import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import i18next from 'i18next';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit } from 'redux-form';
import loginBackground from '../../assets/loginBackground.jpg';
import EdulaiLogo from '../../assets/logo.png';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import MDButton from '../../components/MDButton/MDButton';
import * as UserActions from '../../redux/actions/user.actions';
import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import translations from '../../translations/i18next';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import ResetPasswordForm from '../../components/Forms/LoginRegisterForms/ResetPasswordForm';
import LoginForm from '../../components/Forms/LoginRegisterForms/LoginForm';
import RegisterForm from '../../components/Forms/LoginRegisterForms/RegisterForm';
import { LANGUAGE_LOCAL_MAP } from '../../config/configurations';
import { isMobileBrowser } from '../../utils/utilsFunctions';

class LoginView extends React.Component {

  componentDidMount() {
    document.addEventListener('keydown', (e) => this.onSubmitPressed(e), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', (e) => this.onSubmitPressed(e), false);
  }

  onChangeLanguage(language) {
    i18next.changeLanguage(language);
    this.forceUpdate();
  }


  onSubmitPressed(e) {
    const { dispatch } = this.props;
    if (e.keyCode === 13) {
      dispatch(submit('LoginForm'));
    }
  }

  async onLoginSubmit(userData) {
    const { history, dispatch } = this.props;
    const { username, password } = userData;
    dispatch(UtilsActions.setSpinnerVisible(true));
    try {
      await dispatch(UserActions.login(username, password));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('LOGIN_ERROR_MODAL'));
      history.push('/');
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('LOGIN_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('login.loginError'),
        },
      }));
    }
  }

  onOpenPasswordRecovery() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('PASSWORD_RECOVERY_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('login.passwordRecovery'),
        hideCancel: true,
        content: <ResetPasswordForm onSubmit={(userPasswordResetData) => this.onResetPasswordSubmit(userPasswordResetData)} />,
        bodyTextStyle: { fontSize: 18 },
        bodyText: translations.t('login.passwordRecoveryBody'),
        onConfirm: () => dispatch(submit('ResetPasswordForm')),
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  async onResetPasswordSubmit(resetData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUsersActions.resetPasswordRequest(resetData.username));
      dispatch(ModalsActions.hideModal('PASSWORD_RECOVERY_MODAL'));
      dispatch(ModalsActions.showModal('REGISTER_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('login.resetPasswordSuccess'),
        },
      }))
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('REGISTER_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('login.resetPasswordError'),
        },
      }));
    }
  }

  onOpenRegisterUser() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('REGISTER_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('login.createNewAccount'),
        hideCancel: true,
        content: <RegisterForm onSubmit={(userRegister) => this.onRegisterConfirm(userRegister)} />,
        bodyTextStyle: { fontSize: 20 }, 
        bodyText: `${translations.t('login.registerUserBody')}\n${translations.t('login.registerUserEmail')} `,
        onConfirm: () => dispatch(submit('RegisterForm')),
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  async onRegisterConfirm(userRegisterData) {
    const { dispatch, language } = this.props;
    try {
      const languageSelected = language || 'en';
      const userLanguage = _.find(LANGUAGE_LOCAL_MAP, (lan) => lan.key === languageSelected);
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUsersActions.registerUser({ ...userRegisterData, language: userLanguage ? userLanguage.backend : 'ENGLISH' }));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('REGISTER_MODAL'));
      dispatch(ModalsActions.showModal('REGISTER_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('login.createAccountSuccess'),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('REGISTER_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('login.confirmRegistrationError'),
        },
      }));
    }
  }

  render() {
    const { dispatch } = this.props;
    const isBrowser = isMobileBrowser();
    return (
      <div style={{ height: '109vh', paddingTop: isBrowser ? 0 : 20, backgroundImage: `url(${loginBackground})`, backgroundSize: 'cover', backgroundPosition: 'center top' }}>
        {isBrowser ? null : (
          <LanguageSelector
            language={i18next.language}
            onSelectLanguage={(language) => this.onChangeLanguage(language)}
          />
        )}
        <Card style={{ height: isBrowser ? '150%' : 'unset', width: isBrowser ? '100%' : '50%', margin: 'auto', padding: isBrowser? 0 : 10, paddingBottom: 0, marginTop: isBrowser ? 0: 120, borderRadius: isBrowser ? 0 : 10, paddingTop: isBrowser ? 30 : 0 }}>
          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <img src={EdulaiLogo} alt="edulai" className="logo" style={{ margin: 10, marginTop: 0, alignSelf: 'center', height: isBrowser ? 50 : 65 }} />
              <h1 style={{ margin: 5 }}>Skills Checker</h1>
              <LoginForm
                onSubmit={(loginData) => this.onLoginSubmit(loginData)}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <h4
                className="link-label"
                onClick={() => this.onOpenPasswordRecovery()}
                style={{ letterSpacing: 1, cursor: 'pointer', marginTop: 10, marginBottom: 10, fontSize: 16, textAlign: 'right', color: '#88BDFB' }}
              >
                {translations.t('login.passwordRecovery')}
              </h4>
            </div>
            <div style={{ marginTop: 0 }}>
              <MDButton
                className="gradient-button"
                title="LOGIN"
                backgroundColor={EDULAI_BLUE}
                titleStyle={{ fontSize: 18 }}
                containerstyle={{ width: isBrowser ? 'unset' : 300, margin: 'auto' }}
                onClick={() => dispatch(submit('LoginForm'))}
              />
            </div>
            <h4
              className="link-label"
              onClick={() => this.onOpenRegisterUser()}
              style={{ letterSpacing: 1, cursor: 'pointer', textDecoration: 'underline', marginTop: 20, marginBottom: 0, textAlign: 'center', color: '#88BDFB' }}
            >
              {translations.t('login.registerNow')}
            </h4>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withRouter(LoginView));
