import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';

import { EDULAI_BLUE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import MDButton from '../../MDButton/MDButton';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'required';
  }
  if (!values.firstName) {
    errors.firstName = 'required';
  }
  if (!values.lastName) {
    errors.lastName = 'required';
  }
  if (!values.role) {
    errors.role = translations.t('forms.required');
  }
  return errors;
};

const styles = (theme) => ({
  formContainer: {
    margin: 20,
    marginTop: 20,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  warningText: {
    fontWeight: 'normal',
    marginLeft: 10,
  },
  headerTitle: {
    marginLeft: 20,
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

const SubSkillForm = ({ classes, dispatch, onDeleteSubSkill }) => (
  <MuiThemeProvider theme={theme}>
    <h2 className={classes.headerTitle}>{translations.t('skills.systemSubSkill')}</h2>
    <div className={classes.formContainer}>
      <Field
        name="name"
        component={MDTextInputField}
        containerstyle={{ marginBottom: 20 }}
        label={translations.t('forms.skillName')}
        variant="outlined"
        required
      />
      <MDButton
        title={translations.t('forms.save')}
        backgroundColor={EDULAI_BLUE}
        containerstyle={{ marginBottom: 20 }}
        onClick={() => dispatch(submit('SubSkillForm'))}
      />
      <MDButton
        title={translations.t('forms.delete')}
        backgroundColor="#FF4500"
        containerstyle={{ marginBottom: 20 }}
        onClick={() => onDeleteSubSkill()}
      />
    </div>
  </MuiThemeProvider>
);

const mapStateToProps = (state) => ({
  form: state.form.SubSkillForm,
});


export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'SubSkillForm',
    validate,
    destroyOnUnmount: false,
  }),
  withStyles(styles),
])(SubSkillForm);
