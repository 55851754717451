import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import SecurityIcon from '@material-ui/icons/Security';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`,
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  typography: {
    useNextVariants: true,
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f',
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5,
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#3f3f3f',
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: 18,
  },
  userTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
  },
  titleContainer: {
    marginBottom: 10
  },
  tag: {
    display: 'flex',
    margin: 'auto',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const PlatformUserRow = ({
  user: { role: { name: roleName }, name, surname, email, enabled, userActivationUrl, userTagOutDTOS: tags },
  onClick,
  classes,
  containerstyle,
}) => (
  <MuiThemeProvider theme={theme}>
    <Card style={{ ...containerstyle, opacity: enabled ? 1 : 0.6 }} className={classes.outerContainer}>
      <CardActionArea className={classes.eventContainer} onClick={() => onClick()}>
        <div>
          <div className={classes.titleContainer}>
            <h3 className={classes.title}>
              {`${name} ${surname}`}
            </h3>
            <h3 className={classes.body} style={{ margin: 0 }}>
              {`${email}`}
            </h3>
            {!enabled && !userActivationUrl && (
              <h4 className={classes.disabledText}>
                {translations.t('users.disabled').toUpperCase()}
              </h4>
            )}
            {!enabled && userActivationUrl && (
              <h4 className={classes.disabledText}>
                {translations.t('users.inactive').toUpperCase()}
              </h4>
            )}
          </div>
          <Chip
            className={classes.photoTypeTag}
            color="primary"
            label={translations.t(`roles.${roleName}`)}
            icon={<SecurityIcon />}
          />
          {tags && tags[0] && (
            <div style={{ position: 'absolute', right: 0, bottom: 0, margin: 15 }}>
              <div className={classes.tag}>
                <Chip
                  className={classes.photoTypeTag}
                  style={{
                    backgroundColor: `${EDULAI_BLUE}`,
                    color: 'white',
                    borderRadius: 5,
                    marginLeft: 10,
                  }}
                  label={tags[0].name}
                />
              </div>
            </div>
          )}
        </div>
      </CardActionArea>
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(PlatformUserRow));
