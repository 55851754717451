import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { EDULAI_BLUE } from '../../../../styles/styleConsts';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
  },
});


const MDCheckBoxView = ({ checked, label, disabled, titleStyle, subtitleStyle, subtitle, onSelect }) => (
  <MuiThemeProvider theme={theme}>
    <div>
      <FormControlLabel
        control={
          <Checkbox color="primary" checked={checked} disabled={disabled} onChange={() => onSelect(!checked)} value="gilad" />
        }
        label={<h5 style={{ margin: 0, ...titleStyle }}>{label}</h5>}
      />
      {subtitle && <h5 style={{ marginTop: -15, marginLeft: 35, ...subtitleStyle }}>{subtitle}</h5>}
    </div>
  </MuiThemeProvider>
);

export default MDCheckBoxView;

