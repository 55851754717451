//
// ──────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: P L A T F O R M   U S E R S   A C T I O N S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────
//

import _ from 'lodash';
import ReactGA from 'react-ga4';
import * as PlatformUsersAPI from '../../api/platformUsersAPI';
import {
  SET_USER_TAGS_FILTER,
  RESET_USERS_TAGS_FILTERS,
  RESET_USERS_TAGS_DATA,
  APPEND_USER_TAGS,
  DELETE_TAG,
  SAVE_SUPERVISOR_USERS,
  SAVE_USER_TAGS,
  SAVE_USER_TAGS_PAGINATION,
  SET_SELECTED_USER_TAG, UPDATE_TAG,
  SAVE_BASE_USERS_PAGINATION,
  APPEND_BASE_USERS,
  RESET_BASE_USERS_DATA,
  APPEND_USERS,
  SAVE_BASE_USERS,
  SET_SELECTED_USER,
  UPDATE_USER,
  DELETE_USER,
  RESET_USERS_DATA,
  RESET_USERS_FILTERS,
  SAVE_USERS,
  SAVE_USERS_PAGINATION,
  SET_USERS_FILTER,
} from './actionTypes/platformUsers';
import { USER_ROLES } from '../../config/configurations';

export function saveUsers(users) {
  return {
    type: SAVE_USERS,
    users,
  };
}

export function updateUserInState(user) {
  const userToSave = _.omit(user, 'customFields');
  let { customFields } = user;
  try {
    customFields = JSON.parse(user.customFields);
  } catch (error) {
    // ignore this error
  }
  return {
    type: UPDATE_USER,
    user: { ...userToSave, customFields },
  };
}

export function saveBaseUsers(users) {
  return {
    type: SAVE_BASE_USERS,
    users,
  };
}

export function saveBaseUsersPagination(pagination) {
  return {
    type: SAVE_BASE_USERS_PAGINATION,
    pagination,
  };
}

export function appendBaseUsers(users) {
  return {
    type: APPEND_BASE_USERS,
    users,
  };
}

export function saveSupervisorUsers(supervisors) {
  return {
    type: SAVE_SUPERVISOR_USERS,
    supervisors,
  };
}
export function resetBaseUsersData() {
  return { type: RESET_BASE_USERS_DATA };
}

export function appendUsers(users) {
  return {
    type: APPEND_USERS,
    users,
  };
}

export function saveUsersPagination(pagination) {
  return {
    type: SAVE_USERS_PAGINATION,
    pagination,
  };
}

export function setUsersFilter(field, value) {
  return {
    type: SET_USERS_FILTER,
    field,
    value,
  };
}

export function resetUsersFilters() {
  return {
    type: RESET_USERS_FILTERS,
  };
}

export function deleteUserInState(userId) {
  return {
    type: DELETE_USER,
    userId,
  };
}

export function setSelectedUser(user) {
  return {
    type: SET_SELECTED_USER,
    user,
  };
}

export function resetPlatformUsersData() {
  return { type: RESET_USERS_DATA };
}

export function saveUserTags(tags) {
  return {
    type: SAVE_USER_TAGS,
    tags,
  };
}

export function appendUserTags(tags) {
  return {
    type: APPEND_USER_TAGS,
    tags,
  };
}

export function saveUserTagsPagination(pagination) {
  return {
    type: SAVE_USER_TAGS_PAGINATION,
    pagination,
  };
}

export function setSelectedUserTag(tag) {
  return {
    type: SET_SELECTED_USER_TAG,
    tag,
  };
}

export function updateTagOnState(tag) {
  return {
    type: UPDATE_TAG,
    tag,
  };
}

export function deleteTagOnState(tagId) {
  return {
    type: DELETE_TAG,
    tagId,
  };
}

export function setUserTagFilter(field, value) {
  return {
    type: SET_USER_TAGS_FILTER,
    field,
    value,
  };
}

export function resetUsersTagsFilters() {
  return { type: RESET_USERS_TAGS_FILTERS };
}

export function resetUsersTagsData() {
  return { type: RESET_USERS_TAGS_DATA };
}

export function fetchUsers(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { data: { filters } },
        user: { data: userData },
      } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await PlatformUsersAPI.fetchUsers(params);
      if (response && response.data && response.data.content) {
        const personalId = userData && userData.id;
        const filteredUsers = _.filter(response.data.content, (user) => user.id !== personalId); // exclude himself from users
        const parsedUsers = _.map(filteredUsers, (user) => {
          const userToSave = _.omit(user, 'customFields');
          let { customFields } = user;
          try {
            customFields = JSON.parse(user.customFields);
          } catch (error) {
            // ignore
          }
          return { ...userToSave, customFields };
        });
        dispatch(saveUsers(parsedUsers));
        dispatch(saveUsersPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllUsers(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { data: { filters } },
        user: { data: userData },
      } = getState();
      let params = {
        ...filters,
        page,
        pageSize,
      };

      let response = await PlatformUsersAPI.fetchUsers(params);
      const personalId = userData && userData.id;
      const filteredUsers = _.filter(response.data.content, (user) => user.id !== personalId); // exclude himself from users
      const parsedUsers = _.map(filteredUsers, (user) => {
        const userToSave = _.omit(user, 'customFields');
        let { customFields } = user;
        try {
          customFields = JSON.parse(user.customFields);
        } catch (error) {
          // ignore
        }
        return { ...userToSave, customFields };
      });
      const users = parsedUsers;
      while (!response.data.last) {
        params = {
          ...filters,
          page: response.data.number + 1,
          pageSize,
        };
        response = await PlatformUsersAPI.fetchUsers(params);
        const personalId = userData && userData.id;
        const filteredUsers = _.filter(response.data.content, (user) => user.id !== personalId); // exclude himself from users
        const parsedUsers = _.map(filteredUsers, (user) => {
          const userToSave = _.omit(user, 'customFields');
          let { customFields } = user;
          try {
            customFields = JSON.parse(user.customFields);
          } catch (error) {}
          return { ...userToSave, customFields };
        });
        users.push(...parsedUsers);
      }
      dispatch(saveUsers(users));
      dispatch(saveUsersPagination(_.omit(response.data, 'content')));
      return users;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAppendUsers(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { data: { filters } },
        user: { data: userData },
      } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await PlatformUsersAPI.fetchUsers(params);
      if (response && response.data && response.data.content) {
        const personalId = userData && userData.id;
        const filteredUsers = _.filter(response.data.content, (user) => user.id !== personalId);
        const parsedUsers = _.map(filteredUsers, (user) => {
          const userToSave = _.omit(user, 'customFields');
          let { customFields } = user;
          try {
            customFields = JSON.parse(user.customFields);
          } catch (error) {}
          return { ...userToSave, customFields };
        });
        dispatch(appendUsers(parsedUsers));
        dispatch(saveUsersPagination(_.omit(response.data, 'content')));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createUser(userDTO) {
  return async (dispatch, getState) => {
    try {
      const newUser = {
        username: userDTO.email,
        ...userDTO,
        userRoleType: userDTO && userDTO.role && userDTO.role.value ? userDTO.role.value : USER_ROLES.USER,
        domainId: 1,
        privacyAccepted: true,
      };
      let user;
      const response = await PlatformUsersAPI.createUser(newUser);
      if (response.data) {
        user = response.data;
      }
      if (userDTO.userTag) {
        await PlatformUsersAPI.deleteUserTag(user.id, user.userTagOutDTOS[0].id);
        const response = await PlatformUsersAPI.addUserTag(user.id, userDTO.userTag.value);
        if (response.data) {
          user = response.data;
        }
      }
      if (!user) {
        throw new Error();
      }
      return user;
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.code) throw (error.response.data.code);
      throw error;
    }
  };
}

export function registerUser(userDTO) {
  return async (dispatch, getState) => {
    try {
      const newUser = {
        username: userDTO.email,
        userRoleType: USER_ROLES.USER,
        domainId: 1,
        ...userDTO,

      };
      const response = await PlatformUsersAPI.registerUser(newUser);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.code) throw (error.response.data.code);
      throw error;
    }
  };
}

export function modifyUser(userDTO) {
  return async (dispatch) => {
    let updatedUser = {};
    try {
      const userToUpdate = _.omit(userDTO, 'customFields');
      const response = await PlatformUsersAPI.updateUser(userDTO.id, userToUpdate);
      if (response && response.data) updatedUser = response.data;
    } catch (error) { }
    try {
      const roleResponse = await PlatformUsersAPI.updateUserRole(userDTO.id, { userRoleType: userDTO.role.value });
      if (roleResponse && roleResponse.data) updatedUser = roleResponse.data;
    } catch (error) { }
    if (userDTO.userTag) {
      if (!_.isEmpty(updatedUser.userTagOutDTOS)) {
        await PlatformUsersAPI.deleteUserTag(updatedUser.id, updatedUser.userTagOutDTOS[0].id);
      }
      const tagResponse = await PlatformUsersAPI.addUserTag(userDTO.id, userDTO.userTag.value);
      if (tagResponse && tagResponse.data) updatedUser = tagResponse.data;
    }

    dispatch(updateUserInState(updatedUser));
    return updatedUser;
  };
}

export function forceUserOnboarding(user) {
  return async (dispatch, getState) => {
    let updatedUser = {};
    try {
      const response = await PlatformUsersAPI.setOnboardingStatus(user.id, { onBoardingCompleted: false });
      if (response && response.data) {
        updatedUser = response.data;
        dispatch(updateUserInState(updatedUser));
        return updatedUser;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}
export function deleteUser(userDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await PlatformUsersAPI.deleteUser(userDTO.id);
      if (response.data) {
        dispatch(deleteUserInState(userDTO.id));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function onDisableUser(userDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await PlatformUsersAPI.updateUserEnabling(userDTO.id, { enabled: false });
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchUserDetails(userId) {
  return async (dispatch, getState) => {
    try {
      const response = await PlatformUsersAPI.fetchUserDetails(userId);
      if (response.data) {
        const userToSave = _.omit(response.data, 'customFields');
        let { customFields } = response.data;
        try {
          customFields = JSON.parse(response.data.customFields);
        } catch (error) {}
        return { ...userToSave, customFields };
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function resendUserRegistrationEmail(username) {
  return async (dispatch, getState) => {
    try {
      const response = await PlatformUsersAPI.resetPassword({ username });
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function onEnableUser(userDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await PlatformUsersAPI.updateUserEnabling(userDTO.id, { enabled: true });
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function confirmNewPassword(resetToken, password) {
  return async (dispatch) => {
    try {
      const parameters = {
        password,
        resetToken,
      };
      const response = await PlatformUsersAPI.resetPasswordConfirm(parameters);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function resetPasswordRequest(username) {
  return async (dispatch, getState) => {
    try {
      const response = await PlatformUsersAPI.resetPassword({ username });
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function confirmUserRegistration(activationToken, password) {
  return async (dispatch) => {
    try {
      const parameters = {
        password,
        activationToken,
      };
      const response = await PlatformUsersAPI.confirmUserRegistration(parameters);
      return response;
    } catch (error) {
      throw error;
    }
  };
}
/*
assessmentFormData : { skillId_1: 'value', skillId: 'value'...}
*/
export function saveUserSelfAssessment(assessmentFormData) {
  return async (dispatch, getState) => {
    const {
      user: { data: { id: userId, name, surname } },
    } = getState();
    const skillsValues = _.compact(assessmentFormData);
    const skillSelfAssessment = _.reduce(_.keys(skillsValues), (acc, curr, index) => {
      // eslint-disable-next-line no-param-reassign
      acc = [
        ...acc,
        {
          skillId: parseInt(curr, 10) + 1,
          score: skillsValues[curr],
        },
      ];
      return acc;
    }, []);
    const skillSelfAssessmentDTO = {
      skillSelfAssessmentDTOSet: [...skillSelfAssessment],
    };
    try {
      const response = await PlatformUsersAPI.saveUserSelfAssessment(userId, skillSelfAssessmentDTO);
      if (response && response.data) {
        ReactGA.event({
          category: 'Self Assessment',
          action: 'USER_FINISH_SELF_ASSESSMENT',
          label: `User: ${name} ${surname}`,
        });
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function confirmUserOnboarding(userId, onBoardingCompleted = true, onBoardingData) {
  return async (dispatch, getState) => {
    const { user: { data: userData } } = getState();
    const badgeLinkExperienceDTOS = !onBoardingData.badgeLinks ? [] : _.map(onBoardingData.badgeLinks, (linkSkill) => {
      const index = `badgeLinks-${linkSkill}`;
      return {
        badgeName: linkSkill,
        link: onBoardingData[index],
      };
    });
    const badgesExperienceDTO = {
      badgesName: onBoardingData.badgesName,
      description: onBoardingData.description,
    };
    const formattedOnboardingData = {
      ...onBoardingData,
      badgesLinkDTO: { badgeLinkDTOS: [...badgeLinkExperienceDTOS] },
      badgesExperienceDTO,
    };
    try {
      const userUpdateOnBoardingInDTO = {
        onBoardingCompleted,
        userOnBoardingDataInDTO: formattedOnboardingData,
      };
      const response = await PlatformUsersAPI.commitOnboardingComplete(userId, userUpdateOnBoardingInDTO);
      ReactGA.event({
        category: 'Onboarding',
        action: 'USER_FINISH_ONBOARDING',
        label: `User: ${userData.name} ${userData.surname}`,
      });
      try {
        await dispatch(saveUserSelfAssessment(onBoardingData.skills));
      } catch (error) {}
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchBaseUsers(page = 0, pageSize = 100, fullName = undefined) {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { data: { filters } },
        user: { data: userData },
      } = getState();
      const params = {
        ...filters,
        roleName: USER_ROLES.USER,
        page,
        pageSize,
        fullName,
      };
      const response = await PlatformUsersAPI.fetchUsers(params);
      if (response && response.data && response.data.content) {
        const personalId = userData && userData.id;
        const filteredUsers = _.filter(response.data.content, (userData) => userData.id !== personalId); // exclude himself from users
        const parsedUsers = _.map(filteredUsers, (user) => {
          const userToSave = _.omit(user, 'customFields');
          let { customFields } = user;
          try {
            customFields = JSON.parse(user.customFields);
          } catch (error) {}
          return { ...userToSave, customFields };
        });
        if (page === 0) {
          dispatch(saveBaseUsers(parsedUsers));
        } else {
          dispatch(appendBaseUsers(parsedUsers));
        }
        dispatch(saveBaseUsersPagination(_.omit(response.data, 'content')));
        return filteredUsers;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllBaseUsers() {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { data: { filters } },
        user: { data: userData },
      } = getState();
      let params = {
        ...filters,
        roleName: USER_ROLES.USER,
        page: 0,
        pageSize: 100,
      };
      const personalId = userData && userData.id;
      let response = await PlatformUsersAPI.fetchUsers(params);
      if (!response || !response.data || !response.data.content) throw new Error();
      const users = [...parseUsers(response.data.content, personalId)];
      while (!response.data.last) {
        params = {
          ...filters,
          roleName: USER_ROLES.USER,
          page: response.data.number + 1,
          pageSize: 100,
        };
        response = await PlatformUsersAPI.fetchUsers(params);
        if (!response || !response.data || !response.data.content) throw new Error();
        users.push(...parseUsers(response.data.content, personalId));
      }
      dispatch(saveBaseUsers(users));
      return users;
    } catch (error) {
      throw error;
    }
  };
}

function parseUsers(users, userId) {
  const personalId = userId;
  const filteredUsers = _.filter(users, (userData) => userData.id !== personalId); // exclude himself from users
  const parsedUsers = _.map(filteredUsers, (user) => {
    const userToSave = _.omit(user, 'customFields');
    let { customFields } = user;
    try {
      customFields = JSON.parse(user.customFields);
    } catch (error) {}
    return { ...userToSave, customFields };
  });
  return parsedUsers;
}

export function fetchSupervisorsUsers(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { data: { filters } },
        user: { data: userData },
      } = getState();
      const params = {
        ...filters,
        roleName: USER_ROLES.SUPERVISOR,
        page,
        pageSize,
      };
      const response = await PlatformUsersAPI.fetchUsers(params);
      if (response && response.data && response.data.content) {
        const personalId = userData && userData.id;
        const filteredUsers = _.filter(response.data.content, (userData) => userData.id !== personalId); // exclude himself from users
        dispatch(saveSupervisorUsers(filteredUsers));
        return filteredUsers;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchUsersTags(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { tags: { filters } },
      } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await PlatformUsersAPI.fetchUserTags(params);
      if (response && response.data && response.data.content) {
        const tags = response.data.content;
        if (page === 0) {
          dispatch(saveUserTags(tags));
        } else {
          dispatch(appendUserTags(tags));
        }
        dispatch(saveUserTagsPagination(_.omit(response.data, 'content')));
        return tags;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createUserTag(tagDTO) {
  return async (dispatch) => {
    try {
      const tagResponse = await PlatformUsersAPI.createUserTag(tagDTO);
      if (tagResponse && tagResponse.data) {
        dispatch(updateTagOnState(tagResponse.data));
        return tagResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function editUserTag(tagDTO) {
  return async (dispatch) => {
    try {
      const tagResponse = await PlatformUsersAPI.updateeUserTag(tagDTO);
      if (tagResponse && tagResponse.data) {
        dispatch(updateTagOnState(tagResponse.data));
        return tagResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteTag(tagId) {
  return async (dispatch) => {
    try {
      const tagResponse = await PlatformUsersAPI.deleteTag(tagId);
      if (tagResponse && tagResponse.data) {
        dispatch(deleteTagOnState(tagId));
        return tagResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

/*
Update the user custom fields from onboarding
onboardingCustomFieldValues: [{ customFieldId1: value }, { customFieldId2: value }, ... ]
*/
export function updateUserCustomFieldsFromOnboarding(userId, onBoardingData) {
  return async (dispatch, getState) => {
    try {
      const { domain: { data: domainData } } = getState();
      const { customFields } = domainData;
      const userCustomFields = _.map(_.keys(onBoardingData), (onboardingCfId) => {
        const field = _.find(customFields, (cf) => cf.id === parseFloat(onboardingCfId));
        if (field) {
          return {
            ...field,
            value: onBoardingData[onboardingCfId],
          };
        }
      });
      const customFieldsToSave = { customFields: JSON.stringify(_.compact(userCustomFields)) };
      const response = await PlatformUsersAPI.updateUser(userId, customFieldsToSave);
      if (response && response.data) {
        dispatch(updateUserInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchUsersByTag(tagId, page = 0) {
  return async () => {
    try {
      const params = {
        page,
        pageSize: 50,
      };
      const response = await PlatformUsersAPI.getUsersByUserTag(tagId, params);
      if (response && response.data && response.data.content) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllUsersByTag(tagId) {
  return async (dispatch) => {
    try {
      const users = [];
      const page = 0;
      let usersByTag = await dispatch(fetchUsersByTag(tagId, page));
      if (usersByTag && usersByTag.content) {
        users.push(...usersByTag.content);
      }
      while (!usersByTag.last) {
        // eslint-disable-next-line no-await-in-loop
        usersByTag = await dispatch(fetchUsersByTag(tagId, usersByTag.number + 1));
        if (usersByTag && usersByTag.content) {
          users.push(...usersByTag.content);
        }
      }
      return users;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchTagsWithUsers(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const {
        platformUsers: { tags: { filters } },
      } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await PlatformUsersAPI.fetchUserTags(params);
      if (response && response.data && response.data.content) {
        const tags = response.data.content;
        const approvationCalls = _.map(tags, async (tag) => {
          const tagsUsersResponse = await dispatch(fetchAllUsersByTag(tag.id));
          if (tagsUsersResponse) {
            const tagToUpdate = { ...tag, users: tagsUsersResponse };
            dispatch(updateTagOnState(tagToUpdate));
            return tagsUsersResponse;
          }
        });
        try {
          await Promise.all(approvationCalls);
        } catch (error) {
          if (page === 0) {
            dispatch(saveUserTags(tags));
          } else {
            dispatch(appendUserTags(tags));
          }
        }
        dispatch(saveUserTagsPagination(_.omit(response.data, 'content')));
        return tags;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}
