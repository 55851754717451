import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import UserProfileIcon from '@material-ui/icons/AccountCircle';
import UserDataIcon from '@material-ui/icons/Assignment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EDULAI_BLUE_DARK } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import UserForm from '../Forms/UsersForm/UserForm';
import UserProfileView from './UserProfileView';

const styles = (theme) => ({
  container: {
    padding: 20,
    paddingTop: 0,
  },
  title: {
    margin: 10,
    color: '#5a5c69',
    fontWeight: '200',
  },
  chartsContainer: {
    marginTop: 10,
  },
  noUserContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center',
  },
  noUserText: {
    margin: 0,
    color: '#7F888F',
  },
  noUserIcon: {
    fontSize: 40,
    color: '#7F888F',
    marginRight: 20,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    paddingTop: 25,
    padding: 20,
    marginTop: 7,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE_DARK },
  },
  typography: {
    useNextVariants: true,
  },
});


class UserDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
    };
  }

  onTabChange(index) {
    this.setState({ activeTabIndex: index });
  }

  render() {
    const {
      classes,
      user,
      onDisableUser,
      onDeleteUser,
      onModifyUser,
      onEnableUser,
      onForceUserOnboarding,
      personalRole,
      onCreateNewUserTag,
      onResendPasswordActivation,
      roles,
    } = this.props;
    const { activeTabIndex } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container}>
          <AppBar position="static" style={{ backgroundColor: 'white' }}>
            <Tabs
              value={activeTabIndex}
              onChange={(event, index) => this.onTabChange(index)}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={translations.t('users.userData')} icon={<UserDataIcon />} />
              <Tab label={translations.t('users.userProfile')} icon={<UserProfileIcon />} />
            </Tabs>
          </AppBar>
          <Paper className={classes.listContainer} square style={{ overflowY: 'scroll' }}>
            {activeTabIndex === 0 ? (
              <UserForm
                personalRole={personalRole}
                user={user}
                roles={roles}
                onEnableUser={() => onEnableUser(user)}
                onDisableUser={() => onDisableUser(user)}
                onDeleteUser={() => onDeleteUser(user)}
                onResendPasswordActivation={() => onResendPasswordActivation(user)}
                onSubmit={(userData) => onModifyUser(userData)}
                onCreateNewUserTag={(userTag) => onCreateNewUserTag(userTag)}
              />
            ) : null}
            {activeTabIndex === 1 ? (
              <UserProfileView
                userData={user}
                onForceUserOnboarding={() => onForceUserOnboarding()}
              />
            ) : null}
          </Paper>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  platformUsers: state.platformUsers,
  personalData: state.user.data,
});

export default connect(mapStateToProps)(withStyles(styles)(UserDetailsView));
