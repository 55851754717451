import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import translations from '../../translations/i18next';
import { EDULAI_PURPLE, ORANGE } from '../../styles/styleConsts';
import MDButton from '../MDButton/MDButton';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`,
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  typography: {
    useNextVariants: true,
  },
  titleContainer: {
    alignItems: 'center',
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f',
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5,
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#979da1',
    textAlign: 'left',
    fontSize: 12,
  },
  photoTypeTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
  },
});

const QuizUserRow = ({
  user,
  classes,
  containerstyle,
  sendReminder,
  showSendReminder,
}) => (
  <MuiThemeProvider theme={theme}>
    <Card
      style={{ ...containerstyle }}
      className={classes.outerContainer}
    >
      <CardActionArea disabled className={classes.eventContainer}>
        <div>
          <h3 className={classes.title}>
            {`${user.name}`}
            {' '}
            {`${user.surname}`}
          </h3>
          <h4 style={{ fontWeight: 'normal', margin: 2 }}>{`${user.email}`}</h4>
        </div>
      </CardActionArea>
      {showSendReminder && (
        <MDButton
          title={translations.t('quizzes.sendReminder')}
          backgroundColor={ORANGE}
          containerstyle={{ margin: 10, marginTop: 0 }}
          onClick={() => sendReminder()}
        />
      )}
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(QuizUserRow));
