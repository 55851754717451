//
// ──────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: Q U I Z Z E S   A C T I O N S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────
//
import _ from 'lodash';
import moment from 'moment';
import ReactGA from 'react-ga4';

import * as AnswersAPI from '../../api/answersAPI';
import * as QuizzesAPI from '../../api/quizzesAPI';
import * as QuestionsActions from './questions.actions';
import * as SubmissionsActions from './submissions.actions';
import { QUIZ_STATUS_TYPES, USER_ROLES } from '../../config/configurations';
import {
  APPEND_QUIZZES,
  DELETE_QUIZ,
  RESET_QUIZZES_DATA,
  RESET_QUIZZES_FILTERS,
  SAVE_QUIZZES,
  SAVE_QUIZZES_PAGINATION,
  SET_QUIZZES_FILTER,
  SET_SELECTED_QUIZ,
  UPDATE_QUIZ,
} from './actionTypes/quizzes';

function saveQuizzes(quizzes) {
  return {
    type: SAVE_QUIZZES,
    quizzes,
  };
}

function saveQuizzesPagination(pagination) {
  return {
    type: SAVE_QUIZZES_PAGINATION,
    pagination,
  };
}

export function updateQuizInState(quizId, quiz) {
  return {
    type: UPDATE_QUIZ,
    quizId,
    quiz,
  };
}
export function setQuizzesFilter(field, value) {
  return {
    type: SET_QUIZZES_FILTER,
    field,
    value,
  };
}

export function resetQuizFilters() {
  return {
    type: RESET_QUIZZES_FILTERS,
  };
}

export function resetQuizzesData() {
  return { type: RESET_QUIZZES_DATA };
}

export function appendQuizzes(quizzes) {
  return {
    type: APPEND_QUIZZES,
    quizzes,
  };
}

export function setSelectedQuiz(quiz) {
  return {
    type: SET_SELECTED_QUIZ,
    quiz,
  };
}

export function fetchQuizzAnswersAndSubmissions(quizz) {
  return async (dispatch, getState) => {
    try {
      const {
        user: { data: { id: userId } },
      } = getState();
      let quizToSave = quizz;
      try {
        const answerParams = {
          page: 0,
          pageSize: 50,
        };
        const answers = await AnswersAPI.getAnswers(quizz.id, userId, answerParams);
        if (answers && answers.data && answers.data.content) {
          quizToSave = { ...quizToSave, answers: answers.data.content };
        }
      } catch (error) {}
      try {
        const submissions = await dispatch(SubmissionsActions.fetchAllSubmittedSubmissions(quizz));
        quizToSave = { ...quizToSave, submissions: submissions };
      } catch (error) {}
      try {
        const questions = await dispatch(QuestionsActions.fetchAllQuizQuestions(quizz));
        if (questions) {
          quizToSave = { ...quizToSave, questions };
        }
      } catch (error) {}
      dispatch(updateQuizInState(quizz.id, quizToSave));
      return quizToSave;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchQuizzDetails(quizz) {
  return async (dispatch, getState) => {
    try {
      const {
        user: { data: { id: userId } },
      } = getState();
      let quizToSave = quizz;
      try {
        const quizResponse = await QuizzesAPI.getQuiz(quizz.id);
        if (quizResponse && quizResponse.data) {
          quizToSave = quizResponse.data;
        }
      } catch (error) {}
      try {
        const answerParams = {
          page: 0,
          pageSize: 50,
        };
        const answers = await AnswersAPI.getAnswers(quizz.id, userId, answerParams);
        if (answers && answers.data && answers.data.content) {
          quizToSave = { ...quizToSave, answers: answers.data.content };
        }
      } catch (error) {}
      try {
        const submissions = await dispatch(SubmissionsActions.fetchAllSubmittedSubmissions(quizz));
        quizToSave = { ...quizToSave, submissions: submissions };
      } catch (error) {}
      try {
        const questions = await dispatch(QuestionsActions.fetchAllQuizQuestions(quizz));
        if (questions) {
          quizToSave = { ...quizToSave, questions };
        }
      } catch (error) {}
      dispatch(updateQuizInState(quizz.id, quizToSave));
      return quizToSave;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchQuizzSubmissionsAndQuestions(quizz) {
  return async (dispatch, getState) => {
    let quizToSave = quizz;
    try {
      const allSubmissions = await dispatch(SubmissionsActions.fetchAllSubmittedSubmissions(quizz));
      quizToSave = { ...quizToSave, submissions: allSubmissions };
    } catch(error) {}
    try {
      const questions = await dispatch(QuestionsActions.fetchAllQuizQuestions(quizz));
      if (questions) {
        quizToSave = { ...quizToSave, questions };
      }
    } catch (error) {}
    dispatch(updateQuizInState(quizz.id, quizToSave));
    return quizToSave;
  };
}

export function fetchQuizzesForUsers(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const {
        quizzes: { data: { filters } },
      } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await QuizzesAPI.fetchQuizzes(params);
      if (response && response.data && response.data.content) {
        const filteredQuizzes = _.filter(
          response.data.content,
          (quiz) => (quiz.quizStatusType !== QUIZ_STATUS_TYPES.DRAFT),
        );
        const approvationCalls = _.map(filteredQuizzes, async (quizz) => {
          await dispatch(fetchQuizzAnswersAndSubmissions(quizz));
        });
        await Promise.all(approvationCalls);

        dispatch(saveQuizzesPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchQuizzesAndSubmissions(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const {
        quizzes: { data: { filters } },
        user: { data: { role } },
      } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      let response;
      if (role.name === USER_ROLES.ROOT) {
        response = await QuizzesAPI.fetchAllQuizzes(params);
      } else if (role.name === USER_ROLES.SUPERVISOR) {
        response = await QuizzesAPI.fetchCreatedQuizzes(params);
      }
      if (response && response.data && response.data.content) {
        const quizzes = response.data.content;
        const approvationCalls = _.map(quizzes, async (quizz) => {
          await dispatch(fetchQuizzSubmissionsAndQuestions(quizz));
        });
        try {
          await Promise.all(approvationCalls);
        } catch (error) {
          if (page === 0) {
            dispatch(saveQuizzes(quizzes));
          } else {
            dispatch(appendQuizzes(quizzes));
          }
        }
        dispatch(saveQuizzesPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchQuizzes(page = 0, pageSize = 50) {
  return (dispatch, getState) => {
    try {
      const {
        user: { data: { role } },
      } = getState();
      if (role.name === USER_ROLES.USER) {
        dispatch(fetchQuizzesForUsers());
      } else {
        dispatch(fetchQuizzesAndSubmissions());
      }
    } catch (error) {
      throw error;
    }
  };
}

export const createQuiz = (quizDTO) => (
  async (dispatch, getState) => {
    const { user: { data: userData }} = getState();
    try {
      const newQuiz = {
        name: quizDTO.name,
        startDate: moment(quizDTO.startDate).startOf('minute').valueOf(),
        endDate: moment(quizDTO.endDate).startOf('minute').valueOf(),
        participantsIds: _.map(quizDTO.participants, (user) => user.value),
        userTagsIds: _.map(quizDTO.tags, (tag) => tag.value),
        supervisorId: quizDTO.supervisor && quizDTO.supervisor.value,
        questionsIds: quizDTO.questions,
        hideScore: quizDTO.hideScore
      };
      const response = await QuizzesAPI.createQuiz(newQuiz);
      if (response.data) {
        const quiz = response.data;
        ReactGA.event({
          category: 'Quizzes',
          action: 'QUIZ_CREATED',
          label: `Quiz: ${quiz.id} ${quiz.name} - User: ${userData.name} ${userData.surname}`,
        });
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const submitQuiz = () => (
  async (dispatch, getState) => {
    const { user: { data: userData }, quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const response = await QuizzesAPI.submitQuiz(quiz.id);
      if (response.data) {
        ReactGA.event({
          category: 'Quizzes',
          action: 'QUIZ_SUBMITTED',
          label: `Quiz: ${quiz.id} ${quiz.name} - User: ${userData.name} ${userData.surname}`,
        });
        dispatch(setSelectedQuiz(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const toggleQuizStatus = (status) => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const response = await QuizzesAPI.toggleQuizStatus(quiz.id, status);
      if (response.data) {
        dispatch(setSelectedQuiz(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const changeQuizStartDateTime = (data) => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz }, user: { data: userData }} = getState();
    const quizUpdateStartDateInDTO = { startDate: data.startDate };
    try {
      const response = await QuizzesAPI.changeQuizStartDateTime(quiz.id, quizUpdateStartDateInDTO);
      if (response.data) {
        dispatch(setSelectedQuiz(response.data));
        ReactGA.event({
          category: 'Quizzes',
          action: 'QUIZ_EXTEND_START_TIME',
          label: `Quiz: ${quiz.id} ${quiz.name} - User: ${userData.name} ${userData.surname}`,
        });
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const extendQuiz = (data) => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    const quizUpdateExpirationInDTO = { endDate: data.endDate };
    try {
      const response = await QuizzesAPI.extendExpiration(quiz.id, quizUpdateExpirationInDTO);
      if (response.data) {
        dispatch(setSelectedQuiz(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const forceStartQuiz = () => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz },  user: { data: userData } } = getState();
    try {
      const response = await QuizzesAPI.forceStartQuiz(quiz.id);
      if (response.data) {
        ReactGA.event({
          category: 'Quizzes',
          action: 'QUIZ_FORCE_START',
          label: `Quiz: ${quiz.id} ${quiz.name} - User: ${userData.name} ${userData.surname}`,
        });
        dispatch(setSelectedQuiz(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const updateHideScore = (hideScore) => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const quizUpdateHideScoreInDTO = {
        hideScore: hideScore,
      };
      const response = await QuizzesAPI.updateHideScore(quiz.id, quizUpdateHideScoreInDTO);
      if (response.data) {
        dispatch(setSelectedQuiz({...quiz, hideScore: response.data.hideScore }));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const addParticipants = (newParticipants) => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const quizUpdateParticipantsInDTO = {
        participantsIds: _.map(newParticipants.participants, (user) => user.value),
      };
      const response = await QuizzesAPI.addParticipants(quiz.id, quizUpdateParticipantsInDTO);
      if (response.data) {
        dispatch(setSelectedQuiz(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const terminateQuiz = () => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const response = await QuizzesAPI.terminateQuiz(quiz.id);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const updateQuiz = (quizDTO) => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const quizUpdateInDTO = {
        name: quizDTO.name,
        startDate: moment(quizDTO.startDate).startOf('minute').valueOf(),
        endDate: moment(quizDTO.endDate).startOf('minute').valueOf(),
        participantsIds: _.map(quizDTO.participants, (user) => user.value),
        supervisorId: quizDTO.supervisor && quizDTO.supervisor.value,
        questionsIds: quizDTO.questions,
        hideScore: quizDTO.hideScore
      };
      const response = await QuizzesAPI.updateQuiz(quiz.id, quizUpdateInDTO);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const reactivateQuiz = () => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const response = await QuizzesAPI.reactivateQuiz(quiz.id);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

const deleteQuizInState = (quiz) => ({
  type: DELETE_QUIZ,
  quiz: quiz,
});

export const deleteQuiz = () => (
  async (dispatch, getState) => {
    const { quizzes: { selectedQuiz: quiz } } = getState();
    try {
      const response = await QuizzesAPI.deleteQuiz(quiz.id);
      dispatch(deleteQuizInState(quiz.id));
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const fetchQuizById = (quiz) => (
  async (dispatch) => {
    try {
      const response = await QuizzesAPI.getQuiz(quiz.id);
      if (response.data) {
        let quizToSave = response.data;
        try {
          const questions = await dispatch(QuestionsActions.fetchAllQuizQuestions(quiz));
          if (questions) {
            quizToSave = { ...quizToSave, questions };
          }
        } catch (error) {}
        dispatch(setSelectedQuiz(quizToSave));
        return quizToSave;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);


export function fetchAndUpdateQuizAnswers(quiz) {
  return async (dispatch, getState) => {
    const {
      user: { data: { id: userId } },
    } = getState();
    try {
      const answerParams = {
        page: 0,
        pageSize: 50,
      };
      const answers = await AnswersAPI.getAnswers(quiz.id, userId, answerParams);
      if (answers && answers.data && answers.data.content) {
        const quizToUpdated = { ...quiz, answers: answers.data.content };
        dispatch(updateQuizInState(quiz.id, quizToUpdated));
        return quizToUpdated;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export async function sendDeadlineNotification(quizId, userId) {
  try {
    const notification = await QuizzesAPI.sendDeadlineNotification(quizId, userId);
    return notification.data;
  } catch (error) {
    throw error;
  }
}
