import React from 'react';
import _ from 'lodash';
import translations from '../../translations/i18next';
import { Bubble } from 'react-chartjs-2';
import { createTableBody, createTableHead, createTitle, createTooltip, createUserTableRow, getTooltip, setTooltipPositionStyle } from './htmlTooltipUtils';

const BubbleChart = (props) => {
  const {
    data,
    stepSize,
    height,
    max,
    min,
    legend,
    xAxeTitle,
    yAxeTitle,
  } = props;
  
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: legend,
      position: 'bottom'
    },
    tooltips: {
      enabled: false,
      position: 'nearest',
      custom: function(tooltipModel) {
        // Tooltip Element
        var tooltipEl = getTooltip();

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = createTooltip();
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        function computeUsers(data, index) {
          let labels = [];
          if (!_.isEmpty(data) && data.datasets && !_.isEmpty(data.datasets) && data.datasets[index].users && !_.isEmpty(data.datasets[index].users)) {
            labels = _.map(data.datasets[index].users , user => `${user.name} ${user.surname}`);
          }
          return labels;
        }

        function computeTitle(tooltipItems) {
          return `${translations.t('analytics.score')} ${tooltipItems[0].label} - ${translations.t('analytics.percentage')} ${tooltipItems[0].value}%`;
        }

        // Set Text
        if (tooltipModel.body) {
          var bodyLines = tooltipModel.body.map(getBody);
          
          const tableHead = createTableHead();
          const tableBody = createTableBody();

          const index = tooltipModel.dataPoints[0].datasetIndex;
          const title = computeTitle(tooltipModel.dataPoints);
          const users = computeUsers(props.data, index);

          bodyLines.forEach(function(body, i) {
            const titleRow = createTitle(title)
            tableBody.appendChild(titleRow);
            users.forEach(function(el) {
              const userRow = createUserTableRow(i, el, tooltipModel);
              tableBody.appendChild(userRow);
            });
          });

          var tableRoot = tooltipEl.querySelector('table');

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }

          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }

        // `this` will be the overall tooltip
        var position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        setTooltipPositionStyle(tooltipEl, tooltipModel, position);
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          max,
          min,
          stepSize,
          callback: function (v) {
            return v  + '%'; // convert it to percentage
          },
        },
        scaleLabel: {
          display: yAxeTitle,
          labelString: yAxeTitle,
        }
      }],
      xAxes: [{
        ticks: {
          min: 0,
          max: 5,
          stepSize: 1,
        },
        scaleLabel: {
          display: xAxeTitle,
          labelString: xAxeTitle
        }
      }],
    },
  };

  return (
    <div>
      <Bubble
        type="horizontalBar"
        data={data}
        options={options}
        height={height}
      />
    </div>
  );
};

export default BubbleChart;
