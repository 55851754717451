import _ from 'lodash';
import moment from 'moment';

import * as AnalyticsAPI from '../../api/AnalyticsAPI';
import * as PlatformUsersAPI from '../../api/platformUsersAPI';
import { sendDownloadReportRequest } from '../../api/quizzesAPI';
import { USER_ROLES } from '../../config/configurations';
import translations from '../../translations/i18next';
import {
  RESET_ANALYTICS_DATA,
  SAVE_BAD_USER,
  SAVE_BEST_USER,
  SAVE_QUIZ_SUBMISSIONS_ENTRIES_ANALYTICS,
  SAVE_QUIZ_USER_SKILLS_ANALYTICS,
  SAVE_QUIZ_USER_SUBSKILLS_ANALYTICS,
  SAVE_SKILLS_ANALYTICS,
  SAVE_SKILLS_ANALYTICS_MEAN_BY_TIME,
  SAVE_SKILLS_ANALYTICS_MEANS_SUMMARY,
  SAVE_USER_SKILLS_ANALYTICS,
  SAVE_USER_SUBSKILLS_ANALYTICS,
  SAVE_USERS_SKILLS_SCORES,
  SAVE_USERS_SUMMARY_SCORES,
} from './actionTypes/analytics';
import * as AnswersActions from './answers.actions';

export function resetAnalyticsData() {
  return {
    type: RESET_ANALYTICS_DATA,
  };
}

function saveUserSkillsAnalytics(analytics) {
  return {
    type: SAVE_USER_SKILLS_ANALYTICS,
    analytics,
  };
}

function saveQuizUserSkillsAnalytics(analytics, user) {
  return {
    type: SAVE_QUIZ_USER_SKILLS_ANALYTICS,
    analytics,
    user,
  };
}

function saveUserSubSkillsAnalytics(analytics, skill) {
  return {
    type: SAVE_USER_SUBSKILLS_ANALYTICS,
    analytics,
    skill,
  };
}

function saveQuizUserSubSkillsAnalytics(analytics, skill, user) {
  return {
    type: SAVE_QUIZ_USER_SUBSKILLS_ANALYTICS,
    analytics,
    skill,
    user,
  };
}

function saveQuizSubmissionsEntriesAnalytics(analytics, quiz) {
  return {
    type: SAVE_QUIZ_SUBMISSIONS_ENTRIES_ANALYTICS,
    analytics,
    quiz,
  };
}

function saveBestUser(user) {
  return {
    type: SAVE_BEST_USER,
    user,
  };
}

function saveBadUser(user) {
  return {
    type: SAVE_BAD_USER,
    user,
  };
}

function saveSkillAnalyticsByTime(analytics) {
  return {
    type: SAVE_SKILLS_ANALYTICS,
    analytics,
  };
}

function saveSkillAnalyticsMeansSummary(means) {
  return {
    type: SAVE_SKILLS_ANALYTICS_MEANS_SUMMARY,
    means,
  };
}

function saveSkillAnalyticsMeansByTime(meansByTime) {
  return {
    type: SAVE_SKILLS_ANALYTICS_MEAN_BY_TIME,
    meansByTime,
  };
}

function saveUsersSummaryScores(scores) {
  return {
    type: SAVE_USERS_SUMMARY_SCORES,
    scores,
  };
}

function saveUsersSkillsScores(scores) {
  return {
    type: SAVE_USERS_SKILLS_SCORES,
    scores,
  };
}


export function fetchUserSkillsAnalytics(quizId = null, platformUserId = null) {
  return async (dispatch, getState) => {
    const {
      user: { data: { id: currentUserId } },
    } = getState();
    const userId = platformUserId || currentUserId;
    try {
      const response = await AnalyticsAPI.fetchUserSkillsAnalytics(userId, quizId);
      if (response.data && response.data.skillAnalyticsDTOSet) {
        const formattedUserAnalytics = _.map(response.data.skillAnalyticsDTOSet, analitic => ({
          score: parseFloat(analitic.score).toFixed(2),
          skillId: analitic.skillId,
        }));
        if (quizId) {
          dispatch(saveQuizUserSkillsAnalytics(formattedUserAnalytics, userId));
        } else {
          dispatch(saveUserSkillsAnalytics(formattedUserAnalytics));
        }
        return formattedUserAnalytics;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function fetchUserSubSkillsAnalytics(skill, quizId = null, platformUserId = null) {
  return async (dispatch, getState) => {
    const {
      user: { data: { id: currentUserId } },
    } = getState();
    try {
      const userId = platformUserId || currentUserId;
      const response = await AnalyticsAPI.fetchUserSubSkillsAnalytics(userId, quizId, skill);
      if (response.data && response.data.skillAnalyticsDTOSet) {
        const formattedUserAnalytics = _.map(response.data.skillAnalyticsDTOSet, analitic => ({
          score: parseFloat(analitic.score).toFixed(2),
          skillId: analitic.skillId,
        }));
        if (quizId) {
          dispatch(
            saveQuizUserSubSkillsAnalytics(
              formattedUserAnalytics,
              skill,
              userId,
            ),
          );
        } else {
          dispatch(saveUserSubSkillsAnalytics(formattedUserAnalytics, skill));
        }
        return formattedUserAnalytics;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function fetchQuizSubmissionsEntries() {
  return async (dispatch, getState) => {
    const {
      quizzes: { selectedQuiz: quiz },
    } = getState();
    try {
      const response = await AnalyticsAPI.getSubmissionsEntriesAnalyticsPerQuiz(quiz.id);
      if (response.data && response.data.submissionAnalyticsDTOS) {
        dispatch(
          saveQuizSubmissionsEntriesAnalytics(
            response.data.submissionAnalyticsDTOS, quiz.id,
          ),
        );
        return response.data.submissionAnalyticsDTOS;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function fetchBestUser() {
  return async (dispatch) => {
    try {
      const response = await AnalyticsAPI.getBestUserAnalytics();
      if (response.data && response.data.userAnalyticsDTO) {
        dispatch(saveBestUser(response.data.userAnalyticsDTO));
        return response.data.userAnalyticsDTO;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function fetchBadUser() {
  return async (dispatch) => {
    try {
      const response = await AnalyticsAPI.getBadUserAnalytics();
      if (response.data && response.data.userAnalyticsDTO) {
        dispatch(saveBadUser(response.data.userAnalyticsDTO));
        return response.data.submissionAnalyticsDTOS;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function fetchSkillsAnalyticsByTime(
  startDate,
  endDate,
  skillIds = null,
  userId = null,
) {
  return async (dispatch, getState) => {
    const {
      skills: { data: { content: skillsData } },
    } = getState();
    try {
      const selectedSkillIds = skillIds && !_.isEmpty(skillIds)
        ? _.map(skillIds, (skill) => skill.value)
        : _.map(skillsData, (skill) => skill.id);
      const skillsFilterInDTO = {
        startDate,
        endDate,
        skillIds: selectedSkillIds,
      };
      const response = await AnalyticsAPI.getSkillAnalyticsByTime(skillsFilterInDTO, userId);
      if (response.data && response.data.skillAnalyticsDTOSet) {
        const analytics = _.groupBy(response.data.skillAnalyticsDTOSet, 'skillId');
        _.forIn(selectedSkillIds, (skill) => {
          if (!analytics[`${skill}`]) {
            analytics[`${skill}`] = [];
          }
        });
        // Calculate integral
        const means = _.reduce(_.keys(analytics), (acc, curr, index) => {
          if (!analytics[curr] || _.isEmpty(analytics[curr])) {
            acc[curr] = 0;
          } else {
            acc[curr] = _.meanBy(analytics[curr], (skillAnalytics) => skillAnalytics.score);
          }
          return acc;
        }, {});
        dispatch(saveSkillAnalyticsByTime(analytics));
        dispatch(saveSkillAnalyticsMeansByTime(means));
        return response.data.skillAnalyticsDTOSet;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function fetchSkillsAnalyticsSummary(
) {
  return async (dispatch, getState) => {
    const {
      skills: { data: { content: skillsData } },
    } = getState();
    try {
      const selectedSkillIds = _.map(skillsData, (skill) => skill.id);
      const skillsFilterInDTO = {
        startDate: moment().subtract(2, 'year').valueOf(),
        endDate: moment().add(2, 'year').valueOf(),
        skillIds: selectedSkillIds,
      };
      const response = await AnalyticsAPI.getSkillAnalyticsByTime(skillsFilterInDTO);
      if (response.data && response.data.skillAnalyticsDTOSet) {
        const analytics = _.groupBy(response.data.skillAnalyticsDTOSet, 'skillId');
        _.forIn(selectedSkillIds, (skill) => {
          if (!analytics[`${skill}`]) {
            analytics[`${skill}`] = [];
          }
        });
        // Calculate integral
        const means = _.reduce(_.keys(analytics), (acc, curr, index) => {
          acc[curr] = _.meanBy(analytics[curr], (skillAnalytics) => skillAnalytics.score);
          return acc;
        }, {});
        dispatch(saveSkillAnalyticsMeansSummary(means));
        return means;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

// Fetch all users and get the statistics for every single user
export function fetchSkillsAnalyticsUserSummary(skillId = null) {
  return async (dispatch, getState) => {
    try {
      let userParams = {
        roleName: USER_ROLES.USER,
        page: 0,
        pageSize: 100,
      };
      // Fetch all base users
      let userResponse = await PlatformUsersAPI.fetchUsers(userParams);
      if (userResponse.data && userResponse.data.content) {
        const baseUsers = [...userResponse.data.content];
        while (!userResponse.data.last) {
          userParams = {
            ...userParams,
            page: userResponse.data.number + 1,
          };
          userResponse = await PlatformUsersAPI.fetchUsers(userParams);
          baseUsers.push(...userResponse.data.content);
        }
        /* For every user
          1) fetch overall scores for skills
          2) format the overall scores
          3) calculate the average score
        */
        const userAnalyticsPromises = _.map(baseUsers, async baseUser => {
          let userSkillsScoresAccumulator;
          let userSkillsSummaryScoresAccumulator;
          const response = await AnalyticsAPI.fetchUserSkillsAnalytics(baseUser.id, null);
          if (response.data && response.data.skillAnalyticsDTOSet) {
            let responseToAnalize = _.orderBy(response.data.skillAnalyticsDTOSet, analitic => analitic.skillId);
            // filter metrics data if skill selected
            if (skillId) responseToAnalize = _.filter(responseToAnalize, response => response.skillId === skillId);
            const formattedUserAnalytics = _.map(responseToAnalize, analitic => ({
              score: parseFloat(analitic.score).toFixed(2),
              skillId: analitic.skillId,
              user: baseUser,
              userId: baseUser.id,
            }));
            userSkillsScoresAccumulator = formattedUserAnalytics;
            // Calculate integral
            const userAverageScore = _.meanBy(response.data.skillAnalyticsDTOSet, (skillAnalytics) => skillAnalytics.score);
            const finalScore = !_.isNaN(userAverageScore) ? parseFloat(userAverageScore).toFixed(2) : 0;
            const finalAverageDTO = {
              score: finalScore,
              roundedScore: _.round(finalScore),
              user: baseUser,
              userId: baseUser.id,
            };
            userSkillsSummaryScoresAccumulator = finalAverageDTO;
          }
          return {
            userSkillsScores: {
              [baseUser.id]: { ...userSkillsScoresAccumulator, user: baseUser },
            },
            userSkillsSummaryScores: {...userSkillsSummaryScoresAccumulator }
          };
        });
        const userAnalytics = await Promise.all(userAnalyticsPromises);

        if (userAnalytics) {
          // Format final scores as { userId: { results }}
          const finalSkillsScores = _.reduce(userAnalytics, (acc, curr, index) => {
            acc = {
              ...acc,
              ...curr.userSkillsScores,
            };
            return acc;
          }, {});
          const finalSummaryScores = _.map(userAnalytics, (analytic) => analytic.userSkillsSummaryScores);
          dispatch(saveUsersSummaryScores({ ...finalSummaryScores, totalUsers: _.size(baseUsers) }));
          dispatch(saveUsersSkillsScores({...finalSkillsScores, totalUsers: _.size(baseUsers) }));
          return userAnalytics;
        }
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function createQuizAnalyticsCSVData() {
  return async (dispatch, getState) => {
    const {
      submissions: { data: { quizzes: quizSubmissionsData } },
      quizzes: { selectedQuiz: quiz },
      platformUsers: { data: { content: platformUsersData } },
      questions: { data: { content: questionsData } },
      skills: { data: skillsData },
    } = getState();
    const csvData = [];
    let quizSkills;
    try {
      const subSkillsAnalyticsPromises = _.map(quiz.participantsIds, async id => {
        const user = _.find(platformUsersData, { id });
        if (!user) return null;
        const submission = _.find(quizSubmissionsData[quiz.id], { ownerId: user.id });
        if (!submission) return null;
        await dispatch(AnswersActions.fetchAnswersBySubmissionId(submission.id));
        const { answers: { submissions: answersData }} = getState();
        const answers = _.map(answersData[submission.id], (answer) => {
          const question = _.find(questionsData, { id: answer.questionId });
          const userQuestion = _.find(question.options.optionDTOS,{ optionNumber: answer.optionNumber });
          return userQuestion;
        });
        const points = await dispatch(fetchUserSkillsAnalytics(quiz.id, id));
        quizSkills = _.filter(skillsData.content, (skill) => _.find(points, p => p.skillId === skill.id));

        csvData.push([
          user.name,
          user.surname,
          user.email,
          ..._.map(answers, (answer) => answer.body),
          ..._.map(points, (point) => parseFloat(point.score).toFixed(1)),
        ]);
      });
      await Promise.all(subSkillsAnalyticsPromises);
      const quizQuestions = quiz.questions;
      const csvHeaders = [
        translations.t('forms.name'),
        translations.t('forms.lastName'),
        'E-mail',
        ..._.map(quizQuestions, (question) => question.name),
        ..._.map(quizSkills, (skill) => skill.name),
      ];
      return { csvData, csvHeaders };
    } catch (error) {
      throw error;
    }
  };
}

export function onDownloadQuizResults() {
  return async (dispatch, getState) => {
    const {
      quizzes: { selectedQuiz: quiz },
    } = getState();
    try {
      const response = await sendDownloadReportRequest(quiz.id);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}


// -----------------CHARTS ACTIONS ------------------------

// Create dataset for Radar Chart on System metrics
export function createRadarSummaryDataset() {
  return (dispatch, getState) => {
    const {
      analytics: {
        skillsMeans: {
          meansSummary: skillsMeansSummaryData,
        },
      },
      skills: { data: { content: skillsData } },
    } = getState();
    const summaryData = _.map(_.keys(skillsMeansSummaryData), (skillId) => {
      const displayLabel = _.find(skillsData, { id: parseInt(skillId, 10) });
      return ({
        label: displayLabel && displayLabel.name,
        value: skillsMeansSummaryData[skillId] ? parseFloat(skillsMeansSummaryData[skillId]).toFixed(1) : 0,
      });
    });
    const summaryRadarDataset = {
      datasets: [
        {
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(72, 61, 139, 1)',
          pointBackgroundColor: 'rgba(72, 61, 139, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.currentScoresOfSystem'),
          data: _.map(summaryData, (item) => item.value),
        },
      ],
      labels: _.map(summaryData, (item) => item.label),
    };
    return summaryRadarDataset;
  };
}


// Create datasets for Bubble Chart on System metrics
export function createSummaryBubbleChartDataset() {
  return (dispatch, getState) => {
    const {
      analytics: {
        usersScores: {
          summaryScores: userSummaryScores,
        },
      },
    } = getState();
    const filteredSummaryScores = _.filter(_.omit(userSummaryScores, 'totalUsers'), summaryData => summaryData.score !== 0);
    const bubbleChartPalette=['purple', 'red', 'orange', 'green', 'darkgreen'];
    const groupedScores = _.groupBy(filteredSummaryScores, 'roundedScore');
    const userSummaryScoresDataSetsBubble = {
      datasets: _.map(_.keys(groupedScores), (summaryScoreKey, index) => {
        const usersPercentage = Math.round(((_.size(groupedScores[summaryScoreKey])/userSummaryScores.totalUsers) * 100));
        return ({
          label: `${translations.t('analytics.score')} ${summaryScoreKey}`,
          backgroundColor: bubbleChartPalette[summaryScoreKey -1],
          borderColor: bubbleChartPalette[summaryScoreKey -1],
          pointBorderWidth: 5,
          radius: 3,
          pointHoverRadius: 5,
          users: _.map(groupedScores[summaryScoreKey], summary => summary.user),
          data: [{ x: summaryScoreKey, y: usersPercentage, r: _.size(groupedScores[summaryScoreKey]) * 2}],
        });
      }),
    }
    return userSummaryScoresDataSetsBubble;
  };
}


// Create datasets for summary BarChart on System metrics
export function createSummaryBarChartDataset() {
  return (dispatch, getState) => {
    const {
      analytics: {
        usersScores: {
          summaryScores: userSummaryScores,
        },
      },
    } = getState();
    const filteredSummaryScores = _.filter(_.omit(userSummaryScores, 'totalUsers'), summaryData => summaryData.score !== 0);
    const userSummaryScoresDataSetsBar = {
      datasets: [{
        label: translations.t('analytics.userSkillsScore'),
        backgroundColor: 'rgba(44, 130, 201, 1)',
        borderColor: 'rgba(44, 130, 201, 1)',
        data: _.map(filteredSummaryScores, (summaryScore) => summaryScore.score),
      }],
      labels:  _.map(filteredSummaryScores, (summaryScore) => `${summaryScore.user.name} ${summaryScore.user.surname}`),
    }

    return userSummaryScoresDataSetsBar;
  };
}
