export const EDULAI_BLUE = '#3f65d4';
export const EDULAI_BLUE_LIGHT = '#5476d9';
export const EDULAI_BLUE_DARK = '#294db7';
export const RED = '#DB4437';
export const GREEN_LIGHT = '#5AC0B1';
export const EDULAI_PURPLE = '#483d8b';
export const ORANGE = '#ff9800';

export const QUIZ_STATUS_COLORS = {
  PAUSED: '#FFA500',
  RUNNING: '#008000',
  READY: '#ff8c00',
  DRAFT: '#A9A9A9',
  BLOCKED: '#FF4500',
  DONE: '#1E90FF',
  EXPIRED_FOR_USER: RED,
};
