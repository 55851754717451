import _ from 'lodash';
import palette from 'google-palette';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = (props) => {
  const {
    data,
    labels,
    stepSize,
    height,
    max,
    min,
    legend,
    chartPalette,
    yAxeTitle,
    xAxeTitle,
  } = props;

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: legend,
      position: 'bottom',
    },
    tooltips: {
      callbacks: {
          title: function(tooltipItems, data) {
              var label = tooltipItems[0].xLabel || '';
              return moment(label).format('L');
          }
        }
      },
    scales: {
      yAxes: [{
        ticks: {
          max,
          min,
          stepSize,
        },
        scaleLabel: {
          display: yAxeTitle,
          labelString: yAxeTitle,
        }
      }],
      xAxes: [{
        ticks: {
          maxRotation: 90,
          minRotation: 0,
          callback: function(value, index, values) {
            return moment(value).format('L');
          },
        },
        scaleLabel: {
          display: xAxeTitle,
          labelString: xAxeTitle,
        }
      }],
    },
  };

  const paletteColors = chartPalette ? chartPalette : palette('mpn65', _.size(data));
  const chart = {
    labels,
    datasets: _.map(data, (set, index) => ({
      data: set.values,
      fill: false,
      label: set.label,
      borderDash: !paletteColors ? [5, 5] : [1, 0],
      borderColor: paletteColors ? `#${paletteColors[index]}` : null,
    })),
  };

  return (
    <div>
      <Line
        data={chart}
        options={options}
        height={height}
      />
    </div>
  );
};

export default LineChart;
