import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const MDRadioButtonView = ({ checked, colorIndex, label, value, handleChange, labelStyle }) => (
  <FormControlLabel
    checked={checked}
    color={(colorIndex || 'primary')}
    value={value}
    onChange={e => handleChange(e)}
    control={<Radio />}
    label={<h5 style={{ ...labelStyle }}>{label}</h5>}
  />
);

export default MDRadioButtonView;
