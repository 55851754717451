import React from 'react';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import translations from '../../../translations/i18next';
import { SUBMISSION_STATUS_TYPES } from '../../../config/configurations';

const QuizDoneNoAnswerView = ({ quiz, quizSubmission }) => {
  const showComponent = !quizSubmission || (quizSubmission && quizSubmission.submissionStatusType === SUBMISSION_STATUS_TYPES.DRAFT);
  return (
    <div style={{ textAlign: 'center', margin: 'auto' }}>
      {showComponent && (
        <div>
          <WarningIcon style={{ color: 'red', fontSize: 50 }} />
          <h2>{translations.t('submissions.noSubmissionSendQuizDone')}</h2>
          <h3>{`${translations.t('submissions.submissionsFor', { quizName: quiz.name })} ${translations.t('submissions.closedAt')} ${quiz.terminationDate ? moment(quiz.terminationDate).format('LL') : moment(quiz.endDate).format('LL')}`}</h3>
        </div>
      )}
    </div>
  );
};

export default QuizDoneNoAnswerView;
