import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const MDAccordion = ({
  classes,
  containerstyle,
  subtitleStyle,
  defaultExpanded,
  panelStyle,
  titleStyle,
  title,
  subtitle,
  children,
  onChange,
}) => (
  <div className={classes.root} style={{ ...containerstyle }}>
    <ExpansionPanel onChange={onChange ? (e, isExpanded) => onChange(e, isExpanded) : null} defaultExpanded={defaultExpanded}>
      <ExpansionPanelSummary style={{ paddingLeft: 20 }} expandIcon={<ExpandMoreIcon />}>
        <div>
          <Typography
            className={classes.heading}
            style={{ color: '#3f3f3f', fontWeight: 'bold', ...titleStyle }}
          >
            {title}
          </Typography>
          {subtitle}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
          ...panelStyle,
        }}
      >
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </div>
);

export default withStyles(styles)(MDAccordion);
