import {
  Divider, Grid, IconButton, withStyles,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, initialize, submit } from 'redux-form';
import WarningIcon from '@material-ui/icons/Warning';
import {
  PERMISSIONS, PERMISSION_ENTITIES, QUIZ_STATUS_TYPES, SUBMISSION_STATUS_TYPES,
} from '../../config/configurations';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as QuizzesActions from '../../redux/actions/quizzes.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { EDULAI_BLUE, QUIZ_STATUS_COLORS } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';
import AddParticipantsForm from '../Forms/AddParticipantsForm/AddParticipantsForm';
import ChangeQuizValidityForm from '../Forms/ChangeQuizValidityForm/ChangeQuizValidityForm';
import QuestionDetails from '../Forms/QuizForm/QuestionDetails';
import ListComponent from '../ListComponents/ListComponent';
import MDButton from '../MDButton/MDButton';
import Permission from '../Permission/Permission';
import QuizQuestionRow from './QuizQuestionRow';
import QuizUserRow from './QuizUserRow';
import MDCheckBoxView from '../Forms/FormsComponents/MDCheckbox/MDCheckBoxView';

const styles = () => ({
  container: {
    margin: 20,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  status: {
    borderRadius: 5,
    height: 50,
    display: 'flex',
    width: '80%',
    margin: 'auto',
  },
  statusText: {
    textAlign: 'center',
    color: 'white',
    margin: 'auto',
    fontWeight: 800,
    fontSize: 18,
  },
  text: {
    fontWeight: 'normal',
    margin: 0,
  },
  listContainer: {
    paddingTop: 25,
    padding: 20,
    marginTop: 7,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

class QuizDetails extends Component {
  async onQuizPauseHandler() {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.toggleQuizStatus(QUIZ_STATUS_TYPES.PAUSED));
      await dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('PAUSE_QUIZ_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.quizPauseSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('VIEW_QUIZ_DETAILS_MODAL'));
    } catch (error) {
      dispatch(ModalsActions.showModal('PAUSE_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.pauseQuizError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onQuizResumeHandler() {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.toggleQuizStatus(QUIZ_STATUS_TYPES.RUNNING));
      await dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('RESUME_QUIZ_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.quizResumeSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('VIEW_QUIZ_DETAILS_MODAL'));
    } catch (error) {
      dispatch(ModalsActions.showModal('RESUME_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.resumeQuizError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  onAddParticipantsHandler(participantsIds) {
    const { dispatch } = this.props;
    dispatch(destroy('AddParticipantsForm'));
    dispatch(ModalsActions.showModal('ADD_PARTICIPANTS_MODAL', {
      modalType: 'OPERATIONAL_VIEW',
      modalProps: {
        title: translations.t('quizzes.addNewParticipants'),
        content: (
          <AddParticipantsForm
            participantsIds={participantsIds}
            onSubmit={(newParticipants) => this.addParticipants(newParticipants)}
          />
        ),
        onConfirm: () => dispatch(submit('AddParticipantsForm')),
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  onReactivateQuizHandler() {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    if (moment() > moment(quiz.endDate)) {
      const quizToExtend = { ..._.omit(quiz, 'endDate'), endDate: moment().valueOf() };
      dispatch(destroy('ChangeQuizValidityForm'));
      dispatch(initialize('ChangeQuizValidityForm', quizToExtend));
      dispatch(ModalsActions.showModal('EXTEND_QUIZ_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('quizzes.newEndDateTime'),
          content: (
            <ChangeQuizValidityForm
              end
              onSubmit={async (data) => {
                await this.extendQuiz(data);
                await this.reactivateQuiz();
              }}
            />
          ),
          onConfirm: () => {
            dispatch(ModalsActions.showModal('REACTIVATE_QUIZ_MODAL', {
              modalType: 'MODAL_DIALOG',
              modalProps: {
                title: translations.t('forms.warning'),
                bodyText: translations.t('quizzes.confirmReactivateQuiz'),
                onConfirm: () => dispatch(submit('ChangeQuizValidityForm')),
                confirmText: translations.t('modals.confirm'),
              },
            }));
          },
          confirmText: translations.t('modals.confirm'),
        },
      }));
    } else {
      dispatch(ModalsActions.showModal('REACTIVATE_QUIZ_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('quizzes.confirmReactivateQuiz'),
          onConfirm: () => this.reactivateQuiz(),
          confirmText: translations.t('modals.confirm'),
        },
      }));
    }
  }

  onQuizForceStartHandler = () => {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('FORCE_START', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('forms.warning'),
        bodyText: translations.t('quizzes.confirmForceStart'),
        onConfirm: () => this.forceStartQuiz(),
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  onTerminateQuizHandler = () => {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('TERMINATE_QUIZ_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('forms.warning'),
        bodyText: translations.t('quizzes.confirmTerminateQuiz'),
        onConfirm: () => this.terminateQuiz(),
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  onChangeQuizStartDateTimeHandler = () => {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    dispatch(destroy('ChangeQuizValidityForm'));
    dispatch(initialize('ChangeQuizValidityForm', quiz));
    dispatch(ModalsActions.showModal('CHANGE_START_DATE', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('quizzes.newStartDateTime'),
        content: (
          <ChangeQuizValidityForm
            start
            onSubmit={(data) => this.changeQuizStartDateTime(data)}
          />
        ),
        onConfirm: () => dispatch(submit('ChangeQuizValidityForm')),
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  onExtendQuizHandler = () => {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    dispatch(destroy('ChangeQuizValidityForm'));
    dispatch(initialize('ChangeQuizValidityForm', quiz));
    dispatch(ModalsActions.showModal('EXTEND_QUIZ_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('quizzes.newEndDateTime'),
        content: (
          <ChangeQuizValidityForm
            end
            onSubmit={(data) => this.extendQuiz(data)}
          />
        ),
        onConfirm: () => dispatch(submit('ChangeQuizValidityForm')),
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  async reactivateQuiz() {
    const { dispatch, form, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      if (form && form.ChangeQuizValidityForm) {
        await dispatch(submit('ChangeQuizValidityForm'));
      }
      await dispatch(QuizzesActions.reactivateQuiz());
      await dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('REACTIVATE_QUIZ_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.reactivateQuizSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('REACTIVATE_QUIZ_MODAL'));
      dispatch(ModalsActions.hideModal('VIEW_QUIZ_DETAILS_MODAL'));
    } catch (error) {
      let errorMessage = translations.t('quizzes.reactivateQuizError');
      if (error && error === 7008) errorMessage = translations.t('quizzes.noCreditsError');
      dispatch(ModalsActions.showModal('REACTIVATE_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: errorMessage,
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async updateHideScore(value) {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.updateHideScore(value));
      await dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('UPDATE_HIDE_SCORE_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.updateHideScoreSuccess'),
        },
      }));
    } catch (error) {
      dispatch(ModalsActions.showModal('UPDATE_HIDE_SCORE_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.updateHideScoreError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async addParticipants(newParticipants) {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.addParticipants(newParticipants));
      await dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('ADD_PARTICIPANTS_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.addParticipantsSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('ADD_PARTICIPANTS_MODAL'));
      dispatch(ModalsActions.hideModal('VIEW_QUIZ_DETAILS_MODAL'));
    } catch (error) {
      dispatch(ModalsActions.showModal('ADD_PARTICIPANTS_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.addParticipantsError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async forceStartQuiz() {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.forceStartQuiz());
      await dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('FORCE_START_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.forceStartSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('FORCE_START'));
      dispatch(ModalsActions.hideModal('VIEW_QUIZ_DETAILS_MODAL'));
    } catch (error) {
      dispatch(ModalsActions.hideModal('FORCE_START'));
      let errorMessage = translations.t('quizzes.forceStartError');
      if (error && error === 7008) errorMessage = translations.t('quizzes.noCreditsError');
      dispatch(ModalsActions.showModal('FORCE_START_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: errorMessage,
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async changeQuizStartDateTime(data) {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.changeQuizStartDateTime(data));
      dispatch(ModalsActions.showModal('CHANGE_START_DATE_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.changeStartDateSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('CHANGE_START_DATE'));
      dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(ModalsActions.showModal('CHANGE_START_DATE_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.changeStartDateError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async extendQuiz(data) {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.extendQuiz(data));
      dispatch(ModalsActions.showModal('EXTEND_QUIZ_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.extendQuizSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('EXTEND_QUIZ_MODAL'));
      dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(ModalsActions.showModal('EXTEND_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.extendQuizError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async terminateQuiz() {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.terminateQuiz());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('TERMINATE_QUIZ_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.terminateQuizSuccess'),
        },
      }));
      dispatch(QuizzesActions.fetchQuizzDetails(quiz));
      dispatch(ModalsActions.hideModal('TERMINATE_QUIZ_MODAL'));
      dispatch(ModalsActions.hideModal('VIEW_QUIZ_DETAILS_MODAL'));
    } catch (error) {
      dispatch(ModalsActions.hideModal('TERMINATE_QUIZ_MODAL'));
      dispatch(ModalsActions.showModal('TERMINATE_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.terminateQuizError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  showQuizQuestions() {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    dispatch(ModalsActions.showModal('VIEW_QUESTION_DETAILS', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('quizzes.questions'),
        content: (
          <div>
            {_.map(quiz.questions, (question) => (question ? (
              <div key={question.id}>
                <QuestionDetails question={question} />
                <Divider style={{ marginTop: 30, marginBottom: 30 }} />
              </div>
            ) : null))}
          </div>),
      },
    }));
  }

  render() {
    const {
      classes,
      quizzes: { selectedQuiz: quiz },
      users: { data: { content: usersData } },
      user: { data: user },
      sendReminder,
    } = this.props;
    const createdBy = quiz.createdById === user.id
      ? user
      : _.find(usersData, { id: quiz.createdById });
    const canAddPartecipants = quiz.quizStatusType === QUIZ_STATUS_TYPES.READY || quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING || quiz.quizStatusType === QUIZ_STATUS_TYPES.PAUSED;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <h1 style={{ marginBottom: 0, marginTop: 0 }}>{quiz.name}</h1>
            <Tooltip title={translations.t(`quizzes.typesDescriptions.${quiz.quizStatusType}`)} arrow placement="bottom">
              <Chip
                className={classes.photoTypeTag}
                style={{
                  backgroundColor: QUIZ_STATUS_COLORS[quiz.quizStatusType],
                  color: 'white',
                  borderRadius: 5,
                }}
                label={translations.t(`quizzes.types.${quiz.quizStatusType}`)}
              />
            </Tooltip>
          </div>
          <Divider style={{ margin: '10px 0' }} />
          {createdBy && createdBy.name && createdBy.surname ? (
            <div style={{ display: 'flex' }}>
              <h3 style={{ margin: 0, marginRight: 10 }}>
                {`${translations.t('quizzes.createdBy')}:`}
              </h3>
              <h3 className={classes.text}>
                {`${createdBy.name} ${createdBy.surname}`}
              </h3>
            </div>
          ) : null}
          <div style={{ display: 'flex', marginTop: 10 }}>
            <h3 style={{ margin: 0, marginRight: 10 }}>
              {`${translations.t('quizzes.validity')}:`}
            </h3>
            <h3 className={classes.text}>
              {moment(quiz.startDate).format('LLL')}
              {' - '}
              {moment(quiz.endDate).format('LLL')}
            </h3>
          </div>
          <h2 style={{ marginBottom: 0, marginTop: 35 }}>{translations.t('quizzes.questions')}</h2>
          <Divider />
          {quiz && quiz.questionsIds ? <h3>{`${translations.t('forms.totals')} ${translations.t('quizzes.questions')}: ${_.size(quiz.questionsIds)}`}</h3> : null}
          <ListComponent
            containerstyle={{ width: '100%' }}
          >
            <Paper
              className={classes.listContainer}
              square
              style={{
                overflowY: 'scroll',
                backgroundColor: '#f0f0f0',
                maxHeight: 250,
              }}
            >
              {_.map(quiz.questions, (question) => (<QuizQuestionRow key={`questions_${question.id}`} question={question} />))}
            </Paper>
          </ListComponent>
          <MDButton
            title={translations.t('quizzes.showQuestionsDetails')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{ marginBottom: 20 }}
            onClick={() => this.showQuizQuestions()}
          />
          <div style={{
            marginTop: 35, display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
          >
            <h2 style={{ margin: 0 }}>{translations.t('quizzes.participants')}</h2>
            {canAddPartecipants ? (
              <IconButton onClick={() => this.onAddParticipantsHandler(quiz.participantsIds)}>
                <AddCircleIcon style={{ color: 'gray' }} />
              </IconButton>
            ) : null}
          </div>
          <Divider />
          <ListComponent
            containerstyle={{ width: '100%' }}
          >
            <Paper
              className={classes.listContainer}
              square
              style={{
                overflowY: 'scroll',
                maxHeight: 250,
                backgroundColor: '#f0f0f0',
              }}
            >
              {_.map(quiz.participantsIds, (id) => {
                const userItem = _.find(usersData, { id });
                if (!userItem) return null;
                const userSubmissions = quiz.submissions && _.find(quiz.submissions, (submission) => submission.ownerId === id);
                const isSubmissionPending = !userSubmissions || _.isEmpty(userSubmissions) || (userSubmissions && userSubmissions.submissionStatusType !== SUBMISSION_STATUS_TYPES.SUBMITTED);
                const showSendReminder = quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING && isSubmissionPending;
                return (
                  <QuizUserRow
                    key={`Partecipant_${id}`}
                    user={userItem}
                    showSendReminder={showSendReminder}
                    sendReminder={() => sendReminder(quiz.id, id)}
                  />
                );
              })}
            </Paper>
          </ListComponent>
          <div style={{ marginTop: 35, display: 'flex', alignItems: 'center' }}>
            <h2 style={{ margin: 0 }}>{translations.t('quizzes.hideScores')}</h2>
          </div>
          <Divider />
          <MDCheckBoxView
            label={<h4 style={{ margin: 0, fontSize: 15 }}>{translations.t('quizzes.hideScoresToUsers')}</h4>}
            checked={quiz.hideScore}
            disabled={false}
            onSelect={(value) => this.updateHideScore(value)}
          />
          <div style={{
            display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20,
          }}
          >
            <WarningIcon style={{ color: 'red' }} />
            <h4 style={{
              fontSize: 15, fontWeight: '100', margin: 0, marginLeft: 10,
            }}
            >
              {translations.t('quizzes.hideScoresDescription')}
            </h4>
          </div>
          <Divider />
          {quiz.quizStatusType === QUIZ_STATUS_TYPES.READY && (
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <MDButton
                  title={translations.t('quizzes.forceStart')}
                  backgroundColor="#008000"
                  containerstyle={{ marginBottom: 20 }}
                  onClick={() => this.onQuizForceStartHandler()}
                />
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  title={translations.t('quizzes.changeStartDate')}
                  backgroundColor="#008000"
                  containerstyle={{ marginBottom: 20 }}
                  onClick={() => this.onChangeQuizStartDateTimeHandler()}
                />
              </Grid>
            </Grid>
          )}
          {(
            quiz.quizStatusType === QUIZ_STATUS_TYPES.PAUSED
            || quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING)
            && (
              <Grid container spacing={4}>
                <Grid item xs={6} style={{ paddingBottom: 0 }}>
                  {quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING && (
                    <MDButton
                      title={translations.t('quizzes.pause')}
                      backgroundColor="#FFA500"
                      onClick={() => this.onQuizPauseHandler()}
                    />
                  )}
                  {quiz.quizStatusType === QUIZ_STATUS_TYPES.PAUSED && (
                    <MDButton
                      title={translations.t('quizzes.resume')}
                      backgroundColor="#008000"
                      containerstyle={{ marginBottom: 20 }}
                      onClick={() => this.onQuizResumeHandler()}
                    />
                  )}
                </Grid>
                <Grid item xs={6} style={{ paddingBottom: 0 }}>
                  <MDButton
                    title={translations.t('quizzes.terminate')}
                    backgroundColor="#FF4500"
                    containerstyle={{ marginBottom: 20 }}
                    onClick={() => this.onTerminateQuizHandler(quiz)}
                  />
                </Grid>
                {/* TODO: Update with correct permission/entity */}
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <Permission
                    do={[PERMISSIONS.CREATE]}
                    on={PERMISSION_ENTITIES.SKILL}
                    abilityHelper={AbilityProvider.getDomainAbilityHelper()}
                  >
                    <MDButton
                      title={translations.t('quizzes.extendExpiration')}
                      backgroundColor="#1E90FF"
                      containerstyle={{ marginTop: 0 }}
                      onClick={() => this.onExtendQuizHandler()}
                    />
                  </Permission>
                </Grid>
              </Grid>
            )}
          {quiz.quizStatusType === QUIZ_STATUS_TYPES.DONE && (
            // TODO: Update with correct permission/entity
            <Permission
              do={[PERMISSIONS.EXTEND, PERMISSIONS.REACTIVATE]}
              on={PERMISSION_ENTITIES.QUIZ}
              abilityHelper={AbilityProvider.getDomainAbilityHelper()}
            >
              <MDButton
                title={translations.t('quizzes.reactivate')}
                backgroundColor="#008000"
                containerstyle={{ marginBottom: 20 }}
                onClick={() => this.onReactivateQuizHandler()}
              />
            </Permission>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.platformUsers,
  questions: state.questions,
  skills: state.skills,
  quizzes: state.quizzes,
  user: state.user,
  form: state.form,
});

export default connect(mapStateToProps)(withStyles(styles)(QuizDetails));
