import { CardActionArea } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { EDULAI_PURPLE } from '../../../styles/styleConsts';

const styles = theme => ({
  outerContainer: {
    height: '100%',
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`,
  },
  eventContainer: {
    padding: 15,
    width: '100%',
  },
  title: {
    fontSize: 20,
    margin: 5
  },
  subTitle: {
    fontSize: 15,
    margin: 10
  },
  divider: {
    height: 3
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const SubSkillRow = ({
  subSkill: { name },
  onClick,
  classes,
  containerstyle,
}) => (
  <MuiThemeProvider theme={theme}>
    <Card style={{ ...containerstyle }} className={classes.outerContainer}>
      <CardActionArea onClick={() => onClick()}>
        <div className={classes.eventContainer}>
          <h3 className={classes.title}>
            {name}
          </h3>
        </div>
      </CardActionArea>
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(SubSkillRow));
