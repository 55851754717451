import { Divider, withStyles, IconButton } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  change,
  destroy,
  Field,
  FormSection,
  initialize,
  reduxForm,
  submit,
} from 'redux-form';

import DeleteIcon from '@material-ui/icons/Delete';
import * as ModalsActions from '../../../redux/actions/modal.actions';
import * as QuestionsActions from '../../../redux/actions/questions.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import { EDULAI_BLUE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import MDButton from '../../MDButton/MDButton';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import SelectableField from '../FormsComponents/SelectableInput/SelectableField';
import AnswerForm from './AnswerForm';
import OptionCard from './OptionCard';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = translations.t('forms.required');
  }
  if (!values.body) {
    errors.body = translations.t('forms.required');
  }
  if (!values.skills) {
    errors.skills = translations.t('forms.required');
  } else {
    _.forEach(values.skills, (skill) => {
      if (!values.subskills || !values.subskills[`_${skill.value}`]) {
        if (!errors.subskills) {
          errors.subskills = {};
        }
        errors.subskills[`_${skill.value}`] = translations.t('forms.required');
      }
    });
  }
  return errors;
};

const styles = (theme) => ({
  formContainer: {
    margin: 20,
    marginTop: 20,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alingItems: 'center',
  },
  headerTitle: {
    marginLeft: 20,
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  enabledText: {
    color: '#66c0b0',
    margin: 0,
  },
  disabledText: {
    color: 'red',
    margin: 0,
  },
  statusContainer: {
    marginLeft: 10,
    marginBottom: 20,
  },
  statusTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

class QuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async onFilterSkills(name) {
    const { skills: { data: { content: skills } } } = this.props;
    const filteredOptions = _.filter(
      skills,
      (skill) => skill.name.toLowerCase().includes(name.toLowerCase()),
    );
    const newOptions = _.map(filteredOptions, (skill) => ({
      value: skill.id,
      label: skill.name,
    }));
    return newOptions;
  }

  onCreateNewAnswer(answerData) {
    const { dispatch, questions: { selectedQuestion: { options } } } = this.props;
    let optionNumber;
    if (options && options.length > 0) {
      optionNumber = _.maxBy(options, 'optionNumber').optionNumber + 1;
    } else {
      optionNumber = 1;
    }

    dispatch(QuestionsActions.addOption({
      ...answerData,
      optionNumber,
    }));
    dispatch(ModalsActions.hideModal('ADD_ANSWER'));
  }

  onDeleteOptionClicked(option) {
    const { dispatch } = this.props;
    dispatch(destroy('AnswerForm'));
    dispatch(ModalsActions.showModal('DELETE_OPTION_MODAL_REQUEST', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('forms.warning'),
        bodyText: translations.t('questions.deleteOptionConfirm'),
        onConfirm: () => this.onDeleteOption(option),
        confirmText: translations.t('modals.confirm'),
      },
    }));
    dispatch(ModalsActions.hideModal('EDIT_ANSWER'));
  }

  onDeleteOption(option) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisible(true));
    dispatch(QuestionsActions.deleteOption(option));
    dispatch(UtilsActions.setSpinnerVisible(false));
    dispatch(ModalsActions.hideModal('DELETE_OPTION_MODAL_REQUEST'));
  }

  onAddAnswerClicked() {
    const {
      dispatch,
      questions: { selectedQuestion: { options } },
    } = this.props;
    if (!options || options.length === 0) {
      dispatch(ModalsActions.showModal('WARNING_FIRST_OPTION_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('questions.firstOptionCreate'),
          onClose: () => dispatch(ModalsActions.hideModal('WARNING_FIRST_OPTION_MODAL')),
          onConfirm: () => this.showAnswerForm(),
          confirmText: translations.t('modals.confirm'),
        },
      }));
    } else {
      this.showAnswerForm();
    }
  }

  onSkillSelectionChange(newSkills) {
    const { dispatch, form, questions: { selectedQuestion: { options } } } = this.props;

    if (options && options.length > 0) {
      dispatch(QuestionsActions.resetOptions());
    }

    if (form && form.values && form.values.skills) {
      if (_.size(form.values.skills) > _.size(newSkills)) {
        const skillToRemove = _.first(_.difference(form.values.skills, newSkills));
        if (skillToRemove && form.values.subskills) {
          const newSubSkills = _.pickBy(form.values.subskills, (value, key) => (
            key !== `_${skillToRemove.value}`));
          dispatch(change('QuestionForm', 'subskills', newSubSkills));
        }
      }
    }
  }

  onSubSkillSelectionChange() {
    const { dispatch, questions: { selectedQuestion: { options } } } = this.props;

    if (options && options.length > 0) {
      dispatch(QuestionsActions.resetOptions());
    }
  }

  onSubmitButtonClicked() {
    const {
      dispatch,
      questions: { selectedQuestion },
      form: { values },
    } = this.props;
    if (values
      && values.subskills
      && _.keys(values.subskills).length > 0
      && _.keys(values.subskills).length === values.skills.length && (
      !selectedQuestion.options || selectedQuestion.options.length < 3)
    ) {
      this.showOptionsErrorModal();
    } else {
      dispatch(submit('QuestionForm'));
    }
  }

  onEditAnswerHandler(option) {
    const { dispatch, form: { values: { subskills } } } = this.props;
    dispatch(destroy('AnswerForm'));
    dispatch(initialize('AnswerForm', {
      ...option,
    }));

    dispatch(ModalsActions.showModal('EDIT_ANSWER', {
      modalType: 'MODAL_DIALOG_DELETE',
      modalProps: {
        title: translations.t('questions.editAnswer'),
        content: <AnswerForm
          subskills={subskills}
          onSubmit={(answerData) => this.editAnswer(answerData)}
        />,
        onConfirm: () => dispatch(submit('AnswerForm')),
        onDelete: () => this.onDeleteOptionClicked(option),
        confirmText: translations.t('modals.save'),
        cancelText: translations.t('modals.delete'),
      },
    }));
  }

  editAnswer(answerData) {
    const { dispatch } = this.props;
    dispatch(QuestionsActions.editOption(answerData));
    dispatch(ModalsActions.hideModal('EDIT_ANSWER'));
  }

  showOptionsErrorModal() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('ERROR_OPTIONS_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        hideCancel: true,
        title: translations.t('forms.warning'),
        bodyText: translations.t('questions.optionsNumberError'),
        onConfirm: () => dispatch(ModalsActions.hideModal('ERROR_OPTIONS_MODAL')),
        confirmText: translations.t('modals.close'),
      },
    }));
  }

  showAnswerForm() {
    const { dispatch, form: { values: { subskills } } } = this.props;

    dispatch(ModalsActions.hideModal('WARNING_FIRST_OPTION_MODAL'));
    dispatch(destroy('AnswerForm'));
    dispatch(ModalsActions.showModal('ADD_ANSWER', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('questions.newAnswer'),
        hideCancel: true,
        content: <AnswerForm
          subskills={subskills}
          onSubmit={(answerData) => this.onCreateNewAnswer(answerData)}
        />,
        onConfirm: () => dispatch(submit('AnswerForm')),
        confirmText: translations.t('modals.save'),
      },
    }));
  }

  render() {
    const {
      edit,
      classes,
      skills: { data: { content: skillsData } },
      questions: { selectedQuestion: { options } },
      form,
      onDeleteQuestion,
    } = this.props;

    const canCreateOptions = ((
      (form && form.values && form.values.subskills && _.keys(form.values.subskills).length > 1)
      || !_.isEmpty(options))
    );

    return (
      <MuiThemeProvider theme={theme}>
        {edit ? (
          <div className={classes.header}>
            <h2 className={classes.headerTitle}>{translations.t('questions.editQuestion')}</h2>
            <IconButton style={{ marginLeft: 40 }} onClick={() => onDeleteQuestion()}>
              <DeleteIcon />
            </IconButton>
          </div>
        ) : (
          <h2 className={classes.headerTitle}>{translations.t('questions.newQuestion')}</h2>
        )}
        <div className={classes.formContainer}>
          <Field
            name="name"
            component={MDTextInputField}
            containerstyle={{ marginBottom: 20 }}
            variant="outlined"
            label={translations.t('forms.title')}
            required
          />
          <Field
            name="body"
            variant="outlined"
            containerstyle={{ marginBottom: 20 }}
            component={MDTextInputField}
            label={translations.t('forms.text')}
            required
            multiline
            rows="3"
          />
          <Divider style={{ marginTop: 20, marginBottom: 10 }} />
          <Field
            name="skills"
            containerstyle={{ marginBottom: 20 }}
            component={SelectableField}
            placeholder="Skills"
            title={translations.t('forms.evaluatedSkills')}
            multi
            mandatory
            onSelect={(skill) => this.onSkillSelectionChange(skill)}
            onLoadOptions={(name) => this.onFilterSkills(name)}
            defaultOptions={_.map(skillsData, (skill) => ({
              value: skill.id,
              label: skill.name,
            }))}
          />
          <FormSection name="subskills">
            {form.values && form.values.skills && (
              _.map(form.values.skills, (skill) => (
                <Field
                  name={`_${skill.value}`}
                  containerstyle={{ marginBottom: 20 }}
                  component={SelectableField}
                  placeholder={translations.t('forms.skillName')}
                  title={`${skill.label} sub-skill`}
                  mandatory
                  onSelect={(subskill) => this.onSubSkillSelectionChange(subskill)}
                  defaultOptions={
                    _.map(
                      _.find(skillsData, { id: skill.value }).subSkills,
                      (subskill) => ({
                        value: subskill.id,
                        label: subskill.name,
                      }),
                    )
                  }
                />
              ))
            )}
          </FormSection>

          <div>
            <Divider style={{ marginTop: 21 }} />
            <h2>{translations.t('questions.answers')}</h2>
            {!_.isEmpty(options) && (
            <div>
              {
                      _.map(_.sortBy(options, ['optionNumber']), (option) => (
                        <OptionCard
                          option={option}
                          onEdit={() => this.onEditAnswerHandler(option)}
                        />
                      ))
                    }
            </div>
            )}
            {canCreateOptions
              ? (
                <Fab
                  disabled={options && options.length >= 5}
                  style={{
                    color: 'white',
                  }}
                  color="primary"
                  aria-label="add"
                  onClick={() => this.onAddAnswerClicked()}
                >
                  <AddIcon />
                </Fab>
              ) : <h4>{translations.t('forms.selectSkillsToCreateAnswers')}</h4>}
          </div>
          <MDButton
            title={translations.t('forms.save')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{ marginBottom: 20 }}
            onClick={() => this.onSubmitButtonClicked()}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form.QuestionForm,
  questions: state.questions,
  skills: state.skills,
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'QuestionForm',
    validate,
    destroyOnUnmount: false,
  }),
  withStyles(styles),
])(QuestionForm);
