import React from 'react';

import MDTextInputView from './MDTextInputView';


export default class MDTextInputField extends React.Component {
  onHandleChange(value, input) {
    const { onHandleChange, onFormatText } = this.props;
    let finalValue = value;
    if (onFormatText) finalValue = onFormatText(value);
    if (input) {
      input.onChange(finalValue);
    }
    if (onHandleChange) {
      onHandleChange(finalValue);
    }
  }

  render() {
    const {
      input,
      meta,
      showErrorLabel,
      containerstyle,
      label,
      variant,
      defaultValue,
      type,
    } = this.props;
    const hasError = meta.touched && meta.error;
    const value = input.value || defaultValue || '';
    return (
      <div style={{ marginTop: 0, marginBottom: 0, ...containerstyle }}>
        <MDTextInputView
          error={hasError}
          value={value}
          label={label}
          variant={variant}
          type={type}
          {...this.props}
          onChange={value => this.onHandleChange(value, input)}
        />
        {showErrorLabel && hasError && <h6 style={{ color: 'red', marginTop: 4, marginBottom: 0, marginLeft: 10 }}>{meta.error}</h6>}
      </div>
    );
  }
}
