import { withStyles, createMuiTheme, MuiThemeProvider, IconButton } from '@material-ui/core';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CheckIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { EDULAI_PURPLE } from '../../../../styles/styleConsts';

const styles = () => ({
  infoButton: {
    marginLeft: 'auto',
    fontSize: 32,
    display: 'flex',
    color: 'rgba(0,0,0,0.53)',
  },
  rowContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  skillTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
  },
  title: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    marginBottom: 0,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const SelectableRow = ({
  title,
  skills,
  type,
  onClick,
  isChecked,
  hasError,
  customOptionElement,
  classes,
  onInfoClick,
}) => (
  <MuiThemeProvider theme={theme}>
    <Card
      className="selectable-row"
      style={{
        margin: 15,
        backgroundColor: hasError ? 'rgb(255, 109, 123)' : 'white',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 10,
      }}
    >
      <CardActionArea style={{ padding: 10, width: '100%' }} onClick={() => onClick()}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            className={classes.rowContent}
            onClick={() => onClick()}
            aria-hidden="true"
          >
            {isChecked ? <CheckIcon style={{ fontSize: 30, marginRight: 25, color: 'green' }} /> : (
              <div style={{ display: 'flex', alignItems: 'center', height: 53 }}>
                <div style={{
                  width: 25,
                  height: 25,
                  borderRadius: 12,
                  borderStyle: 'solid',
                  borderWidth: 2,
                  borderColor: !hasError ? 'gray' : 'red',
                  marginRight: 30,
                }}
                />
              </div>
            )}
          </span>
          <div className="resourceInfoRow-titleType">
            {customOptionElement ? <div>{customOptionElement}</div> : (
              <div>
                <h2 className={classes.title}>{title}</h2>
                {skills && _.map(skills, (skill) => (
                  <Chip
                    key={skill.name}
                    className={classes.skillTag}
                    color="primary"
                    label={skill.name}
                  />
                ))}
                <p style={{ color: '#3f3f3f' }}>{type}</p>
              </div>
            )}
          </div>
          {onInfoClick ? (
            <IconButton
              className={classes.infoButton}
              onClick={(event) => {
                event.stopPropagation();
                onInfoClick();
              }}
            >
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          ) : null}
        </div>
      </CardActionArea>
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(SelectableRow);
