import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import translations from '../../../translations/i18next';
import MDDateTimePickerField from '../FormsComponents/MDDateTimePicker/MDDateTimePickerField';

const validate = (values, initial) => {
  const errors = {};

  const momentEndDate = moment(values.endDate || initial.endDate);
  const momentStartDate = moment(values.startDate || initial.startDate);

  if ((momentEndDate - momentStartDate) < 3600000 && (momentEndDate - momentStartDate) > 0) {
    errors.endDate = translations.t('forms.min1hr');
    errors.startDate = translations.t('forms.min1hr');
  }
  if ((momentEndDate - momentStartDate) < 0) {
    errors.endDate = translations.t('forms.startBeforeEnd');
    errors.startDate = translations.t('forms.startBeforeEnd');
  }
  return errors;
};

const styles = () => ({
  formContainer: {
    width: 300,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  headerTitle: {
    marginLeft: 20,
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  enabledText: {
    color: '#66c0b0',
    margin: 0,
  },
  disabledText: {
    color: 'red',
    margin: 0,
  },
  statusContainer: {
    marginLeft: 10,
    marginBottom: 20,
  },
  statusTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

const ChangeQuizValidityForm = (props) => {
  const {
    classes,
    start,
    end,
    form,
  } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.formContainer}>
        {start && (
          <Field
            name="startDate"
            containerstyle={{ marginBottom: 20 }}
            component={MDDateTimePickerField}
            label={translations.t('forms.startDateTime')}
            minDate={Date.now()}
            showErrorLabel
            required
          />
        )}
        {end && (
          <Field
            name="endDate"
            containerstyle={{ marginBottom: 20 }}
            component={MDDateTimePickerField}
            label={translations.t('forms.endDateTime')}
            showErrorLabel
            minDate={form && form.initial && form.initial.endDate}
            required
          />
        )}
      </div>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  users: state.platformUsers,
  questions: state.questions,
  form: state.form.ChangeQuizValidityForm,
  quizzes: state.quizzes,
  user: state.user,
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'ChangeQuizValidityForm',
    validate,
    destroyOnUnmount: false,
  }),
  withStyles(styles),
])(ChangeQuizValidityForm);
