import React from 'react';
import { Card } from '@material-ui/core';
import BestIcon from '@material-ui/icons/EmojiEvents';
import WorstIcon from '@material-ui/icons/TrendingDown';
import StarRateView from '../StarRateView/StarRateView';
import translations from '../../translations/i18next';
import { RED } from '../../styles/styleConsts';

const BestLeastPerformingUsers = ({ bestUser, badUser }) => {
  const isSameUser = bestUser && badUser && badUser.id === bestUser.id;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
      {bestUser && bestUser.name && (
        <Card style={{ marginRight: 10, padding: 10, paddingTop: 20, display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column' }}>
          <BestIcon style={{ color: '#FFC107', fontSize: 30 }} />
          <h3 style={{ margin: 0, fontSize: 20 }}>{translations.t('analytics.bestPerformingUser')}</h3>
          <h3 style={{ margin: 0, fontWeight: 100 }}>{`${bestUser.name} ${bestUser.surname}`}</h3>
          <StarRateView
            value={bestUser.score}
            totalValues={5}
            starStyle={{ width: 15 }}
            unselectedStarStyle={{ width: 9 }}
            starContainerStyle={{ marginBottom: 0, marginTop: 5 }}
            titleContainerStyle={{ marginBottom: 10 }}
            titleStyle={{ fontWeight: 100, fontSize: 18, margin: 0 }}
          />
        </Card>
      )}
      {!isSameUser && badUser && badUser.name && (
        <Card style={{ marginLeft: 10, padding: 10, paddingTop: 20, display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column' }}>
          <WorstIcon style={{ color: RED, fontSize: 30 }} />
          <h3 style={{ margin: 0, fontSize: 20 }}>{translations.t('analytics.leastPerformingUser')}</h3>
          <h3 style={{ margin: 0, fontWeight: 100 }}>{`${badUser.name} ${badUser.surname}`}</h3>
          <StarRateView
            value={badUser.score}
            totalValues={5}
            starStyle={{ width: 15 }}
            unselectedStarStyle={{ width: 9 }}
            starContainerStyle={{ marginBottom: 20, marginTop: 5, float: 'right' }}
            titleContainerStyle={{ marginBottom: 10 }}
            titleStyle={{ fontWeight: 100, fontSize: 18, margin: 0 }}
          />
        </Card>
      )}
    </div>
  );
};

export default BestLeastPerformingUsers;
