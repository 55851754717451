import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RoleIcon from '@material-ui/icons/VerifiedUserOutlined';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import translations from '../../translations/i18next';
import { ORANGE } from '../../styles/styleConsts';
import MDButton from '../MDButton/MDButton';

const styles = (theme) => ({
  title: {
    margin: 0,
    color: '#3f3f3f',
  },
  subtitle: {
    color: '#3f3f3f',
    fontWeight: '100',
    margin: 0,
    marginTop: 5,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  roleIcon: {
    color: '#3f3f3f',
    marginRight: 10,
  },
  section: {
    marginTop: 20
  },
  sectionTitle: {
    margin: 0,
    marginBottom: 2,
  },
  sectionValue: {
    fontWeight: 'normal',
    margin: 0,
    marginTop: 3
  },
  headerContainer: {
    marginBottom: 20,
    padding: 10
  },
  forceOnboardingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
});


const UserProfileView = ({ classes, userData, onForceUserOnboarding }) => {
  const { userOnBoardingDataDTO } = userData;
  return (
    <div>
      <div className={classes.headerContainer} style={{ borderLeft: `3px #36b9cc solid`}}>
        <h4 className={classes.title}>{`${userData.name} ${userData.surname}`}</h4>
        <h4 className={classes.subtitle}>{`${userData.email}`}</h4>
        <div className={classes.roleContainer}>
          <RoleIcon className={classes.roleIcon} />
          <h4 className={classes.subtitle}>{translations.t(`roles.${userData.role.name}`)}</h4>
        </div>
      </div>
      {userOnBoardingDataDTO && userOnBoardingDataDTO.gender && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {translations.t('user.gender')}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
          {translations.t(`user.genderTypes.${userOnBoardingDataDTO.gender}`)}
          </h4>
        </div>
      )}
      {userOnBoardingDataDTO && userOnBoardingDataDTO.ageRange && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {translations.t('user.ageRange')}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
          {translations.t(`user.ageRangeTypes.${userOnBoardingDataDTO.ageRange}`)}
          </h4>
        </div>
      )}

      {userOnBoardingDataDTO && userOnBoardingDataDTO.nation && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {translations.t('user.nation')}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
          {userOnBoardingDataDTO.nation}
          </h4>
        </div>
      )}
      {userOnBoardingDataDTO && userOnBoardingDataDTO.employment && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {translations.t('user.employment')}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
          {translations.t(`user.employmentsTypes.${userOnBoardingDataDTO.employment}`)}
          </h4>
        </div>
      )}
      {userOnBoardingDataDTO && userOnBoardingDataDTO.professionName && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {translations.t('user.professionName')}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
          {userOnBoardingDataDTO.professionName}
          </h4>
        </div>
      )}
      {userOnBoardingDataDTO && userOnBoardingDataDTO.experienceAgeRange && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {translations.t('user.experienceAgeRange')}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
          {translations.t(`user.experienceAgeRangeTypes.${userOnBoardingDataDTO.experienceAgeRange}`)}
          </h4>
        </div>
      )}
      {userOnBoardingDataDTO && userOnBoardingDataDTO.educational && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {translations.t('user.educational')}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
          {translations.t(`user.educationalTypes.${userOnBoardingDataDTO.educational}`)}
          </h4>
        </div>
      )}
      {userOnBoardingDataDTO.badgesExperienceDTO && userOnBoardingDataDTO.badgesExperienceDTO.badgesName && !_.isEmpty(userOnBoardingDataDTO.badgesExperienceDTO.badgesName) && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>{translations.t('forms.stageOrInsterestsDescription')}</h4>
          <Divider />
          {userOnBoardingDataDTO.badgesExperienceDTO.description ? <h4 className={classes.sectionValue}>{userOnBoardingDataDTO.badgesExperienceDTO.description}</h4> : null}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            {_.map(userOnBoardingDataDTO.badgesExperienceDTO.badgesName, badgeName => (
              <h4 className={classes.sectionValue}>• {translations.t(`skills.${badgeName}`)}</h4>
            ))}
          </div>
        </div>
      )}
      {userOnBoardingDataDTO.badgesLinkDTO && userOnBoardingDataDTO.badgesLinkDTO.badgeLinkDTOS && !_.isEmpty(userOnBoardingDataDTO.badgesLinkDTO.badgeLinkDTOS) && (
        <div className={classes.section}>
          <h4 className={classes.sectionTitle}>{translations.t('forms.badgeLinksDescription')}</h4>
          <Divider />
          <div>
            {_.map(userOnBoardingDataDTO.badgesLinkDTO.badgeLinkDTOS, badgeLink => (
              <div>
                <h4 className={classes.sectionValue}>{translations.t(`skills.${badgeLink.badgeName}`)}</h4>
                {badgeLink.link ? <h4 className={classes.sectionValue} style={{ marginLeft: 5 }}>• {badgeLink.link}</h4>: null}
              </div>
            ))}
          </div>
        </div>
      )}
      
      {userOnBoardingDataDTO.portfolioDescription && (
        <div className={classes.section}>
        <h4 className={classes.sectionTitle}>
        {translations.t('user.portfolioDescription')}
        </h4>
        <Divider />
        <h4 className={classes.sectionValue}>
          {userOnBoardingDataDTO.portfolioDescription}
        </h4>
      </div>
      )}
      {_.map(userData.customFields, customField => (
        <div key={customField.id} className={classes.section}>
          <h4 className={classes.sectionTitle}>
          {customField.body}
          </h4>
          <Divider />
          <h4 className={classes.sectionValue}>
            {customField.value}
          </h4>
        </div>
      ))}
      <div>
        {userData.onBoardingCompletionDate ? 
        <h4 style={{ margin: 0, textAlign: 'center', marginTop: 20, fontWeight: '100' }}>
          {translations.t('user.onboardingUpdatedAt', { date: moment(userData.onBoardingCompletionDate).format('LL')})}
        </h4> : null}
        {userData.onBoardingCompleted ?
          <div>
            <h4 style={{ textAlign: 'center', marginBottom: 5, marginTop: 5, fontWeight: '100' }}>{translations.t('forms.forceUserOnboardingDescription')}</h4>
            <MDButton
              backgroundColor={ORANGE}
              title={translations.t('forms.forceUserOnboarding')}
              containerstyle={{ marginBottom: 20, marginTop: 0 }}
              onClick={() => onForceUserOnboarding()}
            />
          </div> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withStyles(styles)(UserProfileView));