import Card from '@material-ui/core/Card';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import CardActionArea from '@material-ui/core/CardActionArea';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import translations from '../../../translations/i18next';
import { EDULAI_PURPLE } from '../../../styles/styleConsts';


const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`,
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f',
  },
  subtitle: {
    margin: 10,
    marginBottom: 0,
    marginLeft: 38,
    marginTop: 0,
    textAlign: 'left',
    fontWeight: 100,
    fontSize: 18,
    color: '#3f3f3f',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const UserTagRow = ({
  tag: { name, users },
  onClick,
  classes,
  containerstyle,
}) => (
  <MuiThemeProvider theme={theme}>
    <Card style={{ ...containerstyle }} className={classes.outerContainer}>
      <CardActionArea className={classes.eventContainer} onClick={() => onClick()}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UsersIcon style={{ marginRight: 15 }} />
          <h4 className={classes.title}>
            {name}
          </h4>
        </div>
          {users && !_.isEmpty(users) && (
            <h4 className={classes.subtitle}>{`${translations.t('users.tagUsers')}: ${_.size(users)}`}</h4>
          )}
      </CardActionArea>
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(UserTagRow));
