import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import RoleIcon from '@material-ui/icons/VerifiedUserOutlined';
import { IconButton } from '@material-ui/core';
import translations from '../../translations/i18next';
import MDSelectView from '../Forms/FormsComponents/MDSelectedField/MDSelectView';
import { LANGUAGE_LOCAL_MAP } from '../../config/configurations';

const styles = (theme) => ({
  cardContainer: {
    padding: 20,
  },
  title: {
    margin: 0,
    color: '#3f3f3f',
  },
  subtitle: {
    color: '#3f3f3f',
    fontWeight: '100',
    margin: 0,
    fontSize: 18,
    marginTop: 5,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  roleIcon: {
    color: '#3f3f3f',
    marginRight: 10,
  },
});


const UserProfileCard = ({ classes, userData, onLogout, language, onChangeLanguage }) => {
  const languageOptions = _.map(LANGUAGE_LOCAL_MAP, language => (
    {
      label: `${translations.t(`languages.${language.translation}`)}`,
      value: `${language.key}`,
    }));
  return (
    <Card className={classes.cardContainer} style={{ borderLeft: `3px #36b9cc solid`}}>
      <div className={classes.titleContainer} style={{ marginBottom: 15 }}>
        <h1 className={classes.title}>{translations.t('user.profile')}</h1>
        <IconButton onClick={() => onLogout()}>
          <LogoutIcon />
        </IconButton>
      </div>
      <h4 className={classes.title}>{`${userData.name} ${userData.surname}`}</h4>
      <div className={classes.roleContainer}>
        <RoleIcon className={classes.roleIcon} />
        <h4 className={classes.subtitle}>{translations.t(`roles.${userData.role.name}`)}</h4>
      </div>
      <h4 className={classes.title} style={{ marginTop: 20 }}>Email</h4>
      <h5 className={classes.subtitle}>{userData.username}</h5>
      <div style={{ width: '50%', marginTop: 20 }}>
        <MDSelectView
          title={translations.t('languages.selectLanguage')}
          options={languageOptions}
          value={language}
          onSelect={(languageSelected) => onChangeLanguage(languageSelected)}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withStyles(styles)(UserProfileCard));