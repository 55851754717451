import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  reset,
  submit,
} from 'redux-form';

import { EDULAI_BLUE, GREEN_LIGHT, RED } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import MDTextInputField from '../Forms/FormsComponents/MDTextInput/MDTextInputField';
import SelectableField from '../Forms/FormsComponents/SelectableInput/SelectableField';
import MDButton from '../MDButton/MDButton';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
});


const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
  },
});

class ListSearchBar extends Component {
  onFilterOptions = async (name) => {
    const { options } = this.props;
    const filteredOptions = _.filter(
      options,
      (option) => option.label.toLowerCase().includes(name.toLowerCase()),
    );
    const newOptions = _.map(filteredOptions, (option) => ({
      value: option.value,
      label: option.label,
    }));
    return newOptions;
  }

  render() {
    const {
      classes,
      dispatch,
      onResetFilters,
      searchFieldLabel,
      options,
    } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Field
              name="name"
              component={MDTextInputField}
              variant="outlined"
              label={searchFieldLabel || translations.t('forms.name')}
            />
            {options && (
              <Field
                name="quizStatusType"
                containerstyle={{ width: 200, marginTop: 20 }}
                component={SelectableField}
                placeholder="Status"
                mandatory
                onLoadOptions={(name) => this.onFilterOptions(name)}
                defaultOptions={options}
                bgColor="transparent"
              />
            )}
          </div>
          <MDButton
            backgroundColor={GREEN_LIGHT}
            title={translations.t('forms.search')}
            containerstyle={{ marginLeft: isMobileBrowser() ? 20 : 40, width: isMobileBrowser() ? 120 : 150, marginBottom: 20 }}
            icon={<SearchIcon style={{ color: 'white', marginLeft: 0 }} />}
            onClick={() => dispatch(submit('ListSearchBar'))}
          />
          <MDButton
            title={translations.t('forms.reset')}
            backgroundColor={RED}
            containerstyle={{ marginLeft: isMobileBrowser() ? 20 : 40, marginBottom: 20 }}
            onClick={() => {
              dispatch(reset('ListSearchBar'));
              onResetFilters();
            }}
            icon={<CloseIcon style={{ color: 'white', marginLeft: 0 }} />}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default connect()(withStyles(styles)(reduxForm({
  form: 'ListSearchBar',
})(ListSearchBar)));
