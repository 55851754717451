import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ActiveUsersIcon from '@material-ui/icons/HowToReg';
import DisabledUsersIcon from '@material-ui/icons/PersonAddDisabled';
import UserInactiveIcon from '@material-ui/icons/VoiceOverOff';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, destroy, initialize } from 'redux-form';

import UserForm from '../../components/Forms/UsersForm/UserForm';
import UserTagForm from '../../components/Forms/UsersForm/UserTagForm';
import ListComponent from '../../components/ListComponents/ListComponent';
import PlatformUserRow from '../../components/ListComponents/RowComponents/PlatformUserRow';
import Spinner from '../../components/Spinner/Spinner';
import UserDetailsView from '../../components/UserDetails/UserDetailsView';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as PlatformUserActions from '../../redux/actions/platformUsers.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { EDULAI_BLUE_DARK } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import UsersTagsView from './UsersTagsView';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const styles = (theme) => ({
  container: {
    padding: 20,
  },
  title: {
    margin: 10,
  },
  chartsContainer: {
    marginTop: 10,
  },
  noUserContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center',
  },
  noUserText: {
    margin: 0,
    color: '#7F888F',
  },
  noUserIcon: {
    fontSize: 40,
    color: '#7F888F',
    marginRight: 20,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    paddingTop: 25,
    padding: 20,
    marginTop: 7,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE_DARK },
  },
  typography: {
    useNextVariants: true,
  },
});


class UsersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeTabIndex: 0,
      platformUserData: [],
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      this.containerDiv.scrollIntoView({ behavior: 'smooth' });
      this.setState({ isLoading: true });
      dispatch(PlatformUserActions.setUsersFilter('enabled', true));
      await dispatch(PlatformUserActions.fetchUsers());
      await dispatch(PlatformUserActions.fetchTagsWithUsers());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onAppendUsers(page) {
    const { dispatch } = this.props;
    await dispatch(PlatformUserActions.fetchAppendUsers(page));
  }

  async onResetUserFilters() {
    const { dispatch } = this.props;
    const { activeTabIndex } = this.state;
    try {
      this.setState({ isLoading: true });
      dispatch(PlatformUserActions.resetUsersFilters());
      dispatch(PlatformUserActions.setUsersFilter('enabled', activeTabIndex === 0));
      await dispatch(PlatformUserActions.fetchUsers());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onSearchUser(filterValue) {
    const { dispatch } = this.props;
    try {
      this.setState({ isLoading: true });
      if (filterValue) dispatch(PlatformUserActions.setUsersFilter('email', filterValue.name));
      dispatch(PlatformUserActions.resetPlatformUsersData());
      await dispatch(PlatformUserActions.fetchUsers());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  onNewUserClicked() {
    const { dispatch, roles: { data: rolesData }, personalData } = this.props;
    dispatch(destroy('UserForm'));
    dispatch(PlatformUserActions.setSelectedUser({}));
    dispatch(ModalsActions.showModal('NEW_USER_FORM_MODAL', {
      modalType: 'OPERATIONAL_VIEW',
      modalProps: {
        content: (
          <UserForm
            personalRole={personalData && personalData.role && personalData.role.name}
            roles={rolesData}
            onSubmit={(userData) => this.onCreateNewUser(userData)}
            onCreateNewUserTag={(userTag) => this.onShowNewTagModal(userTag)}
          />
        ),
      },
    }));
  }

  async onCreateNewUser(userData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.createUser(userData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('CREATE_USER_SUCCESS_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          bodyText: translations.t('users.createUserSuccess'),
          onConfirm: () => dispatch(ModalsActions.hideModal('CREATE_USER_SUCCESS_MODAL')),
        },
      }));
      dispatch(ModalsActions.hideModal('NEW_USER_FORM_MODAL'));
      await dispatch(PlatformUserActions.fetchUsers());
    } catch (error) {
      dispatch(ModalsActions.showModal('CREATE_USER_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.userCreationError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onResendPasswordActivation(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.resetPasswordRequest(user.username));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      dispatch(ModalsActions.showModal('REGISTER_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('login.resetPasswordSuccess'),
        },
      }))
    } catch (error) {
      dispatch(ModalsActions.showModal('CREATE_USER_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.resetPasswordError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onEditUserClicked(userClicked) {
    const { dispatch, roles: { data: usersRoles }, personalData } = this.props;
    // Init user role
    let user = userClicked;
    try {
      user = await dispatch(PlatformUserActions.fetchUserDetails(user.id))
    } catch (error) {}
    dispatch(PlatformUserActions.setSelectedUser(user));
    const userRole = _.find(usersRoles, (role) => user.role.name === role.name);
    const initObject = {
      ...user,
      role: { value: userRole.name, label: translations.t(`roles.${userRole.name}`)}, 
    }
    if (!_.isEmpty(user.userTagOutDTOS)) {
      initObject['userTag'] = { label: user.userTagOutDTOS[0].name, value: user.userTagOutDTOS[0].id }
    }

    dispatch(initialize('UserForm', initObject));

    dispatch(ModalsActions.showModal('EDIT_USER_FORM_MODAL', {
      modalType: 'OPERATIONAL_VIEW',
      modalProps: {
        content: (
          <UserDetailsView
            personalRole={personalData && personalData.role && personalData.role.name}
            user={user}
            roles={usersRoles}
            onEnableUser={() => this.onEnableUser(user)}
            onDisableUser={() => this.onDisableUser(user)}
            onDeleteUser={() => this.onDeleteUserRequest(user)}
            onSubmit={(userData) => this.onModifyUser(userData)}
            onCreateNewUserTag={(userTag) => this.onShowNewTagModal(userTag)}
            onForceUserOnboarding={() => this.onForceUserOnboarding(user)}
            onResendPasswordActivation={() => this.onResendPasswordActivation(user)}
            onModifyUser={(userData) => this.onModifyUser(userData)}
          />),
      },
    }));
  }
  
  async onForceUserOnboarding(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.forceUserOnboarding(user));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('MODIFY_USER_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('users.modifyUserSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      await dispatch(PlatformUserActions.fetchUsers());
    } catch (error) {
      dispatch(ModalsActions.showModal('MODIFY_USER_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.userModifyError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }
  async onModifyUser(userData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.modifyUser(userData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('MODIFY_USER_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('users.modifyUserSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      await dispatch(PlatformUserActions.fetchUsers());
    } catch (error) {
      dispatch(ModalsActions.showModal('MODIFY_USER_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.userModifyError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onEnableUser(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.onEnableUser(user));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      dispatch(ModalsActions.showModal('RESET_PASSWORD_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('forms.enableUserSuccess'),
        },
      }));
      await dispatch(PlatformUserActions.fetchUsers());
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('RESET_PASSWORD_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('forms.disableUserError'),
        },
      }));
    }
  }

  onDeleteUserRequest(user) {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('DELETE_USER_MODAL_REQUEST', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('forms.warning'),
        bodyText: translations.t('users.deleteUserConfirm'),
        onConfirm: () => this.onDeleteUser(user),
      },
    }));
  }

  async onDeleteUser(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.deleteUser(user));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('DELETE_USER_MODAL_REQUEST'));
      dispatch(ModalsActions.showModal('DELETE_USER_MODAL_SUCCESS', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('users.userDeleteSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      dispatch(ModalsActions.hideModal('DELETE_USER_MODAL'));
      dispatch(PlatformUserActions.fetchUsers());
    } catch (error) {
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      dispatch(ModalsActions.hideModal('DELETE_USER_MODAL_REQUEST'));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('DELETE_USER_MODAL_SUCCESS', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.userDeleteError'),
        },
      }));
    }
  }

  async onDisableUser(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.onDisableUser(user));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      dispatch(ModalsActions.showModal('DISABLE_USER_MODAL_SUCCESS', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('users.disableUserSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      await dispatch(PlatformUserActions.fetchUsers());
    } catch (error) {
      dispatch(ModalsActions.hideModal('EDIT_USER_FORM_MODAL'));
      dispatch(ModalsActions.showModal('DISABLE_USER_MODAL_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.disableUserError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onShowNewTagModal(tagName) {
    const { dispatch } = this.props;
    dispatch(initialize('UserTagForm', { name: tagName }));
    dispatch(ModalsActions.showModal('CREATE_NEW_TAG_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('forms.userTag'),
        hideCancel: true,
        content: (
          <UserTagForm
            onSubmit={creditsData => this.onCreateNewTag(creditsData)}
          />
        ),
        bodyTextStyle: { fontSize: 18 }, 
        bodyText: translations.t('forms.createNewTagDescription'),
        onConfirm: null,
        confirmText: translations.t('modals.confirm'),
      },
    }));
  }

  async onCreateNewTag(tagData) {
    const { dispatch, form } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      const newTag = await dispatch(PlatformUserActions.createUserTag(tagData));
      if (form && form.UserForm) {
        dispatch(change('UserForm', 'userTag', { value: newTag.id, label: newTag.name }))
      }
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('CREATE_NEW_TAG_MODAL'));
      dispatch(ModalsActions.showModal('CREATE_USER_TAG_MODAL_SUCCESS', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('users.createUserTagSuccess'),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('CREATE_USER_TAG_MODAL_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.createUserTagError'),
        },
      }));
    }
  }

  onTabChange(index) {
    const { dispatch } = this.props;
    this.setState({ activeTabIndex: index });
    const statusFilter = index === 0;
    dispatch(PlatformUserActions.resetUsersFilters());
    dispatch(PlatformUserActions.setUsersFilter('enabled', statusFilter));
    this.onSearchUser();
  }

  render() {
    const { classes, platformUsers: { data: { content: usersData, pagination } } } = this.props;
    const { isLoading, activeTabIndex } = this.state;
    let filteredUserData = activeTabIndex === 2 ? _.filter(usersData, user => user.userActivationUrl) : usersData;
    filteredUserData = activeTabIndex === 1 ? _.filter(usersData, user => !user.userActivationUrl) : filteredUserData;

    const isMobile = isMobileBrowser();
    return (
      <div ref={(c) => { this.containerDiv = c; }}>
        <MuiThemeProvider theme={theme}>
          <div className={classes.container}>
            <h1 className={classes.title}>{translations.t('users.platformUsers')}</h1>
            <ListComponent
              pagination={pagination}
              containerstyle={{ width: isMobile ? 'unset' : '90%' }}
              newElementText={translations.t('users.createNewUser')}
              searchFieldLabel={translations.t('users.userEmail')}
              onLoadMore={(page) => this.onAppendUsers(page)}
              onCreateNew={() => this.onNewUserClicked()}
              onSearch={(values) => this.onSearchUser(values)}
              onResetFilters={() => this.onResetUserFilters()}
            >
              <AppBar position="static" style={{ backgroundColor: 'white' }}>
                <Tabs
                  value={activeTabIndex}
                  onChange={(event, index) => this.onTabChange(index)}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label={translations.t('users.activeUsers')} icon={<ActiveUsersIcon />} />
                  <Tab label={translations.t('users.disabledUsers')} icon={<DisabledUsersIcon />} />
                  <Tab label={translations.t('users.inactiveUser')} icon={<UserInactiveIcon />} />
                  <Tab label={translations.t('users.usersTags')} icon={<UsersIcon />} />
                </Tabs>
              </AppBar>
              {activeTabIndex === 0 || activeTabIndex === 1 || activeTabIndex === 2 ? (
                <Paper className={classes.listContainer} square style={{ overflowY: 'scroll', maxHeight: '75vh' }}>
                {_.map(filteredUserData, (user) => (
                  <PlatformUserRow
                    key={user.id}
                    user={user}
                    outerContainerstyle={{ marginTop: 15 }}
                    onClick={() => this.onEditUserClicked(user)}
                  />
                ))}
                {(!filteredUserData || _.isEmpty(filteredUserData)) && !isLoading && (
                  <div className={classes.noUserContainer}>
                    <UsersIcon className={classes.noUserIcon} />
                    <h4 className={classes.noUserText}>{translations.t('users.noUsersFound')}</h4>
                  </div>
                )}
                {isLoading && _.isEmpty(filteredUserData) && (
                  <Spinner
                    title={translations.t('general.loading')}
                    hideLogo
                    spinnerStyle={{ color: '#5AC0B1', marginTop: 10 }}
                    titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
                  />
                )}
              </Paper>) : null
              }
              {activeTabIndex === 3 && <UsersTagsView />}
              
            </ListComponent>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  roles: state.roles,
  platformUsers: state.platformUsers,
  personalData: state.user.data,
  form: state.form,
});

export default connect(mapStateToProps)(withStyles(styles)(UsersView));
