import { withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import _ from 'lodash';
import React from 'react';

import MDRadioButtonView from './MDRadioButtonView';


const styles = (theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleText: {
    margin: 0,
    marginRight: 5,
    fontSize: 15,
    fontWeight: 'bold',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    height: 2,
  },
});

class MDRadioButtonsGroupField extends React.Component {
  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e.id);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  render() {
    const {
      groupLabel,
      groupName,
      options,
      horizontal,
      input,
      radioLabelStyle,
      required,
      classes,
      meta,
      showErrorLabel,
      containerstyle,
      title,
      hideStar,
    } = this.props;
    const hasError = meta.touched && meta.error;

    return (
      <FormControl component="fieldset" style={{ display: 'flex', ...containerstyle }}>
        <div className={classes.titleContainer}>
          <h4 className={classes.titleText} style={{ color: hasError ? 'red' : 'black' }}>{title}</h4>
          {!hideStar && required && <span style={{ color: '#D71F4B' }}>*</span>}
        </div>
        <RadioGroup
          aria-label={groupLabel}
          name={groupName}
          value={input.value}
          style={{ flexDirection: (horizontal ? 'row' : 'column') }}
        >
          <div style={{
            display: 'flex',
            flexDirection: horizontal ? 'row' : 'column',
            justifyContent: 'space-around',
          }}
          >
            {_.map(options, (option, index) => (
              <MDRadioButtonView
                key={index}
                checked={input.value === option.id}
                handleChange={(e) => this.onHandleChange(option, input)}
                value={option.id}
                labelStyle={radioLabelStyle}
                label={option.value}
              />
            ))}
          </div>
        </RadioGroup>
        {showErrorLabel && hasError && <h6 style={{ color: 'red', marginTop: 4, marginLeft: 10 }}>{meta.error}</h6>}
      </FormControl>
    );
  }
}

export default withStyles(styles)(MDRadioButtonsGroupField);
