import { Divider, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { USER_ROLES } from '../../config/configurations';
import * as AnalyticsActions from '../../redux/actions/analytics.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import translations from '../../translations/i18next';
import RadarChart from '../Charts/RadarChart';
import MDAccordion from '../MDAccordion/MDAccordion';
import Spinner from '../Spinner/Spinner';
import StarRateView from '../StarRateView/StarRateView';
import WarningIcon from '@material-ui/icons/Warning';

class QuizUserAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      instructionsLink: '',
      selfAssessmentData: null,
    };
  }

  async componentDidMount() {
    const {
      dispatch,
      quiz,
      user,
      hideSubSkills,
      skills: { data: { content: skillsData } },
    } = this.props;
    const instructionsLink = dispatch(UtilsActions.getInstructionLink());
    try {
      await dispatch(AnalyticsActions.fetchUserSkillsAnalytics(quiz, user));
      if (!hideSubSkills) {
        const subSkillsAnalyticsPromises = _.map(
          skillsData,
          async (skill) => dispatch(AnalyticsActions.fetchUserSubSkillsAnalytics(skill.id, quiz, user)),
          );
          await Promise.all(subSkillsAnalyticsPromises);
        }
      const userData = await dispatch(PlatformUsersActions.fetchUserDetails(user));
      const userSelfAssessmentData = userData && userData.selfAssessmentDTO && userData.selfAssessmentDTO.skillSelfAssessmentDTOSet ? userData.selfAssessmentDTO.skillSelfAssessmentDTOSet : [];
      this.setState({ instructionsLink, selfAssessmentData: userSelfAssessmentData, isLoading: false });
    } catch (error) {
      this.setState({ instructionsLink, isLoading: false });
    }
  }

  render() {
    const {
      skills: { data: { content: skillsData }, subSkills },
      analytics: { selectedQuiz: quizUsersAnalytics },
      user,
      hideSubSkills,
      selectedQuiz,
      userData
    } = this.props;
    const { isLoading, instructionsLink, selfAssessmentData } = this.state;

    const subSkillsFlat = _(subSkills)
      .flatten()
      .groupBy('parentId')
      .value();

    const isAnalyticsLoaded = quizUsersAnalytics && quizUsersAnalytics[`${user}`];
    

    const skillsAnalytics = _.map(skillsData, (skill) => {
      const current = isAnalyticsLoaded && _.find(quizUsersAnalytics[`${user}`]["skills"], { skillId: skill.id });
      return {
        name: skill.name,
        id: skill.id,
        value: current ? current.score : 0,
      };
    });

    const chartDatasets = {
      datasets: [
        {
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(72, 61, 139, 1)',
          pointBackgroundColor: 'rgba(72, 61, 139, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.currentScores'),
          data: _.map(skillsAnalytics, (item) => item.value),
        },
        {
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(240, 52, 52, 1)',
          pointBackgroundColor: 'rgba(240, 52, 52, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.selfAssessmentScores'),
          data: _.map(_.orderBy(selfAssessmentData, skill => skill.skillId), (item) => item.score),
        },
      ],
      labels: _.map(skillsAnalytics, (item) => item.name),
    };
    const canSeeScore = selectedQuiz.hideScore === false || (selectedQuiz.hideScore === true && userData.role.name !== USER_ROLES.USER);
    return (
      <div style={{ width: '100%' }}>
        {isLoading && (
          <Spinner
            title={translations.t('general.loading')}
            hideLogo
            spinnerStyle={{ color: '#5AC0B1', marginTop: 10 }}
            titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
          />
        )}
        {!isLoading && (
          <div style={{ width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <h2 style={{ fontWeight: 'normal' }}>{translations.t('analytics.userQuizSkillsScore')}</h2>
              <IconButton style={{ marginLeft: 10 }} onClick={() => window.open(instructionsLink)}>
                <InfoIcon />
              </IconButton> 
            </div>
            {canSeeScore ?
              <div style={{ width: "300", height: '300' }}>
                <RadarChart data={chartDatasets} min={0} max={5} stepSize={1} />
              </div> :
              <div style={{ display: 'flex', alignItems: 'center', width: '100vh', marginTop: 10, marginBottom: 20 }}>
                <WarningIcon style={{ color: 'red' }} />
                <h4 style={{ fontSize: 18, fontWeight: '100', margin: 0, marginLeft: 10 }}>{translations.t('quizzes.hideScoreAnalyticsDescription')}</h4>
              </div>
            }
            {!hideSubSkills ?
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ fontWeight: 'normal' }}>{translations.t('analytics.subskillsScores')}</h2>
                <IconButton style={{ marginLeft: 10 }} onClick={() => window.open(instructionsLink)}>
                  <InfoIcon />
                </IconButton>
              </div>
            : null}
            {
              canSeeScore ? _.map(skillsData, (skill) => {
                const skillData = _.find(skillsAnalytics, d => d.id === skill.id);
                const skillTotalPoints = skillData && skillData.value ? parseFloat(skillData.value).toFixed(1) : 0;
                return (
                  hideSubSkills ? (
                    <div>
                      <h3 style={{ marginBottom: 0 }}>{skill.name}</h3>
                      <StarRateView
                        value={skillTotalPoints}
                        totalValues={5}
                        starStyle={{ width: 15 }}
                        unselectedStarStyle={{ width: 9 }}
                        titleStyle={{ fontWeight: 100, fontSize: 15, margin: 0 }}
                      />
                    </div>
                  ): (
                  <MDAccordion
                    title={skill.name}
                    containerstyle={{ marginTop: 10 }}
                    titleStyle={{ fontSize: 20 }}
                    key={`userAnalytics-${skill.id}-${user}-${moment().valueOf()}`}
                    subtitle={(
                      <StarRateView
                        value={skillTotalPoints}
                        totalValues={5}
                        starStyle={{ width: 15 }}
                        unselectedStarStyle={{ width: 9 }}
                        titleStyle={{ fontWeight: 100, fontSize: 15, margin: 0 }}
                      />
                    )}
                  >
                    <div style={{ padding: 10 }}>
                      <Divider />
                      {_.map(subSkillsFlat[skill.id], (subSkill, key) => {
                        const subSkillValue = isAnalyticsLoaded && _.find(quizUsersAnalytics[`${user}`]["subskills"][skill.id], { skillId: subSkill.id });
                        return (
                          <div key={subSkill.id} style={{ marginBottom: 20 }}>
                            <StarRateView
                              title={subSkill.name}
                              value={subSkillValue ? subSkillValue.score : 0}
                              totalValues={5}
                              starStyle={{ width: 15 }}
                              unselectedStarStyle={{ width: 9 }}
                              starContainerStyle={{ marginBottom: 15, marginTop: 10 }}
                              titleContainerStyle={{ marginBottom: 10 }}
                              titleStyle={{ fontWeight: 100, fontSize: 18, margin: 0 }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </MDAccordion>
                  )
                );
              }) : null
            }
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  analytics: state.analytics,
  skills: state.skills,
  userData: state.user.data,
  selectedQuiz: state.quizzes.selectedQuiz,
});

export default connect(mapStateToProps)(QuizUserAnalytics);
