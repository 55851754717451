import { Divider, withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import IconTrash from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import * as ModalsActions from '../../../redux/actions/modal.actions';
import { LANGUAGE_LOCAL_MAP, USER_ROLES } from '../../../config/configurations';
import { EDULAI_BLUE, RED } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import MDButton from '../../MDButton/MDButton';
import UserStatusView from '../../UserProfileCard/UserStatusView';
import MDSelectField from '../FormsComponents/MDSelectedField/MDSelectField';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import SelectableField from '../FormsComponents/SelectableInput/SelectableField';
import WarningIcon from '@material-ui/icons/Warning';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'required';
  }
  if (!values.name) {
    errors.name = 'required';
  }
  if (!values.surname) {
    errors.surname = 'required';
  }
  if (!values.role) {
    errors.role = translations.t('forms.required');
  }
  if (!values.language) {
    errors.language = translations.t('forms.required');
  }
  return errors;
};

const styles = theme => ({
  formContainer: {
    margin: 20,
    marginTop: 20,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  headerTitle: {
    marginLeft: 20,
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  enabledText: {
    color: '#66c0b0',
    margin: 0,
  },
  disabledText: {
    color: 'red',
    margin: 0,
  },
  statusContainer: {
    marginLeft: 10,
    marginBottom: 20,
  },
  statusTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white',
  },
  warningLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  linkText: {
    textDecorationLine: 'underline',
    color: '#4285F4',
    fontSize: 16,
    fontWeight: 100
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

class UserForm extends React.Component {

  sanitizeRoles(roles) {
    const { personalRole } = this.props;
    let filteredRoles = roles;
    if (personalRole === USER_ROLES.ADMIN) {
      filteredRoles = _.filter(roles, role => role.name !== USER_ROLES.PRINCIPAL);
    }
    return filteredRoles;
  }

  async onFilterRoles(name) {
    const { roles } = this.props;
    let filteredOptions = _.filter(roles, (role) => { return translations.t(`roles.${role.name}`).toLowerCase().includes(name.toLowerCase()); });
    const newOptions = _.map(this.sanitizeRoles(filteredOptions), role => ({
      value: role.name, // workaround for creatable component
      label: translations.t(`roles.${role.name}`),
    }));
    return newOptions;
  }

  async onFilterUserTags (name) {
    const { platformUsers: { tags: { content: userTags } } } = this.props;
    let filteredOptions = _.filter(userTags, (tag) => { return tag.name.toLowerCase().includes(name.toLowerCase()); });
    const newOptions = _.map(filteredOptions, tag => ({
      value: tag.id, // workaround for creatable component
      label: tag.name,
    }));
    return newOptions;
  }

  onCopyUrl() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('COPY_SUCCESS_ALERT', {
      modalType: 'SUCCESS_ALERT',
      modalProps: {
        message: translations.t('forms.copySuccess'),
      },
    }));
  }

  render() {
    const {
      classes,
      dispatch,
      user,
      roles,
      form,
      onDeleteUser,
      onDisableUser,
      onCreateNewUserTag,
      onEnableUser,
      onResendPasswordActivation,
      platformUsers: { tags: { content: userTags } },
    } = this.props;
    const selectedRole = form && form.values && form.values.role && _.find(roles, role => role.name === form.values.role.value);
    const languageOptions = _.map(LANGUAGE_LOCAL_MAP, (language) => (
      {
        label: `${translations.t(`languages.${language.translation}`)}`,
        value: `${language.backend}`,
      }));
    const isEditing = user && !_.isEmpty(user) && user.id;
    const isEmptyTagSelection = form && form.values && (!form.values.userTag || _.isEmpty(form.values.userTag));
    const isUserInactive = user && !user.enabled && !_.isNull(user.userActivationUrl);
    return (
      <MuiThemeProvider theme={theme}>
        <h2 className={classes.headerTitle}>{translations.t('users.platformUser')}</h2>
        <div className={classes.formContainer}>
          <Field
            name="name"
            component={MDTextInputField}
            containerstyle={{ marginBottom: 20 }}
            variant="outlined"
            label={translations.t('forms.firstName')}
            required
          />
          <Field
            name="surname"
            variant="outlined"
            containerstyle={{ marginBottom: 20 }}
            component={MDTextInputField}
            label={translations.t('forms.lastName')}
            required
          />
          <Field
            name="email"
            component={MDTextInputField}
            containerstyle={{ marginBottom: 5 }}
            label="Email"
            variant="outlined"
            required
            onFormatText={(text) => text.toLowerCase()}
            disabled={user && !_.isEmpty(user)}
          />
          {!isUserInactive ? (
            <h4
              className="link-label"
              onClick={() => onResendPasswordActivation()}
              style={{ letterSpacing: 1, cursor: 'pointer', marginTop: 0, fontSize: 13, textAlign: 'right', color: '#88BDFB' }}
            >
              {translations.t('login.passwordRecoveryForUser')}
            </h4>
          ) : null}
          <Field
            name="language"
            component={MDSelectField}
            title={translations.t('languages.selectLanguage')}
            required
            variant="outlined"
            color="primary"
            options={languageOptions}
            containerstyle={{ marginTop: 20 }}
          />
          <Field
            name="role"
            component={SelectableField}
            placeholder={translations.t('forms.companyRolePlaceholder')}
            title={translations.t('forms.userRole')}
            mandatory
            containerstyle={{ marginTop: 20 }}
            titleContainerStyle={{ marginBottom: 0 }}
            onLoadOptions={name => this.onFilterRoles(name)}
            defaultOptions={_.map(this.sanitizeRoles(roles), role => ({
              value: role.name, // workaround for creatable component
              label: translations.t(`roles.${role.name}`),
            }))}
          />
          {selectedRole && (
            <div style={{ marginTop: 5, marginBottom: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InfoIcon style={{ fontSize: 15 }} />
              <h4 style={{ fontSize: 13, margin: 0, marginLeft: 10, fontWeight: 100 }}>{translations.t(`rolesDescriptions.${selectedRole.name}`)}</h4>
            </div>
          )}
          {selectedRole && selectedRole.name === USER_ROLES.USER && (
            <Field
              name="userTag"
              title={translations.t('forms.userTag')}
              subtitle={translations.t('forms.userTagDescription')}
              placeholder={translations.t('forms.userTagPlaceholder')}
              component={SelectableField}
              mandatory
              canCreate
              containerstyle={{ marginTop: 20 }}
              titleContainerStyle={{ marginBottom: 0 }}
              onLoadOptions={name => this.onFilterUserTags(name)}
              onNewOption={(name) => onCreateNewUserTag(name)}
              defaultOptions={_.map(userTags, tag => ({
                value: tag.id, // workaround for creatable component
                label: tag.name,
              }))}
            />
          )}
          {selectedRole && selectedRole.name === USER_ROLES.USER && isEmptyTagSelection && !isEditing && (
            <div style={{ marginTop: 5, marginBottom: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InfoIcon style={{ fontSize: 15 }} />
              <h4 style={{ fontSize: 13, margin: 0, marginLeft: 10, fontWeight: 100 }}>{translations.t('forms.edulaiTagDefaultDescription')}</h4>
            </div>
          )}
          <MDButton
            title={translations.t('forms.save')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{ marginBottom: 20 }}
            onClick={() => dispatch(submit('UserForm'))}
          />
          {isEditing && (
            <div>
              <Divider style={{ marginTop: 20 }} />
              <UserStatusView
                isInactive={isUserInactive}
                isEnabled={user && user.enabled}
                onEnableUser={() => onEnableUser(user.id)}
                onDisableUser={() => onDisableUser(user.id)}
              />
              {isUserInactive ? (
                <div style={{ marginTop: 20 }}>
                  <h4 className={classes.title} style={{ fontSize: 20 }}>{translations.t('forms.inactiveUserActivationLink')}</h4>
                  <h4 className={classes.subtitle} style={{ margin: 10 }}>{translations.t('forms.inactiveUserCopyLink')}</h4>
                  <div className={classes.warningLinkContainer}>
                    <WarningIcon style={{ color: 'red' }} />
                    <h5 className={classes.subtitle} style={{ fontWeight: 'bold', marginBottom: 0, marginLeft: 5 }}>{translations.t('forms.inactiveUserCopyWarning')}</h5>
                  </div>
                  <CopyToClipboard text={user.userActivationUrl} onCopy={() => this.onCopyUrl()}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CopyIcon />
                    <h3 className={classes.linkText} style={{ margin: 0, marginLeft: 10 }}>{user.userActivationUrl}</h3>
                    </div>
                  </CopyToClipboard>
                  <Divider style={{ marginTop: 20 }} />
                </div>
              ): null}
              <h2 className={classes.title}>{translations.t('forms.deleteUser')}</h2>
              <MDButton
                icon={<IconTrash />}
                backgroundColor={RED}
                title={translations.t('forms.deleteUser')}
                containerstyle={{ marginBottom: 20 }}
                onClick={() => onDeleteUser()}
              />
              <Divider style={{ marginTop: 20 }} />
            </div>
          )}

        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  form: state.form.UserForm,
  platformUsers: state.platformUsers,
});


export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'UserForm',
    validate,
    destroyOnUnmount: false,
  }),
  withStyles(styles),
])(UserForm);

