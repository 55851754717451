import i18next from 'i18next';

i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          general: {
            today: 'Today',
            loading: 'Loading...',
          },
          header: {
            users: 'Users',
            quiz: 'Quiz',
            questions: 'Questions',
            statistics: 'Statistics',
            skills: 'Skills & Sub-Skills',
            settings: 'Settings',
            domain: 'Domain Info',
          },
          skills: {
            COLLABORATION: 'Collaboration',
            CRITICAL_THINKING: 'Critical Thinking',
            PROBLEM_SOLVING: 'Problem Solving',
            LEADERSHIP: 'Leadership',
            COMMUNICATION: 'Communication',
            INTERCULTURALISM: 'Interculturalism',
            LEARNING: 'Learning',
            systemSkills: 'System\'s Skills',
            createNewSkill: 'New Skill',
            noSkillFound: 'No Skill found in the system',
            systemSubSkill: 'Sub-Skill',
            systemSubSkills: 'Sub-Skills',
            noSubSkillForSkill: 'No Sub-Skill defined for the selected skill',
            systemSubSkillsDescription: 'Define one or more Sub-Skills associated with the current Skill',
          },
          login: {
            noService: 'Service temporary not available. Please reload the page to retry',
            welcome: 'Welcome back',
            passwordRecovery: 'Forgot your password?',
            passwordRecoveryBody: 'Enter your email below. We will send you and email with a link to reset your password',
            loginError: 'Login error. Please check your credentials and try again',
            welcomeFirstTime: 'Set your password to access',
            confirmRegistrationError: 'An error occurred while creating your account. Please try again',
            creatingYourAccount: 'We are creating your new account!',
            createAccountSuccess: 'Account created successfully! Check your email to set your password and login',
            resetPasswordSuccess: 'An email with password recovery instructions has been send!',
            resetPasswordError: 'An error occurred on the password recovery. Please try again',
            passwordSetSuccess: 'Password set successfully!',
            passwordSetError: 'An error occurred while creating the new password. Please try again',
            insertEmail: 'Your email',
            insertPassword: 'Your password',
            registerNow: 'No account yet? Register now!',
            registerUserBody: 'Insert your data to create your Edulai account!',
            registerUserEmail: 'We will send you and email with the instructions to set your password',
            createNewAccount: 'Create a new account',
            acceptPrivacy: 'I\'ve read and accept the Privacy Policy',
            passwordRecoveryForUser: 'Send password recovery email',
          },
          modals: {
            cancel: 'Cancel',
            continue: 'Continue',
            confirm: 'Confirm',
            ok: 'Ok',
            compliment: 'Congratulations!',
            publish: 'Publish',
            close: 'Close',
            delete: 'Delete',
            save: 'Save',
            reloadPage: 'Reload Page',
            closeWithoutSave: 'Close without saving',
          },
          languages: {
            language: 'Language',
            it: 'Italian',
            en: 'English',
            ITALIAN: 'Italian',
            ENGLISH: 'English',
            selectLanguage: 'Select a language',
          },
          user: {
            profile: 'Profile',
            ageRange: 'Age',
            gender: 'Gender',
            nation: 'Nation',
            employment: 'Employment',
            professionName: 'Profession',
            experienceAgeRange: 'Years of experience',
            educational: 'Educational level',
            portfolioDescription: 'Portfolio',
            employmentsTypes: {
              STUDENT: 'Student',
              UNEMPLOYED: 'Unemployed',
              EMPLOYED: 'Employed',
            },
            ageRangeTypes: {
              RANGE18_22: '18-22 years',
              RANGE23_25: '23-25 years',
              RANGE26_30: '26-30 years',
              RANGE31_40: '31-40 years',
              RANGE41_50: '41-50 years',
              RANGE51_99: '50+',
            },
            experienceAgeRangeTypes: {
              RANGE0_1: 'Less than one year',
              RANGE1_2: '1-2 years',
              RANGE2_5: '2-5 years',
              RANGE5_10: '5-10 years',
              RANGE10_20: '10-20 years',
              RANGE20_99: 'More than 20 years',
            },
            genderTypes: {
              MALE: 'Male',
              FEMALE: 'Female',
              UNSPECIFIED: 'Unspecified',
            },
            educationalTypes: {
              SECONDARY_SCHOOL: 'High school',
              GRADUATION: 'University degree',
              POSTGRADUATE_CERTIFICATE: 'Post-university certification',
            },
            onboardingUpdatedAt: 'Profile updated at {{date}}',
          },
          forms: {
            copySuccess: 'Link copied on clipboard',
            inactiveUserActivationLink: 'Activation Link',
            inactiveUserExplanation: 'User has not yet activate his account',
            inactiveUserCopyLink: 'If the user has difficulty activating his account, you can send him this activation link',
            inactiveUserCopyWarning: 'Warning: use this link only in cases of extreme necessity. Check that the user has entered a valid email and that he is sending the link to the correct account. Once in possession of the activation link, anyone can also activate this account incorrectly',
            selectSkill: 'Select a skill',
            today: 'Today',
            thisMonth: 'This month',
            thisYear: 'This year',
            thisWeek: 'This week',
            edulaiTagNotModify: 'The Edulai group cannot be modified',
            tagName: 'Group name',
            selectSkillsToCreateAnswers: 'Select at least two Skills and Sub-Skills to start create new answers',
            forceUserOnboardingDescription: 'Force user to take onboarding again',
            forceUserOnboarding: 'Force onboarding',
            newSubSkill: 'New Sub-Skill',
            selfAssessmentDescription: 'For each skill indicate your mastery level with a value from 1 to 5, where 1 indicates the basic level and 5 the Expert level',
            saveDraft: 'Save quiz draft',
            backtoLogin: 'Back to Login',
            noOptions: 'No options',
            loadingOptions: 'Loading options',
            companyRolePlaceholder: 'Search role...',
            required: 'Required',
            save: 'Save',
            search: 'Search',
            reset: 'Reset',
            name: 'Name',
            edit: 'Edit',
            send: 'Send',
            createNew: 'Create new',
            saveChanges: 'Save changes',
            firstName: 'Name',
            lastName: 'Last name',
            address: 'Address',
            next: 'Next',
            back: 'Back',
            skip: 'Skip',
            finish: 'Finish',
            optional: 'Optional',
            confirmPassword: 'Confirm password',
            newPassword: 'Create your password',
            passwordValidation: 'Minimum 8 characters a letter and no special characters',
            passwordMustCoincide: 'Password must coincide',
            passwordSpecialCharacters: 'NO special characters',
            warning: 'Warning',
            confirm: 'Confirm',
            close: 'Close',
            bio: 'Bio',
            emailNotValid: 'Email not valid',
            logoutConfirmation: 'Are you sure you want to logout?',
            iAcceptThe: 'I\'ve read and accept the',
            privacyPolicy: 'Privacy Policy',
            confirmPasswordLabel: 'Confirm your password',
            confirmRegistration: 'Create your account',
            confirmPasswordTitle: 'Confirm password',
            userRole: 'User role',
            deleteUser: 'Delete user',
            disableUserExplanation: 'Disable the user to revoke all the priviliges in the institution',
            userStatus: 'User status',
            enabledUserExplanation: 'The user has been disabled',
            onboardingTitle: 'Welcome to Edulai!',
            onboardingDescription: 'Please take few minutes to let us know you better',
            whatIsYourAge: 'How old are you?',
            startEdulai: 'Start using Edulai!',
            whatIsYourGender: 'What\'s your gender?',
            male: 'Male',
            female: 'Female',
            other: 'Other',
            insertNation: 'Insert your nation',
            whatIsYourNation: 'What\'s your nation?',
            whatIsYourOccupancy: 'You are currently',
            student: 'Student',
            unemployed: 'Unemployed',
            professionist: 'Employed professionist',
            whatIsYourJob: 'If you are employed, what is the name of your main professional role?',
            insertJobTitle: 'Enter the name of your role',
            yearsOfExperince: 'How many years of professional experience you have in that role?',
            insertYearsOfExperince: 'Insert your years of experience',
            years: 'Years',
            lessThanOneYear: 'Less then 1 year',
            moreThanTwentiYear: 'More than 20 years',
            whatIsYourScholarLevel: 'What is your school level?',
            highSchool: 'High school',
            universityDegree: 'University degree',
            postUniversityDegree: 'Post-university certification',
            stageOrInsterests: 'Have you ever done internships of any kind, studies or work abroad, or do you have personal interests that have helped you develop skills for the job market? If yes, select the skills you have gained and explain below which experiences have been acquired. If applicable, you can discuss these skills directly with employers',
            stageOrInsterestsSupport: 'Cross this box if you have material produced to support the skills you have listed above, as part of your professional portfolio to show to employers and indicate what material it is (e.g. video recordings of your presentations, written works or projects you made, etc)',
            stageOrInsterestsBadges: 'Have you ever obtained digital badges for one or more of the above skills? If so, does it indicate for which competences you have gained them? Please also provide the link to badges',
            skillName: 'Skill name',
            newSkillWarning: 'The new skill will only be available for creating or editing new questions. The changes will not involve Quiz already created or in progress',
            skillCreatedSuccess: 'Skill created successfully',
            skillCreatedError: 'An error occurred while creating the skill. Please try again',
            skillUpdatedSuccess: 'Skill updated successfully',
            skillUpdatedError: 'An error occurred while updating the skill. Please try again',
            skillDeleteWarning: 'By deleting this skill you will make it unavailable for new Quizzes. Previous Quizzes including this skill will not be affected by this change and the skill will still be visibile on statistics',
            skillDeleteError: 'An error occurred while deleting this skill',
            skillDeleteSuccess: 'Skill deleted successfully',
            subSkills: 'Sub-Skills',
            subSkillDeleteWarning: 'By deleting this Sub-Skill you will make it unavailable for new Quizzes. Previous Quizzes including this Sub-Skill will not be affected by this change and the Sub-Skill will still be visibile on statistics',
            subSkillCreatedSuccess: 'Sub-Skill created successfully',
            subSkillCreatedError: 'An error occurred while creating the Sub-Skill. Please try again',
            subSkillUpdatedSuccess: 'Skill updated successfully',
            subSkillUpdatedError: 'An error occurred while updating the Sub-Skill. Please try again',
            subSkillDeleteError: 'An error occurred while deleting this Sub-Skill',
            subSkillDeleteSuccess: 'Sub-Skill deleted successfully',
            deleteSubSkill: 'Delete Sub-Skill',
            deleteSkill: 'Delete Skill',
            expirationDate: 'Expiration date',
            creationDate: 'Creation date',
            credits: 'Remaining credits',
            subscriptionType: 'License type',
            addCredits: 'Add credits',
            setCredits: 'Set credits',
            totalCredits: 'Total credits',
            creditsLabel: 'Credits',
            addCreditsDescription: 'Modify the credits for the institution',
            addCreditSuccess: 'Credits modified successfully',
            addCreditError: 'An error occurred while modifying the credits. Please try again',
            modifyDomainType: 'Modify license type',
            modifyDomainTypeDescription: 'You can change the type of domain license to Flat License (unlimited credits) and PPU License (limited credits).',
            modifyDomainTypeWarning: 'Are you sure you want to change the license?',
            modifyDomainTypeSuccess: 'License successfully modified',
            modifyDomainTypeError: 'An error occurred while changing the license. Please try again',
            flatSubscriptionDescription: 'Flat (unlimited credits)',
            ppuSubscriptionDescription: 'PPU (pay per credits)',
            flatCreditsDescription: 'In FLAT-type domains the remaining credits will not be deducted on new quizzes submissions',
            domainStatus: 'Domain Status',
            disabled: 'Disabled',
            enabled: 'Active',
            disableDomain: 'Disable Domain',
            enableDomain: 'Activate Domain',
            enableUserSuccess: 'User enabled successfully',
            enableUser: 'Enable user',
            disableDomainRequest: 'Are you sure you want to disabled the domain? It will not be possible to create new users, submit new answers and create new Quizzes',
            modifyDomainStatusSuccess: 'Domain status modified successfully',
            modifyDomainStatusError: 'An error occurred while modifying the domain status',
            title: 'Title',
            text: 'Text',
            evaluatedSkills: 'Evaluated skills',
            startDate: 'Start date',
            startTime: 'Start time',
            startDateTime: 'Start date & time',
            endDate: 'End date',
            endTime: 'End time',
            endDateTime: 'End date & time',
            min1hr: '1 hour minimum',
            startBeforeEnd: 'Start date after end date',
            newEndBeforeOldEnd: 'New end date is before original one',
            quizInPast: 'Can\'t create quiz in the past',
            delete: 'Delete',
            totals: 'Totals',
            total: 'Total',
            creditsAfterOperations: 'Credits after the operation: {{credits}}',
            selectOperation: 'Select operation',
            modifyCredits: 'Modify Credits',
            userTagPlaceholder: 'Select a group for the user',
            userTag: 'User group',
            userTagDescription: 'Use groups to allow you to easily select multiple users',
            createNewOption: 'Create new',
            createNewTagDescription: 'Create a new group for users',
            createNewTag: 'Create new group',
            modifyTagDescription: 'Modify the group of users',
            deleteTagConfirm: 'Are you sure you want to delete this group? Operation cannot be undone',
            dateInterval: 'Date interval',
            applyFilters: 'Apply filters',
            resetFilters: 'Reset filters',
            stageOrInsterestsDescription: 'Skills and experience gained on a stage',
            badgeLinksDescription: 'Digital badges links',
            edulaiTagDefaultDescription: 'If you don\'t select a group, the user will belong to the Edulai default group',
            editSkillName: 'Edit skill name',
            cantDeleteReferencedSkill: 'Can\'t delete skill. It\'s referenced by one or more questions.',
            deleteQuestionWarning: 'By deleting this question you will make it unavailable for new Quizzes. Previous Quizzes including a previous version of this question will not be affected by this change',
            deleteQuestionSuccess: 'Question deleted',
            deleteQuestionError: 'An error occurred deleting the question',
            deleteQuestionInUseError: 'Error: the current version of this question is in use in a Quiz',
            maxCharacters: 'Max {{number}} characters',
          },
          domainTypes: {
            FLAT: 'Flat subscription',
            PPU: 'Pay per use subscription',
          },
          users: {
            inactive: 'Inactive',
            inactiveUser: 'Inactive users',
            tagUsers: 'Users',
            usersTags: 'Users groups',
            deleteTagSuccess: 'Group deleted',
            deleteTagError: 'Error while deleting the group',
            createUserTagSuccess: 'Group created',
            createUserTagError: 'Error while creating the group',
            editUserTagSuccess: 'Group modified',
            editUserTagError: 'Error while editing the group',
            userData: 'User Data',
            userProfile: 'User profile',
            platformUsers: 'Platform users',
            platformUser: 'Platform user',
            createNewUser: 'New user',
            userEmail: 'User email',
            noUsersFound: 'No user found',
            disabled: 'Disabled',
            enabled: 'Active',
            disableUser: 'Disable user',
            userModifyError: 'An error occurred while updating your data',
            userModifySuccess: 'Profile updated successfully',
            modifyUserSuccess: 'User data updated successfully',
            createUserSuccess: 'User created successfully. The new user will be INACTIVE until it will activate its account by creating its own password',
            deleteUserConfirm: 'Are you sure to delete the selected user? The operation is not reversible.',
            resendConfirmationEmail: 'Resend confirmation email',
            resendActivationEmail: 'Send activation email',
            userAlreadyExists: 'An user with the same email already exists',
            userDeleteSuccess: 'User deleted successfully',
            disableUserSuccess: 'User disabled successfull',
            disableUserError: 'An error occurred while disabling the user. Please try again',
            newSubSkill: 'New Sub-Skill',
            activeUsers: 'Active users',
            disabledUsers: 'Disabled users',
            onboardingFailed: 'An error occurred while updating user data. Please try again',
          },
          questions: {
            newQuestion: 'New question',
            editQuestion: 'Edit question',
            findInTitle: 'Find in the title...',
            noQuestionsFound: 'No questions found',
            createQuestionSuccess: 'Question created successfully',
            createQuestionError: 'An error occurred while creating the question. Please try again',
            modifyQuestionSuccess: 'Question modified successfully',
            modifyQuestionError: 'An error occurred while modifying the question. Please try again',
            answers: 'Answers',
            newAnswer: 'New answer',
            editAnswer: 'Edit answer',
            deleteOptionConfirm: 'Are you sure to delete the selected option? The operation is not reversible.',
            optionDeleteSuccess: 'Option deleted successfully',
            optionsNumberError: 'Please, insert at least 3 valid answers.',
            firstOptionCreate: 'After creating the first answer, editing skills and subskills will reset all the answers. Are you sure you want to proceed?',
            myAnswers: 'My answers',
            version: 'Version',
          },
          roles: {
            ROOT: 'Root',
            ADMIN: 'Admin',
            SUPERVISOR: 'Supervisor',
            USER: 'Basic user',
            PRINCIPAL: 'Principal',
          },
          rolesDescriptions: {
            ROOT: 'This role is dedicated to the managers of the Edulai cloud platform',
            ADMIN: 'The Admins are responsible for the platform user management and customers registry',
            PRINCIPAL: 'The Principal is the head of the institution. He can see global statistics and metrics',
            SUPERVISOR: 'Supervisors are responsible to create and manage Quizzes',
            USER: 'Basic users can receive and submit answers to Quizzes',
          },
          domain: {
            customFields: 'Custom user field',
            createCustomField: 'Create custom field',
            customFieldDescription: 'This custom field will be presented as a question in the user onboarding',
            customFieldTitle: 'Custom field name',
            customFieldBody: 'Custom field question body',
            customFieldSuccess: 'Custom fields edited successfully',
            customFieldError: 'An error occurred while editing the custom fields',
            deleteCustomFieldRequest: 'Are you sure you want to delete this custom field? Operation cannot be undone',
            customFieldDeleteSuccess: 'Field deleted successfully',
            customFieldDeleteError: 'An error occurred deleting this field',
          },
          quizzes: {
            types: {
              DRAFT: 'DRAFT',
              RUNNING: 'RUNNING',
              PAUSED: 'PAUSED',
              DONE: 'DONE',
              READY: 'READY',
              ALL: 'ALL',
              EXPIRED_FOR_USER: 'EXPIRED',
            },
            typesDescriptions: {
              DRAFT: 'Drafter quiz can be modified until submitted',
              RUNNING: 'The quiz is running and users can submit their answers',
              PAUSED: 'The quiz is paused and users cannot submit their answers',
              DONE: 'The quiz is done!',
              READY: 'Quiz will start when its start date expire or if started explicitly',
              EXPIRED_FOR_USER: 'Quiz is over and you haven\'t submit your answers',
            },
            selectSupervisor: 'Select a Supervisor',
            selectSupervisorDescription: 'If you don\'t select a supervisor you will be responsible for this quiz!',
            noCreditsError: 'Domain credits expired! Please add new credits to continue',
            activeQuizzes: 'Active quizzes',
            disabledQuizzes: 'Disabled quizzes',
            newQuiz: 'New quiz',
            noQuizzesFound: 'No quizzes found',
            editQuiz: 'Edit quiz',
            createQuizSuccess: 'Quiz successfully created. Quiz will remain in "Draft" state until it will be started explicitly',
            createQuizError: 'An error occurred while creating the quiz. Please try again',
            participants: 'Participants',
            users: 'Users',
            questions: 'Questions',
            selectedQuestionsNumber: 'Number of question selected',
            validity: 'Validity',
            completed: 'Completed',
            progress: 'Progress',
            submit: 'Submit',
            submitDraft: 'Quiz ready!',
            submitQuizError: 'An error occurred while submitting the quiz. Please try again',
            quizSubmitSuccess: 'Quiz submitted successfully. Quizz will remain in "Ready" state until its start date',
            pause: 'Pause',
            resume: 'Resume',
            pauseQuizError: 'An error occurred while pausing the quiz. Please try again',
            quizPauseSuccess: 'Quiz paused successfully',
            resumeQuizError: 'An error occurred while resuming the quiz. Please try again',
            quizResumeSuccess: 'Quiz resumed successfully',
            changeStartDate: 'Change start date & time',
            forceStart: 'Start now',
            newStartDateTime: 'New start date & time',
            changeStartDateError: 'An error occurred while chainging quiz start date & time. Please try again',
            changeStartDateSuccess: 'Start date & time changed successfully',
            confirmForceStart: 'Are you sure you want to immediately start the quiz?',
            forceStartError: 'An error occurred while starting the quiz. Please try again',
            forceStartSuccess: 'Quiz started successfully',
            addNewParticipants: 'Add new participants',
            newParticipants: 'New participants',
            addParticipantsSuccess: 'New participants successfully added',
            addParticipantsError: 'An error occurred while adding new participants. Please try again',
            terminate: 'Terminate',
            confirmTerminateQuiz: 'Are you sure you want to immidiately terminate the quiz? The action will be irreversible.',
            terminateQuizSuccess: 'Quiz successfully terminated',
            terminateQuizError: 'An error occurred while terminating the quiz. Please try again',
            editQuizSuccess: 'Quiz successfully updated. Quiz will remain in "Draft" state until it will be started explicitly',
            editQuizError: 'An error occurred while updating the quiz. Please try again',
            reactivate: 'Reactivate',
            confirmReactivateQuiz: 'Are you sure you want to reactivate the quiz?',
            reactivateQuizSuccess: 'Quiz successfully reactivated',
            reactivateQuizError: 'An error occurred while reactivating the quiz. Please try again',
            createdBy: 'Supervisor',
            extendQuizSuccess: 'Quiz successfully extended',
            extendQuizError: 'An error occurred while extending the quiz. Please try again',
            newEndDateTime: 'New end date & time',
            extendExpiration: 'Extend expiration',
            confirmDeleteQuiz: 'Are you sure you want to immidiately delete the quiz? The action will be irreversible.',
            deleteQuizSuccess: 'Quiz successfully deleted',
            deleteQuizError: 'An error occurred while deleting the quiz. Please try again',
            showQuestionsDetails: 'Show questions details',
            givenAnswers: 'Answers completed',
            totalQuestions: 'Total Questions',
            quizStatistics: 'Statistics',
            userAnswers: 'Show answers',
            sendReminder: 'Send reminder',
            sendReminderSuccess: 'Reminder sent',
            sendReminderError: 'Send reminder failed',
            hideScoresToUsers: 'Hide scores to participants',
            hideScores: 'Hide scores',
            hideScoresDescription: 'If you hide the scores, users will be able to review their answers to the quiz but not their results',
            hideScoreAnalyticsDescription: 'The quiz scores are hidden by the supervisor',
            updateHideScoreSuccess: 'Hide scores successfully updated',
            updateHideScoreError: 'An error occurred while updating hide scores. Please try again',
          },
          analytics: {
            userScore: 'Score for user',
            score: 'Score',
            subskillsScores: 'Subskills scores',
            skillsScores: 'Skills scores',
            analytics: 'Analytics',
            submissionsTrend: 'Submissions trend',
            usersSubmissions: 'Participants submissions',
            noSubmissionStarted: 'No submission found for this user',
            submissionStillDraft: 'The user has started a submission but hasn\'t finalized it',
            currentScores: 'Current scores',
            selfAssessmentScores: 'Self assessment',
            userQuizSkillsScore: 'User scores for the quiz',
            usersQuizSkillsScore: 'User scores',
            skillsTrendChart: 'Skills trend chart',
            leastPerformingUser: 'Least performing user',
            bestPerformingUser: 'Best performing user',
            skills: 'Skills',
            all: 'All',
            errorCreateCSV: 'An error occurred while creating the CSV',
            chartForUser: 'Analytics for user',
            chartForUserOverTime: 'Analytics over time for user {{user}}',
            overallStatisticsForUser: 'Overall statistics of user {{user}}',
            overallAnalytics: 'System analytics',
            filters: 'Filters',
            chartMeanOverall: 'Overall means of system\'s skills',
            chartMeanOverTime: 'Overall means of system\'s skills in the selected time frame',
            chartForUserMean: 'Aggregate average score of user {{user}}',
            overallAnalyticsOverTime: 'System skills over time',
            overallAnalyticsOverTimeDescription: 'This chart shows the trend of the system skills in the selected time frame',
            currentScoresOfSystem: 'Current scores of all users',
            summaryDescription: 'This chart shows the system scores for all platform users for each skill',
            userSummaryScores: 'Aggregated score for every user',
            userSummaryScoresDescription: 'This chart shows for each user the average scores',
            detailedAnalytics: 'Detailed analytics',
            selectSkillForChart: 'Select a skill to see the users scores',
            usersSkillsScores: 'Users point for all skills',
            overallStatisticsForUserDescription: 'Select a user from Filters to see details',
            userSkillsScore: 'User skills scores',
            usersSkillScores: 'Users scores for {{skill}}',
            userSummaryBubbleScores: 'User percentage per score',
            userSummaryBubbleScoresDescription: 'This chart shows for every class of score the percentage of users',
            percentage: 'Percentage',
            everyone: 'All users',
            usersPercentage: 'Percentage of users',
            downloadCsvSuccess: 'Your report has been sent. Check your email in a few minutes',
          },
          submissions: {
            answer: 'Answer',
            submissionLoadFailed: 'An error occurred while loading the quiz',
            pleaseReload: 'Please try again.',
            submissionsFor: 'Submissions for {{quizName}}',
            closedAt: 'closed at',
            arePaused: 'are suspended.',
            waitForSupervisor: 'Waif for further instructions from the quiz supervisor',
            willStart: 'will start',
            soon: 'soon',
            areOpen: 'are open',
            startNewSubmission: 'start the quiz',
            noAnswerSelectedError: 'Please, select one of the options.',
            submitAnswer: 'Submit answer',
            concludeQuiz: 'Finish quiz',
            answerSubmitSuccess: 'Answer successfully submitted',
            answerSubmitError: 'An error occurred while submitting the answer. Please try again',
            quizSubmitted: 'Quiz submitted',
            submissionSubmitSuccess: 'Quiz successfully submitted',
            submissionSubmitError: 'An error occurred while closing the quiz. Please try again',
            submissionGuidelines1: 'The purpose of the Edulai Skills Checker is to assess the level of some key Soft Skills of yours, important for employers. The Edulai Skills Checker assessment can be performed one time or more than once in different moments in time,(so that the development of one or more skills can be monitored.',
            submissionGuidelines2: 'The Edulai Skills Checker presents several concrete professional cases you have to read carefully. Each case describes a specific situation and you need to pick up, among the 5 answers/solutions provided, the one you feel more comfortable with.  Do not spend too much time to think about the answer you will choose, there is not a right or a wrong one. Filling in the Skills Checker will require between 15 and 20 minutes of your time.',
            submissionGuidelines3: 'The answers need to be provided one after the other, so you cannot skip any of them or you cannot go back and forth with the questions. The Skills Checker needs to be completed in “one go” but just in case of technical problems that will prevent you to complete the check, the system will give you the chance to access the check again and to submit the remaining missed answers afterwards.',
            submissionGuidelines4: 'Once you have submitted all your answers, the system will automatically show you the results and you will be able to access both a more general and a more detailed report. Guidelines on how to read your results will be also provided. ',
            sybmissionGuidelinesSmartWorking: 'With the words “Remote Working” we refer to the possibility to carry out one\'s work wherever one is, regardless of his/her geographic location, even if it can mainly take place from home. This definition also includes the use of communication technologies such as PC, internet, email, video conferencing systems, for the realization of daily work tasks and for working with colleagues in virtual/online teams. (Smarter Working Guide, 2007)',
            submissionCompleted: 'You have answered all questions! Send your responses to complete the quiz',
            noSubmissionSendQuizDone: 'You haven\'t submit your answers for this quiz!',
            submissionsRefusedNoCredits: 'Your institution can\'t accept further submissions. Please contact your supervisor',
          },
        },
      },
      it: {
        translation: {
          general: {
            loading: 'Caricamento...',
            today: 'Oggi',
          },
          header: {
            users: 'Utenti',
            quiz: 'Quiz',
            questions: 'Domande',
            statistics: 'Statistiche',
            skills: 'Skills e Sotto skills',
            settings: 'Impostazioni',
            domain: 'Informazioni dominio',
          },
          skills: {
            COLLABORATION: 'Collaborazione',
            CRITICAL_THINKING: 'Pensiero Critico',
            PROBLEM_SOLVING: 'Problem Solving',
            LEADERSHIP: 'Leadership',
            COMMUNICATION: 'Comunicazione',
            INTERCULTURALISM: 'Interculturalità',
            LEARNING: 'Apprendimento',
            systemSkills: 'Skills di sistema',
            createNewSkill: 'Nuova Skill',
            noSkillFound: 'Nessuna Skill trovata a sistema',
            systemSubSkill: 'Sotto-Skill',
            noSubSkillForSkill: 'Nessuna Sub-Skill definita per la Skill selezionata',
            systemSubSkills: 'Sotto-Skills',
            systemSubSkillsDescription: 'Definisci una o più Sotto-Skill associate alla Skill corrente',
          },
          login: {
            noService: 'Servizio temporaneamente non disponibile. Per favore ricarica la pagina e riprova',
            welcome: 'Bentornato!',
            passwordRecovery: 'Password dimenticata?',
            passwordRecoveryBody: 'Inserisci la tua email nel campo sottostante. Ti invieremo un\'email con le istruzioni per il recupero password',
            loginError: 'Login fallito. Per favore controlla le tue credenziali e riprova',
            welcomeFirstTime: 'Imposta la tua password per accedere',
            confirmRegistrationError: 'Si è verificato un errore nella creazione dell\'account. Per favore riprova',
            creatingYourAccount: 'Stiamo creando il tuo nuovo account!',
            createAccountSuccess: 'Account creato con successo. Controlla la tua posta elettronica per impostare la password ed effettuare il login',
            resetPasswordSuccess: 'Un\'email con le istruzioni di recupero è stata inviata!',
            resetPasswordError: 'Si è verificato un errore nel recupero password. Per favore riprova',
            passwordSetSuccess: 'Password impostata con successo! Vai al login per accedere',
            passwordSetError: 'Si è verificato un errore nell\'impostare la password. Per favore riprova',
            insertEmail: 'La tua email',
            insertPassword: 'La tua password',
            registerNow: 'Non hai un account? Registrati adesso!',
            registerUserBody: 'Inserisci i tuoi dati per creare il tuo account Edulai!',
            registerUserEmail: 'Ti invieremo un\'email con le istruzioni per impostare la tua password',
            createNewAccount: 'Crea un nuovo account',
            insertFirstName: 'Il tuo nome',
            insertLastName: 'Il tuo cognome',
            acceptPrivacy: 'Ho letto e accetto la Privacy Policy',
            passwordRecoveryForUser: 'Invia email di recupero password',
          },
          modals: {
            cancel: 'Cancella',
            continue: 'Continua',
            confirm: 'Conferma',
            ok: 'Ok',
            compliment: 'Complimenti',
            publish: 'Pubblica',
            close: 'Chiudi',
            delete: 'Elimina',
            save: 'Salva',
            reloadPage: 'Ricarica Pagina',
            closeWithoutSave: 'Chiudi senza salvare',
          },
          languages: {
            language: 'Lingua',
            it: 'Italiano',
            en: 'Inglese',
            ITALIAN: 'Italiano',
            ENGLISH: 'Inglese',
            selectLanguage: 'Seleziona lingua',
          },
          user: {
            profile: 'Profilo',
            ageRange: 'Fascia d\'età',
            gender: 'Genere',
            nation: 'Nazione',
            employment: 'Impiego',
            professionName: 'Professione',
            experienceAgeRange: 'Anni di esperienza',
            educational: 'Livello istruzione',
            portfolioDescription: 'Portfolio',
            employmentsTypes: {
              STUDENT: 'Studente',
              UNEMPLOYED: 'Disoccupato',
              EMPLOYED: 'Impiegato',
            },
            ageRangeTypes: {
              RANGE18_22: '18-22 anni',
              RANGE23_25: '23-25 anni',
              RANGE26_30: '26-30 anni',
              RANGE31_40: '31-40 anni',
              RANGE41_50: '41-50 anni',
              RANGE51_99: '50+ anni',
            },
            experienceAgeRangeTypes: {
              RANGE0_1: 'Meno di un anno',
              RANGE1_2: '1-2 anni',
              RANGE2_5: '2-5 anni',
              RANGE5_10: '5-10 anni',
              RANGE10_20: '10-20 anni',
              RANGE20_99: 'Più di 20 anni',
            },
            genderTypes: {
              MALE: 'Maschio',
              FEMALE: 'Femmina',
              UNSPECIFIED: 'Non specificato',
            },
            educationalTypes: {
              SECONDARY_SCHOOL: 'Scuola superiore',
              GRADUATION: 'Diploma/Laurea Universitaria',
              POSTGRADUATE_CERTIFICATE: 'Certificazione post-universitaria',
            },
            onboardingUpdatedAt: 'Profilo aggiornato al {{date}}',
          },
          forms: {
            copySuccess: 'Link copiato negli appunti',
            inactiveUserActivationLink: 'Link di attivazione',
            inactiveUserExplanation: 'L\'utente non ha ancora attivato il suo account',
            inactiveUserCopyLink: 'Se l\'utente ha difficoltà ad attivare il suo account puoi inviargli questo link di attivazione',
            inactiveUserCopyWarning: 'Attenzione: utilizza questo link solo in casi di estrema necessità. Verifica che l\'utente abbia inserito un\'email valida e di star inviando il link all\'account corretto. Una volta in possesso del link di attivazione, chiunque potrà attivare questo account anche in maniera erronea',
            selectSkill: 'Seleziona una skill',
            today: 'Oggi',
            thisMonth: 'Questo mese',
            thisYear: 'Quest\'anno',
            thisWeek: 'Questa settimana',
            edulaiTagNotModify: 'Il gruppo Edulai non può essere modificato',
            tagName: 'Nome gruppo',
            selectSkillsToCreateAnswers: 'Seleziona almeno due skills e due sottoskills per iniziare a creare le risposte',
            badgeLinksDescription: 'Link a badge digitali',
            forceUserOnboardingDescription: 'Forza l\'utente ad effettuare nuovamente l\'onboarding',
            forceUserOnboarding: 'Forza onboarding',
            enableUser: 'Attiva utente',
            selfAssessmentDescription: 'Indica il livello di padronanza che ritieni di avere per ogni skill indicando un valore da 1 a 5, dove 1 indica il livello Base e 5 quello di Esperto',
            saveDraft: 'Salva bozza quiz',
            backtoLogin: 'Torna al login',
            noOptions: 'Nessuna opzione',
            loadingOptions: 'Caricando opzioni',
            companyRolePlaceholder: 'Cerca ruolo...',
            required: 'Obbligatorio',
            save: 'Salva',
            search: 'Cerca',
            reset: 'Cancella',
            name: 'Nome',
            edit: 'Modifica',
            send: 'Invia',
            createNew: 'Crea',
            saveChanges: 'Salva modifiche',
            firstName: 'Nome',
            lastName: 'Cognome',
            next: 'Prossimo',
            back: 'Indietro',
            skip: 'Salta',
            finish: 'Finito',
            optional: 'Opzionale',
            confirmPassword: 'Conferma password',
            newPassword: 'La tua password',
            passwordValidation: 'Minimo 8 caratteri e almeno una lettera. Niente caratteri speciali',
            passwordMustCoincide: 'Le password devono coincidere',
            passwordSpecialCharacters: 'Nessun carattere speciale',
            warning: 'Attenzione',
            close: 'Chiudi',
            emailNotValid: 'Email non valida',
            logoutConfirmation: 'Sei sicuro di voler effettuare il logout?',
            insertFirstName: 'Il tuo nome',
            insertLastName: 'Il tuo cognome',
            iAcceptThe: 'Ho letto e accetto la',
            privacyPolicy: 'Privacy Policy',
            confirmPasswordLabel: 'Conferma la tua password',
            confirmRegistration: 'Crea il tuo account',
            confirmPasswordTitle: 'Conferma password',
            userRole: 'Ruolo utente',
            deleteUser: 'Cancella utente',
            disableUserExplanation: 'Disabilita l\'utente per revocare tutti i suoi permessi all\'interno della piattaforma',
            enabledUserExplanation: 'L\'utente è stato disabilitato',
            userStatus: 'Stato dell\'utente',
            onboardingTitle: 'Benvenuto in Edulai!',
            onboardingDescription: 'Ti chiediamo un paio di minuti per permetterci di conoscerti meglio!',
            whatIsYourAge: 'Quanti anni hai?',
            startEdulai: 'Comincia a usare Edulai!',
            whatIsYourGender: 'Qual è il tuo genere?',
            male: 'Uomo',
            female: 'Donna',
            other: 'Altro',
            insertNation: 'Inserisci la tua nazione',
            whatIsYourNation: 'Qual è la tua nazione di residenza?',
            whatIsYourOccupancy: 'Attualmente sei',
            student: 'Studente',
            unemployed: 'Disoccupato',
            professionist: 'Un professionista occupato',
            whatIsYourJob: 'Se sei occupato, qual è il nome del tuo ruolo professionale principale?',
            insertJobTitle: 'Inserisci il nome del tuo ruolo',
            yearsOfExperince: 'Quanti anni di esperienza professionale hai in quel ruolo?',
            insertYearsOfExperince: 'Inserisci anni di esperienza',
            years: 'Anni',
            lessThanOneYear: 'Meno di un anno',
            moreThanTwentiYear: 'Più di 20 anni',
            whatIsYourScholarLevel: 'Qual è il tuo livello scolastico?',
            highSchool: 'Scuola superiore',
            universityDegree: 'Diploma/Laurea Universitaria',
            postUniversityDegree: 'Certificazione post-universitaria',
            stageOrInsterests: 'Hai mai svolto stage di qualunque tipo, studi o lavoro all\'estero, o hai degli interessi personali che ti hanno aiutato a sviluppare delle competenze per il mercato del lavoro? In caso affermativo seleziona le competenze che hai maturato e spiega qui sotto attraverso quale esperienze sono state acquisite. Se del caso potrai discutere queste competenze direttamente con i datori di lavoro',
            stageOrInsterestsSupport: 'Indica in questa casella se hai del materiale prodotto a supporto delle competenze maturate che hai elencato qui sopra, come parte del tuo portfolio professionale da mostrare ai datori di lavoro ed indica di che materiale si stratta (Es. registrazioni video di tue presentazioni, elaborati scritti o progetti da te realizzati, etc)',
            stageOrInsterestsBadges: 'Hai mai ottenuto badge digitali o certificazioni per una o più delle competenze qui sopra riportate? In caso affermativo indica per quale/quali competenza/competenze li hai maturati? Fornisci per favore qui sotto anche il/i link al/ai badge, se del caso',
            skillName: 'Nome skill',
            newSkillWarning: 'La nuova skill sarà disponibile solo per la creazione o la modifica di nuove domande. Le modifiche non coinvolgeranno Quiz già creati o in corso',
            skillCreatedSuccess: 'Skill creata con successo',
            skillCreatedError: 'Si è verificato un errore nella creazione della Skill. Per favore riprova',
            skillUpdatedSuccess: 'Skill aggiornata con successo',
            skillUpdatedError: 'Si è verificato un errore nell\'aggiornamento della Skill. Per favore riprova',
            skillDeleteWarning: 'Eliminando questa Skill, la renderai non disponibile per i nuovi Quiz. Quiz precedenti che includono questa Skill non saranno interessati da questo cambiamento e l\'abilità sarà comunque visibile nelle statistiche',
            skillDeleteError: 'Si è verificato un errore nella cancellazione della Skill. Per favore riprova',
            skillDeleteSuccess: 'Skill cancellata con successo',
            subSkills: 'Sub-Skills',
            newSubSkill: 'Nuova Sub-Skill',
            subSkillCreatedSuccess: 'Sub-Skill creata con successo',
            subSkillCreatedError: 'Si è verificato un errore nella creazione della Sub-Skill. Per favore riprova',
            subSkillUpdatedSuccess: 'Sub-Skill aggiornata con successo',
            subSkillUpdatedError: 'Si è verificato un errore nell\'aggiornamento della Sub-Skill. Per favore riprova',
            subSkillDeleteWarning: 'Eliminando questa Sub-Skill, la renderai non disponibile per i nuovi Quiz. Quiz precedenti che includono questa Sub-Skill non saranno interessati da questo cambiamento e l\'abilità sarà comunque visibile nelle statistiche',
            subSkillDeleteError: 'Si è verificato un errore nella cancellazione della Sub-Skill. Per favore riprova',
            subSkillDeleteSuccess: 'Sub-Skill cancellata con successo',
            deleteSubSkill: 'Cancella Sotto-Skill',
            deleteSkill: 'Cancella Skill',
            expirationDate: 'Data di scadenza',
            creationDate: 'Data di creazione',
            credits: 'Crediti residui',
            subscriptionType: 'Tipo di licenza',
            addCredits: 'Aggiungi crediti',
            setCredits: 'Imposta crediti',
            totalCredits: 'Crediti totali',
            addCreditsDescription: 'Modifica i crediti dell\'istituzione',
            addCreditSuccess: 'Crediti modificati con successo',
            addCreditError: 'Si è verificato un errore nella modifica dei crediti. Per favore riprova',
            creditsLabel: 'Crediti',
            modifyDomainType: 'Modifica tipo di licenza',
            modifyDomainTypeDescription: 'Puoi modificare il tipo di lincenza dominio in Licenza Flat (crediti illimitati) e Licenza PPU (crediti limitati).',
            modifyDomainTypeWarning: 'Sei sicuro di voler modificare la licenza?',
            modifyDomainTypeSuccess: 'Licenza modificata con successo',
            modifyDomainTypeError: 'Si è verificato un errore nella modifica della licenza. Per favore riprova',
            flatSubscriptionDescription: 'Flat (crediti illimitati)',
            ppuSubscriptionDescription: 'PPU (crediti limitati)',
            flatCreditsDescription: 'Nei domini di tipo FLAT i crediti residui non verranno scalati quando vengono inviate nuove rispose ai Quiz',
            domainStatus: 'Stato Dominio',
            disabled: 'Disabilitato',
            enabled: 'Attivo',
            disableDomain: 'Disabilita Dominio',
            enableDomain: 'Attiva Dominio',
            disableDomainRequest: 'Sei sicuro di voler disattivare il dominio? Non sarà più possibile creare nuovi utenti, nuovi Quiz e sottomettere risposte',
            modifyDomainStatusSuccess: 'Stato del dominio modificato con successo',
            modifyDomainStatusError: 'Si è verificato un errore nella modifica del dominio.',
            title: 'Titolo',
            text: 'Testo',
            evaluatedSkills: 'Skills valutate',
            startDate: 'Data di inizio',
            startTime: 'Ora di inizio',
            startDateTime: 'Date e ora di inizio',
            endDate: 'Data di fine',
            endTime: 'Ora di fine',
            endDateTime: 'Date e ora di fine',
            min1hr: 'Intervallo minimo di 1 ora',
            startBeforeEnd: 'Data di inizio successiva a data di fine',
            newEndBeforeOldEnd: 'Nuova data di fine predecente ad originale',
            quizInPast: 'Impossibile creare quiz nel passato',
            delete: 'Elimina',
            total: 'Totale',
            totals: 'Totali',
            creditsAfterOperations: 'Crediti totali dopo l\'operazione: {{credits}}',
            selectOperation: 'Seleziona operazione',
            modifyCredits: 'Modifica Crediti',
            userTagPlaceholder: 'Seleziona un gruppo per l\'utente',
            userTag: 'Gruppo utente',
            userTagDescription: 'Utilizza i gruppi per permettere di selezionare più utenti in maniera semplice',
            createNewOption: 'Crea nuovo',
            createNewTagDescription: 'Crea un nuovo gruppo per gli utenti',
            createNewTag: 'Crea nuovo gruppo',
            modifyTagDescription: 'Modifica il gruppo di utenti',
            deleteTagConfirm: 'Sei sicuro di voler eliminare questo gruppo? L\'operazione non può essere annullata',
            dateInterval: 'Intervallo di tempo',
            applyFilters: 'Applica filtri',
            resetFilters: 'Elimina filtri',
            stageOrInsterestsDescription: 'Abilità e esperienza in stage',
            edulaiTagDefaultDescription: 'Se non selezioni nessun gruppo, l\'utente apparterrà al gruppo di base Edulai',
            editSkillName: 'Modifica nome skill',
            cantDeleteReferencedSkill: 'Impossibile eliminare la skill selezionata. La skill è referenziata da una o più domande.',
            deleteQuestionWarning: 'Eliminando questa Domanda la renderai non disponibile per i nuovi Quiz. Quiz che includono una versione precedente di questa Domanda non saranno interessati da questo cambiamento.',
            deleteQuestionSuccess: 'Domanda eliminata',
            deleteQuestionError: 'Si è verificato un errore nella cancellazione della domanda',
            deleteQuestionInUseError: 'Errore: questa versione della domanda è in uso in un Quiz',
            maxCharacters: 'Massimo {{number}} caratteri',
          },
          domainTypes: {
            FLAT: 'Licenza Flat',
            PPU: 'Licenza a consumo crediti',
          },
          users: {
            inactiveUser: 'Utenti inattivi',
            tagUsers: 'Utenti',
            deleteTagSuccess: 'Gruppo eliminato',
            deleteTagError: 'Si è verificato un errore nel cancellare il gruppo',
            createUserTagSuccess: 'Gruppo creato con successo',
            createUserTagError: 'Si è verificato un errore nella creazione del gruppo',
            editUserTagSuccess: 'Gruppo modificato',
            editUserTagError: 'Si è verificato un errore nella modifica',
            userData: 'Dati utente',
            userProfile: 'Profilo utente',
            platformUsers: 'Utenti piattaforma',
            createNewUser: 'Nuovo utente',
            userEmail: 'Email dell\'utente',
            noUsersFound: 'Nessun utente trovato',
            disabled: 'Disabilitato',
            disableUser: 'Disabilita utente',
            enabled: 'Attivo',
            userModifySuccess: 'Profilo aggiornato con successo',
            userCreationError: 'Si è verificato un errore nella creazione dell\'utente. Per favore riprova',
            userDeleteError: 'Si è verificato un errore nella cancellazione dell\'utente. Per favore riprova',
            userModifyError: 'Si è verificato un errore nella modifica dell\'utente. Per favore riprova',
            modifyUserSuccess: 'Utente modificato con successo',
            createUserSuccess: 'Utente creato con successo, L\'utente risulterà INATTIVO fin quando non attiverà il suo account creando la sua password personale',
            deleteUserConfirm: 'Sei sicuro di voler eliminare l\'utente selezionato? L\'operazione non può essere annullata',
            userDeleteSuccess: 'Utente eliminato con successo',
            platformUser: 'Utente piattaforma',
            disableUserSuccess: 'Utente disabilitato con successo',
            enableUserSuccess: 'Utente abilitato con successo',
            disableUserError: 'Si è verificato un errore. Per favore riprova',
            resendConfirmationEmail: 'Reinviga email di conferma',
            resendActivationEmail: 'Invia email di attivazione',
            activeUsers: 'Utenti attivi',
            disabledUsers: 'Utenti disabilitati',
            onboardingFailed: 'Si è verificato un errore nell\'invio dei dati. Per favore riprova',
            usersTags: 'Gruppi di utenti',
            noUsersTagFound: 'Nessun gruppo trovato',
            inactive: 'Inattivo',
          },
          questions: {
            newQuestion: 'Nuova domanda',
            editQuestion: 'Modifica domanda',
            findInTitle: 'Cerca nel titolo',
            noQuestionsFound: 'Nessuna domanda trovata',
            createQuestionSuccess: 'Domanda creata con successo',
            createQuestionError: 'Si è verificato un errore durante la creazione della domanda. Per favore riprova.',
            modifyQuestionSuccess: 'Domanda modificata con successo',
            modifyQuestionError: 'Si è verificato un errore durante la modifica della domanda. Per favore riprova.',
            answers: 'Risposte',
            newAnswer: 'Nuova risposta',
            editAnswer: 'Modifica riposta',
            deleteOptionConfirm: 'Sei sicuro di voler eliminare l\'opzione selezionata? L\'operazione non può essere annullata',
            optionDeleteSuccess: 'Opzione eliminata con successo',
            optionsNumberError: 'Per favore, inserire almeno 3 risposte valide.',
            firstOptionCreate: 'Dopo aver creato la prima risposta non sarà più possibile modificare skills e sottoskills senza resettare tutte le risposte già create. Sei sicuro di voler procedere?',
            myAnswers: 'Le mie risposte',
            version: 'Versione',
          },
          roles: {
            ROOT: 'Root',
            ADMIN: 'Admin',
            SUPERVISOR: 'Supervisore',
            USER: 'Utente base',
            PRINCIPAL: 'Principal',
          },
          rolesDescriptions: {
            ROOT: 'Questo ruolo è dedicato ai gestori della piattaforma cloud di Edulai',
            ADMIN: 'L\'utente Admin è l\'utenza di più alto livello gerarchico dell\'istituzione ed è responsabile della gestione degli utenti della piattaforma,',
            PRINCIPAL: 'Il Principal è l\'amministratore dell\'istituzione il cui scopo è visualizzare l\'andamento dello svolgimento dei quiz e l\'avanzamento delle skills di tutta l\'istituzione',
            SUPERVISOR: 'L\'utente Supervisor è l\'utente di backoffice dell\'istituzione il cui compito è creare e somministrare quiz',
            USER: 'L\'utente base della piattaforma ha la possibilità di compilare quiz, visualizzare i propri risultati per un singolo quiz e vedere l\'andamento delle sue Skills e Sotto-Skills dal suo profilo',
          },
          domain: {
            domain: 'Dominio',
            customFields: 'Campi anagrafici personalizzati',
            createCustomField: 'Crea campo anagrafico',
            customFieldDescription: 'Questo campo anagrafico verrà mostrato agli utenti come domanda in fase di onboarding',
            customFieldTitle: 'Nome del campo anagrafico',
            customFieldBody: 'Testo della domanda',
            customFieldSuccess: 'Campi anagrafici modificati con successo',
            customFieldError: 'Si è verificato un errore nella modifica dei campi anagrafici',
            deleteCustomFieldRequest: 'Sei sicuro di voler eliminare questo campo? L\'operazione non può essere annullata',
            customFieldDeleteSuccess: 'Campo eliminato con successo',
            customFieldDeleteError: 'Si è verificato un errore nell\'eliminazione',
          },
          quizzes: {
            types: {
              DRAFT: 'BOZZA',
              RUNNING: 'ATTIVO',
              PAUSED: 'IN PAUSA',
              DONE: 'FINITO',
              READY: 'PRONTO',
              ALL: 'TUTTI',
              EXPIRED_FOR_USER: 'SCADUTO',
            },
            typesDescriptions: {
              DRAFT: 'Le bozze dei quiz possono essere modificati. Clicca per attivarlo!',
              RUNNING: 'Il quiz è attivo e gli utenti possono rispondere alle domande',
              PAUSED: 'Il quiz è in pausa e gli utenti non possono rispondere alle domande',
              DONE: 'Il quiz è terminato!',
              READY: 'Il Quiz inizierà quando passerà la sua ora di inizio o se avviato prima del tempo',
              EXPIRED_FOR_USER: 'Il quiz è terminato e non hai inviato le tue risposte',
            },
            selectSupervisor: 'Seleziona un Supervisore',
            selectSupervisorDescription: 'Se non selezioni un supervisore solo tu sarai in grado di gestire il Quiz!',
            noCreditsError: 'Crediti del dominio terminati! Aggiungi nuovi crediti per avviare i quiz!',
            activeQuizzes: 'Quiz attivi',
            disabledQuizzes: 'Quiz inattivi',
            newQuiz: 'Nuovo quiz',
            noQuizzesFound: 'Nessun quiz trovato',
            editQuiz: 'Modifica quiz',
            createQuizSuccess: 'Quiz creato con successo. Il quiz rimarrà in stato di "Bozza" fino a quando non verrà avviato!',
            createQuizError: 'Si è verificato un errore durante la creazione del quiz. Per favore riprova.',
            participants: 'Partecipanti',
            users: 'Utenti',
            questions: 'Domande',
            selectedQuestionsNumber: 'Numero di domande selezionate',
            validity: 'Validità',
            completed: 'Completati',
            progress: 'Progresso',
            submit: 'Invia',
            submitDraft: 'Quiz pronto!',
            submitQuizError: 'Si è verificato un errore durante l\'invio del quiz. Per favore riprova.',
            quizSubmitSuccess: 'Quiz inviato con successo. Il quiz rimarrà in stato "Pronto" fino alla sua data di inizio',
            pause: 'Pausa',
            resume: 'Riattiva',
            pauseQuizError: 'Si è verificato un errore durante la sospensione del quiz. Per favore riprova.',
            quizPauseSuccess: 'Quiz sospeso con successo',
            resumeQuizError: 'Si è verificato un errore durante la riattivazione del quiz. Per favore riprova.',
            quizResumeSuccess: 'Quiz riattivato con successo',
            changeStartDate: 'Modifica data di inizio',
            forceStart: 'Inizia ora',
            newStartDateTime: 'Nuova data e ora di inizio',
            changeStartDateError: 'Si è verificato un errore durante la modifica della data e ora di inizio del quiz. Per favore riprova.',
            changeStartDateSuccess: 'Data e ora di inizio del quiz modificate con successo',
            confirmForceStart: 'Sei sicuro di voler iniziare il quiz immediatamente?',
            forceStartError: 'Si è verificato un errore durante l\'inizio del quiz. Per favore riprova.',
            forceStartSuccess: 'Quiz iniziato con successo',
            addNewParticipants: 'Aggiungi nuovi partecipanti',
            newParticipants: 'Nuovi partecipanti',
            addParticipantsSuccess: 'Nuovi partecipanti aggiunti con successo',
            addParticipantsError: 'Si è verificato un errore durante l\'aggiunta di nuovi partecipanti. Per favore riprova.',
            terminate: 'Termina',
            confirmTerminateQuiz: 'Sei sicuro di voler terminare il quiz immediatamente? L\'azione non sarà reversibile.',
            terminateQuizSuccess: 'Quiz terminato con successo',
            terminateQuizError: 'Si è verificato un errore durante la terminazione del quiz. Per favore riprova.',
            editQuizSuccess: 'Quiz modificato con successo. Il quiz rimarrà in stato di "Bozza" fino a quando non verrà avviato!',
            editQuizError: 'Si è verificato un errore durante la modifica del quiz. Per favore riprova.',
            reactivate: 'Riattiva',
            confirmReactivateQuiz: 'Sei sicuro di voler riattivare il quiz?',
            reactivateQuizSuccess: 'Quiz riattivato con successo',
            reactivateQuizError: 'Si è verificato un errore durante la riattivazione del quiz. Per favore riprova.',
            createdBy: 'Responsabile',
            extendQuizSuccess: 'Quiz esteso con successo',
            extendQuizError: 'Si è verificato un errore durante l\'estensione della data e ora di inizio del quiz. Per favore riprova.',
            newEndDateTime: 'Nuova data e ora di fine',
            extendExpiration: 'Estendi scadenza',
            confirmDeleteQuiz: 'Sei sicuro di voler eliminare il quiz?',
            deleteQuizSuccess: 'Quiz eliminato con successo',
            deleteQuizError: 'Si è verificato un errore durante l\'eliminazione del quiz. Per favore riprova.',
            showQuestionsDetails: 'Mostra dettagli domande',
            givenAnswers: 'Risposte completate',
            totalQuestions: 'Domande totali',
            quizStatistics: 'Statistiche',
            userAnswers: 'Mostra risposte',
            sendReminder: 'Invia promemoria',
            sendReminderSuccess: 'Promemoria inviato',
            sendReminderError: 'Invio promemoria fallito',
            hideScoresToUsers: 'Nascondi punteggi ai partecipanti',
            hideScores: 'Nascondi punteggi',
            hideScoresDescription: 'Se nascondi i punteggi gli utenti potranno rivedere le proprie risposte al quiz ma non i punteggi ottenuti',
            hideScoreAnalyticsDescription: 'I punteggi del quiz sono nascosti dal supervisore',
            updateHideScoreSuccess: 'Nascondi punteggi modificato con successo',
            updateHideScoreError: 'Si è verificato un errore durante la modifica di nascondi punteggi. Per favore riprova.',
          },
          analytics: {
            filters: 'Filtri',
            subskillsScores: 'Punteggi subskills',
            score: 'Punteggo',
            userScore: 'Punteggio utente',
            skillsScores: 'Punteggi skills',
            analytics: 'Statistiche',
            submissionsTrend: 'Andamento delle submissions',
            usersSubmissions: 'Risposte dei partecipanti',
            noSubmissionStarted: 'L\'utente non ha risposto al quiz',
            submissionStillDraft: 'L\'utente ha iniziato il quiz ma non ha finalizzato le risposte',
            currentScores: 'Punteggi attuali',
            selfAssessmentScores: 'Autovalutazione iniziale',
            userQuizSkillsScore: 'Punteggi utente per il quiz',
            usersQuizSkillsScore: 'Punteggi utenti',
            skillsTrendChart: 'Grafico andamento delle skills',
            leastPerformingUser: 'Utente peggiore',
            bestPerformingUser: 'Utente migliore',
            skills: 'Skills',
            all: 'Qualsiasi',
            everyone: 'Tutti i partecipanti',
            errorCreateCSV: 'Si è verificato un errore nella creazione del CSV',
            chartForUser: 'Statistiche dell\'utente',
            chartForUserOverTime: 'Statistiche dell\'utente {{user}} nel tempo',
            chartForUserMean: 'Media aggregata dell\'utente {{user}}',
            chartMeanOverall: 'Media aggregata delle skills di sistema',
            chartMeanOverTime: 'Media aggregata delle skills di sistema nell\'intervallo di tempo selezionato',
            overallStatisticsForUser: 'Punteggi totali dell\'utente {{user}}',
            overallAnalytics: 'Metriche di sistema',
            overallAnalyticsOverTime: 'Andamento skills di sistema',
            overallAnalyticsOverTimeDescription: 'Questo grafico mostra l\'andamento delle skills di sistema nell\'intervallo di tempo selezionato',
            currentScoresOfSystem: 'Punteggi attuali di tutti gli utenti',
            summaryDescription: 'Punteggi aggregati di tutti gli utenti della piattaforma per singola skill',
            userSummaryScores: 'Punteggi aggregati per ogni utente',
            userSummaryScoresDescription: 'Questo grafico mostra per ogni utente la media dei suoi punteggi fino ad ora ottenuti',
            detailedAnalytics: 'Analitiche nel dettaglio',
            usersSkillsScores: 'Punteggi utenti per tutte le skills',
            selectSkillForChart: 'Seleziona la skill per vedere il punteggio degli utenti',
            overallStatisticsForUserDescription: 'Seleziona un utente dai filtri per visualizzarne i dettagli',
            userSkillsScore: 'Punteggio utente',
            usersSkillScores: 'Punteggi utenti per {{skill}}',
            userSummaryBubbleScoresDescription: 'Questo grafico mostra per ogni classe di punteggio la percentuale di utenti con quel punteggio',
            percentage: 'Percentuale',
            userSummaryBubbleScores: 'Percentuali di utenti per punteggio',
            usersPercentage: 'Percentuale di utenti',
            downloadCsvSuccess: 'Il tuo report è stato inviato. Controlla la tua mail tra qualche minuto',
          },
          submissions: {
            answer: 'Risposta',
            submissionLoadFailed: 'Si è verificato un errore nel caricamento del quiz',
            pleaseReload: 'Per favore, ricarica il quiz.',
            submissionsFor: 'Le risposte al quiz {{quizName}}',
            closedAt: 'sono state chiuse il',
            arePaused: 'sono sospese.',
            waitForSupervisor: 'Attendi ulteriori istruzioni dal supervisor del quiz',
            willStart: 'inizieranno',
            soon: 'presto',
            areOpen: 'sono aperte',
            startNewSubmission: 'inizia il quiz',
            noAnswerSelectedError: 'Per favore, seleziona una delle opzioni.',
            submitAnswer: 'Invia risposta',
            concludeQuiz: 'Concludi quiz',
            answerSubmitSuccess: 'Risposta inviata con successo',
            answerSubmitError: 'Si è verificato un errore durante l\'invio della risposta. Per favore riprova.',
            quizSubmitted: 'Quiz inviato',
            submissionSubmitSuccess: 'Quiz concluso!',
            submissionSubmitError: 'Si è verificato un errore durante la chiusura del quiz. Per favore riprova.',
            submissionGuidelines1: 'Lo scopo di Edulai Skills Checker è quello di valutare il livello di alcune tue Soft Skills chiave, importanti per i datori di lavoro oggi. La valutazione di Edulai Skills Checker può essere eseguita una sola volta, o più di una volta, nell’arco di un certo periodo di tempo, in modo che lo sviluppo di una o più competenze possa essere monitorato.',
            submissionGuidelines2: 'Edulai Skills Checker presenta diversi casi professionali concreti da leggere con attenzione. Ogni caso descrive una situazione specifica ed è necessario scegliere, tra le 5 risposte/soluzioni fornite, quella che ritieni più opportuna per quel caso. Non passare troppo tempo a pensare alla risposta che sceglierai, non c\'è né una giusta o una sbagliata. La compilazione dello Skills Checker richiede tra i 15 e i 20 minuti di tempo.',
            submissionGuidelines3: 'Le risposte devono essere selezionate una dopo l\'altra, quindi non puoi saltarle o non puoi muoverti avanti e indietro tra le domande. Lo Skills Checker deve essere completato tutto in una volta, ma nel caso in cui problemi tecnici ti impediscano di completarlo, il sistema ti darà la possibilità di accedere nuovamente e d\'inviare successivamente le risposte mancanti',
            submissionGuidelines4: 'Una volta inviate tutte le risposte, il sistema ti mostrerà automaticamente i risultati e sarai in grado di accedere sia ad un report più generale sia ad uno più specifico sullo sviluppo dei tuoi skills. Saranno inoltre fornite linee guida su come leggere i risultati.',
            sybmissionGuidelinesSmartWorking: 'Con il termine “Smart Working” intendiamo lo svolgimento del proprio lavoro ovunque indipendentemente da dove ci si trovi e dalla localizzazione geografica, anche se esso può avvenire prevalentemente da casa. Questa definizione comprende anche la terminologia “lavoro da remoto” attraverso l’utilizzo delle tecnologie della comunicazione come ad esempio, PC, internet, email, sistemi di videoconferenza. per la realizzazione dei quotidiani compiti lavorativi e per lavorare con colleghi in team virtuali/online. (Smarter Working Guide, 2007)',
            submissionCompleted: 'Hai risposto a tutte le domande! Invia le tue risposte per terminare il quiz',
            noSubmissionSendQuizDone: 'Non hai inviato le tue risposte a questo quiz!',
            submissionsRefusedNoCredits: 'La tua istituzione non può accettare nuove rispose. Per favore contatta il tuo responsabile',
          },
        },
      },
    },
  });

export default i18next;
