//
// ──────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: A L L   Q U I Z   S T A T U S E S   V I E W : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────
//


import _ from 'lodash';
import React from 'react';
import QuizDoneNoAnswerView from './QuizStatusViews/QuizDoneNoAnswerView';
import QuizPausedView from './QuizStatusViews/QuizPausedView';
import QuizReadyView from './QuizStatusViews/QuizReadyView';


const statusViewComponents = {
  DONE: QuizDoneNoAnswerView,
  PAUSED: QuizPausedView,
  READY: QuizReadyView,
};
/*
* This component will render the right shooting status view
* depending on the quiz.quizStatusType variable.
*/
const BaseQuizStatusView = (props) => {
  const { quiz } = props;
  if (!quiz || _.isEmpty(quiz)) {
    return null;
  }
  const Component = statusViewComponents[quiz.quizStatusType];
  if (!Component) return null;
  return <Component key={quiz.id} quiz={quiz} {...props} />;
};

export default BaseQuizStatusView;
