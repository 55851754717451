import { withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import translations from '../../../translations/i18next';
import { EDULAI_PURPLE } from '../../../styles/styleConsts';
import OptionItem from './OptionItem';

const styles = () => ({
  outerContainer: {
    height: '100%',
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`,
  },
  eventContainer: {
    padding: 15,
    width: '100%',
  },
  title: {
    fontSize: 20,
    margin: 5,
    marginLeft: 0,
  },
  photoTypeTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 15,
  },
  questionBody: {
    fontWeight: 'normal',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const OptionDetails = (props) => {
  const {
    classes,
    question,
  } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ minWidth: 500, maxWidth: 800 }}>
        <div>
          <h2 style={{ marginBottom: 2 }}>{question.name}</h2>
          <h3 className={classes.questionBody} style={{ marginTop: 0 }}>{`${translations.t('questions.version')} ${question.version}`}</h3>
          <h3 className={classes.questionBody}>{question.body}</h3>
          <h3 className={classes.title} style={{ marginBottom: 10 }}>Measured skills</h3>
          {_.map(question.measuredSkills, (skill) => (
            <Chip
              key={`${skill.id}_${question.id}`}
              id={skill.id}
              className={classes.photoTypeTag}
              color="primary"
              label={skill.name}
            />
          ))}
        </div>
        <h3 className={classes.title} style={{ marginBottom: 15 }}>Options</h3>
        {
          _.map(_.sortBy(question.options.optionDTOS, ['optionNumber']), (option) => (
            <OptionItem key={option.id} option={option} />
          ))
        }
      </div>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  skills: state.skills,
});


export default _.flow([
  connect(mapStateToProps),
  withStyles(styles),
])(OptionDetails);
