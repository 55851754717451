import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import translations from '../../translations/i18next';
import { EDULAI_PURPLE } from '../../styles/styleConsts';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`,
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  typography: {
    useNextVariants: true,
  },
  titleContainer: {
    alignItems: 'center',
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f',
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5,
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  body: {
    marginBottom: 0,
    fontWeight: '100'
  },
  photoTypeTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
  },
});

const QuizQuestionRow = ({
  question,
  classes,
  containerstyle,
}) => (
  <MuiThemeProvider theme={theme}>
    {question && (
      <Card
      style={{ ...containerstyle }}
      className={classes.outerContainer}
    >
      <CardActionArea disabled className={classes.eventContainer}>
        <div>
          <h3 className={classes.title}>
            {`${question.name}`}
          </h3>
          <h3 className={classes.body} style={{ marginTop: 0 }}>{`${translations.t('questions.version')} ${question.version}`}</h3>
          {_.map(question.measuredSkills, (skill) => (
            <Chip
              key={`${skill.id}_${question.id}`}
              className={classes.photoTypeTag}
              color="primary"
              label={skill.name}
            />
          ))}
        </div>
      </CardActionArea>
    </Card>
    )}
    
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(QuizQuestionRow));
