import { Card, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BackArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit, initialize } from 'redux-form';
import loginBackground from '../assets/loginBackground.jpg';
import EdulaiLogo from '../assets/logo.png';
import OnboardingForm from '../components/Forms/UsersForm/OnboardingForm';
import MDButton from '../components/MDButton/MDButton';
import * as PlatformUsersActions from '../redux/actions/platformUsers.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import * as ModalsActions from '../redux/actions/modal.actions';
import * as UserActions from '../redux/actions/user.actions';
import { EDULAI_BLUE } from '../styles/styleConsts';
import translations from '../translations/i18next';
import { isMobileBrowser } from '../utils/utilsFunctions';

const styles = (theme) => ({
  container: {
    padding: isMobileBrowser() ? 0 : 20,
    height: isMobileBrowser() ? '170vh' : '110vh',
    overflowY: 'scroll',
    paddingBottom: 200,
  },
  title: {
    margin: 10,
    color: '#5a5c69',
    fontWeight: '200',
    textAlign: 'center',
  },
  chartsContainer: {
    marginTop: 10,
  },
  cardInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingTop: isMobileBrowser() ? 50 : 0,
  },
  backToLoginButton: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
});

class UserOnboardingView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      instructionsLink: '',
    }
  }

  componentDidMount() {
    const { dispatch, skills: { content: skillsData } } = this.props;
    dispatch(initialize('OnboardingForm', { skillsData }));
    const instructionsLink = dispatch(UtilsActions.getInstructionLink());
    this.setState({ instructionsLink });
  }

  async onOnboardingFinish(onBoardingData) {
    const { history, user, dispatch } = this.props;
    try {
      const userId = user && user.data && user.data.id;
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUsersActions.confirmUserOnboarding(userId, true, onBoardingData));
      try {
        await dispatch(PlatformUsersActions.updateUserCustomFieldsFromOnboarding(userId, onBoardingData));
      } catch (error) {}
      await dispatch(UserActions.fetchUser());
      dispatch(UtilsActions.setSpinnerVisible(false));
      history.push('/quizzes');
    } catch (error) {
      // Fallback on quizz anyway. The onboarding will be proposed on the next login.
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('ONBOARDING_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('users.onboardingFailed'),
        },
      }));
    }
  }

  onBackToLogin() {
    const { dispatch, history } = this.props;
    dispatch(UserActions.userLogout());
    history.push('/login');
  }

  render() {
    const { classes, domain: { data: domainData }, form, language, dispatch, skills: { content: skillsData } } = this.props;
    const { instructionsLink } = this.state;
    const isMobile = isMobileBrowser();
    return (
      <div className={classes.container} style={{ backgroundImage: `url(${loginBackground})`, backgroundSize: 'cover', backgroundPosition: 'center top' }}>
        <Card style={{ marginBottom: 50, display: 'flex', alignItems: 'center' }}>
          <div className={classes.cardInnerContainer}>
            <IconButton className={classes.backToLoginButton} onClick={() => this.onBackToLogin()}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BackArrowIcon />
                <h4 style={{ margin: 0 }}>{translations.t('forms.backtoLogin')}</h4>
              </div>
            </IconButton>
            <img src={EdulaiLogo} alt="edulai" className="logo" style={{ margin: 10, marginTop: 20, alignSelf: 'center', height: 65 }} />
            <h1 className={classes.title}>{translations.t('forms.onboardingTitle')}</h1>
            <h2 className={classes.title}>{translations.t('forms.onboardingDescription')}</h2>
            <OnboardingForm
              customFields={domainData.customFields}
              currentForm={form}
              skills={skillsData}
              language={language}
              instructionsLink={instructionsLink}
              onSubmit={(onBoardingData) => this.onOnboardingFinish(onBoardingData)}
            />
          </div>
        </Card>
        <MDButton
          className="gradient-button"
          title={translations.t('forms.startEdulai')}
          backgroundColor={EDULAI_BLUE}
          titleStyle={{ fontSize: 18 }}
          containerstyle={{ width: isMobile ? 'unset' : '100%', margin: 'auto', marginTop: 100, marginBottom: 100, marginLeft: isMobile ? 20: 0, marginRight: isMobile ? 20 : 0 }}
          onClick={() => dispatch(submit('OnboardingForm'))}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  skills: state.skills.data,
  form: state.form.OnboardingForm,
  user: state.user,
  domain: state.domain,
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(UserOnboardingView)));