import React from 'react';
import { Radar } from 'react-chartjs-2';

const RadarChart = (props) => {
  const {
    data,
    min,
    max,
    stepSize,
  } = props;

  const options = {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontSize: 15,
      },
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
      },
    },
    scale: {
      ticks: {
        max,
        min,
        stepSize,
      },
      pointLabels: {
        fontSize: 15,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Radar
      data={data}
      options={options}
      width={400}
      height={400}
    />
  );
};

export default RadarChart;
