import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import EdulaiLogo from '../../assets/logo.png';
import { EDULAI_BLUE } from '../../styles/styleConsts';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 40,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: EDULAI_BLUE,
  },
};

const Spinner = ({
  title,
  classes,
  spinnerStyle,
  titleStyle,
  hideLogo,
  customStyle,
}) => (
  <div className={classes.container} style={{ ...customStyle }}>
    {!hideLogo && <img src={EdulaiLogo} alt="edulai" className={classes.logo} />}
    <CircularProgress className={classes.circularProgress} style={{ ...spinnerStyle }} />
    {title && <h4 className={classes.title} style={{ ...titleStyle }}>{title}</h4>}
  </div>
);

export default withStyles(styles)(Spinner);
