import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import * as ModalsActions from '../../redux/actions/modal.actions';
import MDDialog from './MDDialog';
import MDDialogDelete from './MDDialogDelete';
import MDErrorAlert from './MDErrorAlert';
import MDInfoAlert from './MDInfoAlert';
import MDOperationalView from './MDOperationalView';
import MDSuccessAlert from './MDSuccessAlert';
import MDWarningAlert from './MDWarningAlert';

const modalComponents = {
  SUCCESS_ALERT: MDSuccessAlert,
  ERROR_ALERT: MDErrorAlert,
  INFO_ALERT: MDInfoAlert,
  WARNING_ALERT: MDWarningAlert,
  MODAL_DIALOG: MDDialog,
  MODAL_DIALOG_DELETE: MDDialogDelete,
  OPERATIONAL_VIEW: MDOperationalView,
};

const BaseAlert = ({ modals, dispatch }) => {
  if (!_.size(modals)) {
    return null;
  }
  const components = _.map(modals, (modal) => {
    if (!modal.id || !modal.data) return null;

    const { modalType, modalProps } = modal.data;
    const Component = modalComponents[modalType];

    return (
      <Component
        key={modal.id}
        {...modalProps}
        dispatch={dispatch}
        hideModal={() => dispatch(ModalsActions.hideModal(modal.id))}
      />
    );
  });
  return (_.size(components) ? components : null);
};

export default connect((state) => ({ modals: state.modals.data }))(BaseAlert);
