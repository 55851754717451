import React, { Component } from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserProfileCard from '../../components/UserProfileCard/UserProfileCard';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import translations from '../../translations/i18next';
import { LANGUAGE_BACKEND_MAP } from '../../config/configurations';


const styles = (theme) => ({
  container: {
    padding: 20,
  },
  title: {
    margin: 10,
    color: '#5a5c69',
  },
  chartsContainer: {
    marginTop: 10,
  },
});
class SettingsView extends Component {
  componentDidMount() {
    this.containerDiv.scrollIntoView({ behavior: 'smooth' });
  }

  onLogoutRequest() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('LOGOUT_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('forms.warning'),
        bodyTextStyle: { fontSize: 18 },
        bodyText: translations.t('forms.logoutConfirmation'),
        onConfirm: () => this.onLogout(),
      },
    }));
  }

  onLogout() {
    const { dispatch, history } = this.props;
    dispatch(ModalsActions.hideModal('LOGOUT_MODAL'));
    dispatch(UserActions.userLogout());
    history.push('/login');
  }

  onChangeLanguage(language) {
    const { dispatch } = this.props;
    i18next.changeLanguage(language);
    dispatch(UtilsActions.setLanguage(language));
    dispatch(UserActions.updateUserLanguage(LANGUAGE_BACKEND_MAP[language]));
  }

  render() {
    const { user: { data: userData }, classes } = this.props;
    return (
      <div className={classes.container} ref={(c) => { this.containerDiv = c; }}>
        {userData && !_.isEmpty(userData) ? <UserProfileCard userData={userData} onChangeLanguage={(language) => this.onChangeLanguage(language)} onLogout={() => this.onLogoutRequest()} /> : null}
        {process.env.REACT_APP_VERSION && (
          <h4 style={{ textAlign: 'center', marginRight: 15, fontSize: 15, fontWeight: '100', color: '#3f3f3f' }}>
            {`Edulai Skills Checker v${process.env.REACT_APP_VERSION}`}
          </h4>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(SettingsView)));
