import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { EDULAI_PURPLE } from '../../../styles/styleConsts';
import MDRadioButtonsGroupField from '../FormsComponents/MDRadioButtonsGroupField/MDRadioButtonsGroupField';

const validate = (values) => {
  const errors = {};
  return errors;
};

const styles = () => ({
  formContainer: {
    margin: 20,
  },
  headerTitle: {
    marginLeft: 20,
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f',
  },
  text: {
    fontWeight: 'normal',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

/* eslint-disable-next-line */
class SubmissionAnswerForm extends Component {
  render() {
    const { options } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Field
          name="answer"
          component={MDRadioButtonsGroupField}
          containerstyle={{ marginBottom: 30 }}
          showErrorLabel
          required
          hideStar
          options={options}
          radioLabelStyle={{ fontSize: 15, fontWeight: 'normal' }}
        />
      </MuiThemeProvider>
    );
  }
}

export default _.flow([
  reduxForm({
    form: 'SubmissionAnswerForm',
    validate,
    destroyOnUnmount: true,
  }),
  withStyles(styles),
])(SubmissionAnswerForm);
