import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bar } from 'react-chartjs-2';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const ScrollableBarChart = (props) => {
  const {
    data,
    dataSetSize,
    xAxeTitle,
    yAxeTitle,
  } = props;
  let maxWidth = window.screen.width - 280 - 80; // 280 sidebar menu, 80 margin and padding
  maxWidth = isMobileBrowser() ? window.screen.width + 100 : maxWidth;
  const chartWidth = dataSetSize * 20 < maxWidth ? maxWidth : dataSetSize * 20;

  return (
    <div className="chartWrapper">
      <PerfectScrollbar style={{ paddingBottom: 15 }}>
        <div className="chartAreaWrapper" style={{ width: chartWidth }}>
          <Bar
            data={data}
            height={400}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'bottom',
              },
              scales: {
                xAxes: [{
                  categorySpacing: 2,
                  scaleLabel: {
                    display: xAxeTitle,
                    labelString: xAxeTitle,
                  },
                }],
                yAxes: [{
                  ticks: {
                    max: 5,
                    min: 0,
                    stepSize: 1,
                  },
                  scaleLabel: {
                    display: yAxeTitle,
                    labelString: yAxeTitle,
                  },
                }],
              },
            }}
          />
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default ScrollableBarChart;
