import React from 'react';
import _ from 'lodash';
import { NavLink, Route, withRouter } from 'react-router-dom';
import { ListItemIcon } from '@material-ui/core';

class HeaderButton extends React.Component {
  checkActivation = (match) => {
    if (match) {
      const { paths } = this.props;
      return _.indexOf(paths, match.url) !== -1;
    }
    return false;
  }

  render() {
    const { title, titleStyleSelected, titleNormalStyle, paths, iconSelected, icon } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Route
          to={_.first(paths)}
          // eslint-disable-next-line react/no-children-prop
          children={({ match }) => (
            <div style={{ display: 'flex', width: '100%', backgroundColor: this.checkActivation(match) ? null : null }}>
              <NavLink
                exact
                to={_.first(paths)}
                isActive={() => this.checkActivation(match)}
                style={{ display: 'flex', width: '100%', textDecoration: 'none', color: '#80888f' }}
                activeStyle={{
                  textDecoration: 'none',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
                  <ListItemIcon >{this.checkActivation(match) ? iconSelected : icon }</ListItemIcon>
                  <h3 style={ this.checkActivation(match) ? titleStyleSelected : { fontFamily: 'Oxygen', fontWeight: 200, color: 'rgba(255,255,255,.8)', ...titleNormalStyle } }>
                    {title}
                  </h3>
                </div>
              </NavLink>
            </div>
          )}
        />
      </div>
    );
  }
}

export default withRouter(HeaderButton);
