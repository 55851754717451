import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import { Divider, IconButton } from '@material-ui/core';
import * as AnalyticsActions from '../../redux/actions/analytics.actions';
import * as SkillsActions from '../../redux/actions/skills.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import translations from '../../translations/i18next';
import RadarChart from '../Charts/RadarChart';
import MDAccordion from '../MDAccordion/MDAccordion';
import Spinner from '../Spinner/Spinner';
import StarRateView from '../StarRateView/StarRateView';


class BaseUserAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      instructionsLink: '',
    };
  }

  async componentDidMount() {
    const {
      dispatch,
      user
    } = this.props;
    const instructionsLink = dispatch(UtilsActions.getInstructionLink());
    try {
      const skills = await dispatch(SkillsActions.fetchSkillsAndSubSkills());
      await dispatch(AnalyticsActions.fetchUserSkillsAnalytics(null, user && user.id));

      const subSkillsAnalyticsPromises = _.map(
        skills,
        async (skill) => dispatch(AnalyticsActions.fetchUserSubSkillsAnalytics(skill.id)),
      );
      await Promise.all(subSkillsAnalyticsPromises);
      this.setState({ instructionsLink, isLoading: false });
    } catch (error) {
      this.setState({ instructionsLink, isLoading: false });
    }
  }
  

  render() {
    const {
      skills: { data: { content: skillsData }, subSkills },
      userData,
      analytics: { user: userAnalytics },
    } = this.props;
    const { isLoading, instructionsLink } = this.state;

    const subSkillsFlat = _(subSkills)
      .flatten()
      .groupBy('parentId')
      .value();

    const skillsAnalytics = _.map(skillsData, (skill) => {
      const current = _.find(userAnalytics.skills, { skillId: skill.id });
      return {
        name: skill.name,
        id: skill.id,
        value: current ? current.score : 0,
      };
    });
    const userSelfAssessmentData = userData && userData.selfAssessmentDTO && userData.selfAssessmentDTO.skillSelfAssessmentDTOSet ? userData.selfAssessmentDTO.skillSelfAssessmentDTOSet : [];

    const chartDatasets = {
      datasets: [
        {
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(72, 61, 139, 1)',
          pointBackgroundColor: 'rgba(72, 61, 139, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.currentScores'),
          data: _.map(_.orderBy(skillsAnalytics, skill => skill.id), (item) => item.value),
        },
        {
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(240, 52, 52, 1)',
          pointBackgroundColor: 'rgba(240, 52, 52, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.selfAssessmentScores'),
          data: _.map(_.orderBy(userSelfAssessmentData, skill => skill.skillId), (item) => item.score),
        },
      ],
      labels: _.map(_.orderBy(skillsAnalytics, skill => skill.id), (item) => item.name),
    };
    return (
      <div>
        {isLoading && (
          <Spinner
            title={translations.t('general.loading')}
            hideLogo
            spinnerStyle={{ color: '#5AC0B1', marginTop: 10 }}
            titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
          />
        )}
        {!isLoading && (
          <div>
            <div style={{ width: '300', height: '300' }}>
              <RadarChart data={chartDatasets} min={0} max={5} stepSize={1} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2>{translations.t('analytics.subskillsScores')}</h2>
              <IconButton style={{ marginLeft: 10 }} onClick={() => window.open(instructionsLink)}>
                <InfoIcon />
              </IconButton>
            </div>
            {
              _.map(skillsData, (skill) => {
                const skillData = _.find(skillsAnalytics, d => d.id === skill.id);
                const skillTotalPoints = skillData && skillData.value ? parseFloat(skillData.value).toFixed(1) : 0;
                return (
                  <MDAccordion
                    title={skill.name}
                    containerstyle={{ marginTop: 10 }}
                    titleStyle={{ fontSize: 20 }}
                    key={skill.id}
                    subtitle={(
                      <StarRateView
                        value={skillTotalPoints}
                        totalValues={5}
                        starStyle={{ width: 15 }}
                        unselectedStarStyle={{ width: 9 }}
                        titleStyle={{ fontWeight: 100, fontSize: 15, margin: 0 }}
                      />
                    )}
                  >
                    <div style={{ padding: 10 }}>
                      <Divider />
                      {_.map(_.uniqBy(subSkillsFlat[skill.id], s => s.id), (subSkill) => {
                        const subSkillValue = _.find(userAnalytics.subskills[skill.id], { skillId: subSkill.id });
                        return (
                          <div key={`subSkillsPoints-${subSkill.id}`} style={{ marginBottom: 20 }}>
                            <StarRateView
                              key={subSkill.id}
                              title={subSkill.name}
                              value={subSkillValue ? subSkillValue.score : 0}
                              totalValues={5}
                              starStyle={{ width: 15 }}
                              unselectedStarStyle={{ width: 9 }}
                              starContainerStyle={{ marginBottom: 15, marginTop: 10 }}
                              titleContainerStyle={{ marginBottom: 10 }}
                              titleStyle={{ fontWeight: 100, fontSize: 18, margin: 0 }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </MDAccordion>
                );
              })
            }
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  analytics: state.analytics,
  skills: state.skills,
  userData: state.user.data,
});

export default connect(mapStateToProps)(BaseUserAnalytics);
