import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import MDCheckBoxView from './MDCheckBoxView';

const styles = {  
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  titleText: {
    margin: 0,
    marginRight: 5,
    fontSize: 15,
    letterSpacing: 1,
  },
  subtitleText: {
    margin: 0,
    marginRight: 5,
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: 'normal',
  },
  subtitleContainer: {
    marginBottom: 10,
  }
};

class MDCheckBoxField extends Component {
  onHandleChange(value, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(value);
    }
  }

  render() {
    const { input, checkBoxColor, containerstyle, showErrorLabel, meta, disabled, title, classes, titleContainerStyle, titleStyle, subTitleStyle, subtitle } = this.props;
    const checked = input.value;
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', ...containerstyle }}>
        {title && (
          <div className={classes.titleContainer} style={{ ...titleContainerStyle }}>
            <h4 className={classes.titleText} style={{ ...titleStyle, color: '#3f3f3f' }}>{title}</h4>
          </div>
        )}
        {subtitle && (
          <div className={classes.subtitleContainer}>
            <h4 className={classes.subtitleText} style={{ ...subTitleStyle, color: '#3f3f3f' }}>{subtitle}</h4>
          </div>
        )}
        <MDCheckBoxView
          checked={checked}
          color={checkBoxColor}
          disabled={disabled}
          onSelect={value => this.onHandleChange(value, input)}
          {...this.props}
        />
        {showErrorLabel && hasError && <h6 style={{ color: 'red', marginTop: 4, marginLeft: 10, marginBottom: 0 }}>{meta.error}</h6>}
      </div>
    );
  }
};

export default withStyles(styles)(MDCheckBoxField);

