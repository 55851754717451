import { AppBar, Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import UserPointsIcon from '@material-ui/icons/TrackChanges';
import UserAnswersIcon from '@material-ui/icons/TrendingUp';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QUIZ_STATUS_TYPES, SUBMISSION_STATUS_TYPES } from '../../config/configurations';
import * as AnalyticsActions from '../../redux/actions/analytics.actions';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as QuestionsActions from '../../redux/actions/questions.actions';
import * as SubmissionsActions from '../../redux/actions/submissions.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import { enumerateDaysBetweenDates } from '../../utils/utilsFunctions';
import LineChart from '../Charts/LineChart';
import MDAccordion from '../MDAccordion/MDAccordion';
import MDButton from '../MDButton/MDButton';
import Spinner from '../Spinner/Spinner';
import CSVButton from './CSVButton';
import QuizUserAnalytics from './QuizUserAnalytics';
import UserAnswers from './UserAnswers';


const styles = () => ({
  container: {
    margin: 10,
    marginTop: 0,
    height: '80vh',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

class QuizAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      activeTabIndex: 0,
      csvData: [],
      csvHeaders: [],
      usersAnalyticsPanelsIds: [],
      usersAnswersPanelsIds: [],
    };
  }

  async componentDidMount() {
    const { dispatch, quizzes: { selectedQuiz: quiz } } = this.props;
    try {
      await dispatch(AnalyticsActions.fetchQuizSubmissionsEntries());
      await dispatch(SubmissionsActions.fetchQuizSubmissions(quiz.id));
      const questionsPromises = _.map(
        quiz.questionsIds,
        async (id) => await dispatch(QuestionsActions.fetchQuestionById(id)),
      );
      await Promise.all(questionsPromises);
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  onTabChange(index)  {
    this.setState({ activeTabIndex: index });
  }

  async onDownloadCSV() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(AnalyticsActions.onDownloadQuizResults());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('DOWNLOAD_CSV_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('analytics.downloadCsvSuccess'),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('CREATE_CSV_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('analytics.errorCreateCSV'),
        },
      }));
    }
  }

  // Load analytics only if the panel is expanded. If the panel has already been expanded then avoid reload all the statistics for the user
  onToggleUserAnalytics(isExpanded, userId) {
    const { usersAnalyticsPanelsIds } = this.state;
    if (isExpanded && _.indexOf(usersAnalyticsPanelsIds, userId) === -1) {
      const newPanelsIds = [...usersAnalyticsPanelsIds, userId];
      this.setState({ usersAnalyticsPanelsIds: newPanelsIds });
    }
  }


  onToggleUserAnswers(isExpanded, userId) {
    const { usersAnswersPanelsIds } = this.state;
    if (isExpanded && _.indexOf(usersAnswersPanelsIds, userId) === -1) {
      const newPanelsIds = [...usersAnswersPanelsIds, userId];
      this.setState({ usersAnswersPanelsIds: newPanelsIds });
    }
  }

  render() {
    const {
      classes,
      quizzes: { selectedQuiz: quiz },
      analytics: { quizzes: quizzesAnalytics },
      platformUsers: { data: { content: platformUsersData } },
      submissions: { data: { quizzes: quizSubmissionsData } },
      sendReminder,
    } = this.props;

    const { isLoading, activeTabIndex, csvData, csvHeaders, usersAnalyticsPanelsIds, usersAnswersPanelsIds } = this.state;

    const isDraft = (submission) => (
      submission && submission.submissionStatusType === SUBMISSION_STATUS_TYPES.DRAFT
    );

    const isSubmitted = (submission) => (
      submission && submission.submissionStatusType === SUBMISSION_STATUS_TYPES.SUBMITTED
    );

    const labels = enumerateDaysBetweenDates(quiz.startDate, quiz.endDate);
    const data = [{
      values:
        _.map(labels, (label) => {
          const date = _.find(quizzesAnalytics[quiz.id], { date: label });
          return (date && date.count) || 0;
        }),
    }];

    const isQuizAnalyticsTab = activeTabIndex === 0;

    const isQuizRunning = quiz && quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container}>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center '}}>
              <h1 style={{ margin: 0, marginRight: 20 }}>{`${quiz.name} ${translations.t('analytics.analytics')}`}</h1>
              {!_.isEmpty(quiz.submissions) && (
                <CSVButton
                  data={csvData}
                  containerstyle={{ marginTop: 0 }}
                  headers={csvHeaders}
                  fetchCSVData={() => this.onDownloadCSV()}
                  fileName={`QuizResults-${moment().format('LL')}.csv`}
                />
              )}
            </div>
            {isLoading && (
              <Spinner
                customStyle={{ margin: 'auto' }}
                title={translations.t('general.loading')}
                hideLogo
                spinnerStyle={{ color: '#5AC0B1', marginTop: 10 }}
                titleStyle={{ color: '#80888d', marginTop: 5, fontSize: 12 }}
              />
            )}
            {!isLoading && (
              <div>
                <AppBar position="static" style={{ backgroundColor: 'white' }}>
                  <Tabs
                    value={activeTabIndex}
                    onChange={(event, index) => this.onTabChange(index)}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label={translations.t('analytics.usersSubmissions')} icon={<UserAnswersIcon />}/>
                    <Tab label={translations.t('analytics.usersQuizSkillsScore')} icon={<UserPointsIcon />}/>
                  </Tabs>
                </AppBar>
                <Paper style={{ padding: 20 }}>
                  {isQuizAnalyticsTab ? (
                    <LineChart
                      height={100}
                      labels={labels}
                      data={data}
                      min={0}
                      colors
                      stepSize={1}
                    />) : null}
                    <div style={{ marginBottom: 50 }}>
                      {
                        _.map(quiz.participantsIds, (id, key) => {
                          const user = _.find(platformUsersData, { id });
                          if (!user) return null;
                          const submission = _.find(quizSubmissionsData[quiz.id], { ownerId: user.id });
                          const isSubmissionPending = !submission || (submission && submission.submissionStatusType !== SUBMISSION_STATUS_TYPES.SUBMITTED);
                          const showSendReminder = isQuizRunning && isSubmissionPending;

                          const loadUserStatistics = _.indexOf(usersAnalyticsPanelsIds, user.id) !== -1 || key === 0;
                          const loadUserAnswers = _.indexOf(usersAnswersPanelsIds, user.id) !== -1 || key === 0;
                          
                          return (
                            <div>
                              {isQuizAnalyticsTab ? (
                                <MDAccordion
                                  title={`${user.name} ${user.surname}`}
                                  defaultExpanded={key === 0}
                                  key={submission && submission.id ? submission.id: key}
                                  containerstyle={{ marginTop: 10, width: '100%' }}
                                  onChange={(e, isExpanded) => this.onToggleUserAnswers(isExpanded, user.id)}
                                  subtitle={showSendReminder ? (
                                    <MDButton
                                      title={translations.t('quizzes.sendReminder')}
                                      backgroundColor={EDULAI_BLUE}
                                      containerstyle={{ margin: 0 }}
                                      onClick={() => sendReminder(quiz.id, user.id)}
                                    />
                                  ): null}
                                >
                                  <div style={{ padding: 10 }}>
                                    {isDraft(submission) && (
                                      <div style={{ margin: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                        <WarningIcon style={{ marginRight: 5 }} />
                                        <h3 style={{ fontWeight: 'normal' }}>{translations.t('analytics.submissionStillDraft')}</h3>
                                      </div>
                                    )}
                                    {isSubmitted(submission) && loadUserAnswers ? (
                                      <div>
                                        <UserAnswers quiz={quiz} submission={submission} />
                                      </div>
                                    ): null}
                                    {!submission && (
                                      <div style={{ margin: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                        <WarningIcon style={{ marginRight: 5 }} />
                                        <h3 style={{ fontWeight: 'normal' }}>{translations.t('analytics.noSubmissionStarted')}</h3>
                                      </div>
                                    )}
                                  </div>
                                </MDAccordion>
                              ) : (
                                <MDAccordion
                                  title={`${user.name} ${user.surname}`}
                                  defaultExpanded={key === 0}
                                  key={user.id}
                                  containerstyle={{ marginTop: 10, width: '100%' }}
                                  onChange={(e, isExpanded) => this.onToggleUserAnalytics(isExpanded, user.id)}
                                  subtitle={showSendReminder ? (
                                    <MDButton
                                      title={translations.t('quizzes.sendReminder')}
                                      backgroundColor={EDULAI_BLUE}
                                      containerstyle={{ margin: 0 }}
                                      onClick={() => sendReminder(quiz.id, user.id)}
                                    />
                                  ): null}
                                >
                                  <div style={{ padding: 10 }}>
                                    {isDraft(submission) && (
                                      <div style={{ margin: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                        <WarningIcon style={{ marginRight: 5 }} />
                                        <h3 style={{ fontWeight: 'normal' }}>{translations.t('analytics.submissionStillDraft')}</h3>
                                      </div>
                                    )}
                                    {isSubmitted(submission) && loadUserStatistics ?(
                                      <div>
                                        <QuizUserAnalytics  quiz={quiz.id} user={user.id} />
                                      </div>
                                    ) : null}
                                    {!submission && (
                                      <div style={{ margin: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                        <WarningIcon style={{ marginRight: 5 }} />
                                        <h3 style={{ fontWeight: 'normal' }}>{translations.t('analytics.noSubmissionStarted')}</h3>
                                      </div>
                                    )}
                                  </div>
                                </MDAccordion>
                              )}
                            </div>
                          );
                        })
                      }
                  </div>
              </Paper>
            </div>
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  quizzes: state.quizzes,
  analytics: state.analytics,
  platformUsers: state.platformUsers,
  submissions: state.submissions,
  answers: state.answers,
  questions: state.questions,
});

export default connect(mapStateToProps)(withStyles(styles)(QuizAnalytics));
