
import { CssBaseline, List, ListItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import DomainIcon from '@material-ui/icons/CardTravel';
import UsersIcon from '@material-ui/icons/PeopleAlt';
import QuizzesIcon from '@material-ui/icons/Assignment';
import QuestionsIcon from '@material-ui/icons/SpeakerNotes';
import StatisticsIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SkillsIcon from '@material-ui/icons/DynamicFeed';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EdulaiLogo from '../../assets/logo.png';
import HeaderButton from '../../components/Header/HeaderButton';
import tranlations from '../../translations/i18next';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import AppContainer from './AppContainer';
import Permission from '../../components/Permission/Permission';
import { PERMISSIONS, PERMISSION_ENTITIES, USER_ROLES } from '../../config/configurations';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';

const drawerWidth = 260;

const styles = (theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: '#f8f9fc',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: drawerWidth,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#f5f6f7' },
  },
  typography: {
    useNextVariants: true,
  },
});

const SideBar = ({ languange }) => (
  <div style={{ zoom: '90%', height: '120vh', backgroundColor: 'darkslateblue', display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 70, paddingLeft: 10 }}>
      <img alt="glance" src={EdulaiLogo} style={{ width: 180 }} />
    </div>
    <Divider />
    <List>
      <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.QUIZ} rolesNot={[USER_ROLES.PRINCIPAL]} abilityHelper={AbilityProvider.getDomainAbilityHelper()}>
        <ListItem button key={tranlations.t('header.quiz')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.quiz')}
            titleStyleSelected={{ fontSize: 18, color: 'white', fontWeight: 'bold' }}
            icon={<QuizzesIcon style={{ color: 'rgba(255,255,255,.3)' }} />}
            iconSelected={<QuizzesIcon style={{ color: 'white' }} />}
            paths={['/quizzes']}
          />
        </ListItem>
      </Permission>
      <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.USER} abilityHelper={AbilityProvider.getDomainAbilityHelper()}>
        <ListItem button key={tranlations.t('header.users')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.users')}
            titleStyleSelected={{ fontSize: 18, color: 'white', fontWeight: 'bold' }}
            icon={<UsersIcon style={{ color: 'rgba(255,255,255,.3)' }} />}
            iconSelected={<UsersIcon style={{ color: 'white' }} />}
            paths={['/users']}
          />
        </ListItem>
      </Permission>
      <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.QUESTION} abilityHelper={AbilityProvider.getDomainAbilityHelper()}>
        <ListItem button key={tranlations.t('header.questions')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.questions')}
            titleStyleSelected={{ fontSize: 18, color: 'white', fontWeight: 'bold' }}
            icon={<QuestionsIcon style={{ color: 'rgba(255,255,255,.3)' }} />}
            iconSelected={<QuestionsIcon style={{ color: 'white' }} />}
            paths={['/questions']}
          />
        </ListItem>
      </Permission>
      <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.SKILL} abilityHelper={AbilityProvider.getDomainAbilityHelper()}>
        <ListItem button key={tranlations.t('header.skills')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.skills')}
            titleStyleSelected={{ fontSize: 18, color: 'white', fontWeight: 'bold' }}
            icon={<SkillsIcon style={{ color: 'rgba(255,255,255,.3)' }} />}
            iconSelected={<SkillsIcon style={{ color: 'white' }} />}
            paths={['/skills']}
          />
        </ListItem>
      </Permission>
      <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.ANALYTICS} rolesNot={[USER_ROLES.SUPERVISOR]} abilityHelper={AbilityProvider.getDomainAbilityHelper()}>
        <ListItem button key={tranlations.t('header.statistics')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.statistics')}
            titleStyleSelected={{ fontSize: 18, color: 'white', fontWeight: 'bold' }}
            icon={<StatisticsIcon style={{ color: 'rgba(255,255,255,.3)' }} />}
            iconSelected={<StatisticsIcon style={{ color: 'white' }} />}
            paths={['/statistics']}
          />
        </ListItem>
      </Permission>
      <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.DOMAIN} rolesNot={[USER_ROLES.USER]} abilityHelper={AbilityProvider.getDomainAbilityHelper()}>
        <ListItem button key={tranlations.t('header.domain')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.domain')}
            titleStyleSelected={{ fontSize: 18, fontFamily: 'Oxygen', color: 'white', fontWeight: 'bold' }}
            icon={<DomainIcon style={{ color: 'rgba(255,255,255,.3)' }} />}
            iconSelected={<DomainIcon style={{ color: 'white' }} />}
            paths={['/domain']}
          />
        </ListItem>
      </Permission>
      <ListItem button key={tranlations.t('header.settings')} style={{ height: 55, margin: 0, padding: 0 }}>
        <HeaderButton
          title={tranlations.t('header.settings')}
          titleStyleSelected={{ fontSize: 18, fontFamily: 'Oxygen', color: 'white', fontWeight: 'bold' }}
          icon={<SettingsIcon style={{ color: 'rgba(255,255,255,.3)' }} />}
          iconSelected={<SettingsIcon style={{ color: 'white' }} />}
          paths={['/settings']}
        />
      </ListItem>
    </List>
  </div>
);

class SidebarLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: false,
    };
  }

  toggleHeaderMenu() {
    const { showHeader } = this.state;
    this.setState({ showHeader: !showHeader });
  }

  profileRouting() {
    const { user: { data: userData }, history } = this.props;
    if (userData.isPhotographer) {
      history.push('/myProfile');
    } else {
      history.push('/profile');
    }
  }


  render() {
    const { classes, children, languange } = this.props;
    const { showHeader } = this.state;
    
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          {isMobileBrowser() && (
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => this.toggleHeaderMenu()}
                className={classes.menuButton}
              >
                <div style={{ marginTop: 3, }}>
                  <MenuIcon style={{ marginBottom: 5 }} />
                  <img alt="edulai" src={EdulaiLogo} style={{ width: 100, marginLeft: 10  }} />
                </div>
              </IconButton>
            </Toolbar>
          )}
          <div className={classes.drawer}>
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={showHeader}
                onClose={() => this.toggleHeaderMenu()}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <SideBar languange={languange} />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <SideBar languange={languange} />
              </Drawer>
            </Hidden>
          </div>
        </div>
        <div style={{ paddingTop: isMobileBrowser() ? 100: 0, paddingLeft: isMobileBrowser() ? 10 : 260, height: '120vh' }}>
          <AppContainer>
            {children}
          </AppContainer>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  utils: state.utils,
  user: state.user,
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SidebarLayout)));
