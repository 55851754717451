import Paper from '@material-ui/core/Paper';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, initialize } from 'redux-form';

import QuizAnalytics from '../../components/Analytics/QuizAnalytics';
import UserAnswers from '../../components/Analytics/UserAnswers';
import QuizForm from '../../components/Forms/QuizForm/QuizForm';
import ListComponent from '../../components/ListComponents/ListComponent';
import QuizRow from '../../components/ListComponents/RowComponents/QuizRow';
import QuizDetails from '../../components/QuizDetails/QuizDetails';
import QuizSubmission from '../../components/QuizSubmission/QuizSubmission';
import Spinner from '../../components/Spinner/Spinner';
import {
  PERMISSION_ENTITIES,
  PERMISSIONS,
  QUIZ_STATUS_TYPES,
  SUBMISSION_STATUS_TYPES,
  USER_ROLES,
} from '../../config/configurations';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as PlatformUserActions from '../../redux/actions/platformUsers.actions';
import * as QuestionsActions from '../../redux/actions/questions.actions';
import * as QuizzesActions from '../../redux/actions/quizzes.actions';
import * as SkillsActions from '../../redux/actions/skills.actions';
import * as SubmissionsActions from '../../redux/actions/submissions.actions';
import * as TagsActions from '../../redux/actions/tags.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { EDULAI_BLUE_DARK } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const styles = () => ({
  container: {
    padding: isMobileBrowser() ? 0 : 20,
  },
  title: {
    margin: 10,
  },
  chartsContainer: {
    marginTop: 10,
  },
  noQuizContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center',
  },
  noQuizText: {
    margin: 0,
    color: '#7F888F',
  },
  noQuizIcon: {
    fontSize: 40,
    color: '#7F888F',
    marginRight: 20,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    paddingTop: 25,
    padding: 20,
    marginTop: 7,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE_DARK },
  },
  typography: {
    useNextVariants: true,
  },
});

class QuizzesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      this.containerDiv.scrollIntoView({ behavior: 'smooth' });
      this.setState({ isLoading: true });
      await this.onFetchQuizzes();
      await dispatch(SubmissionsActions.fetchSubmissionsByUser());
      this.setState({ isLoading: false });
      dispatch(PlatformUserActions.setUsersFilter('enabled', true));
      await dispatch(SkillsActions.fetchSkillsAndSubSkills());
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onAppendQuizzes(page) {
    await this.onFetchQuizzes(page);
  }

  async onNewQuizClicked() {
    const { dispatch } = this.props;
    dispatch(destroy('QuizForm'));
    dispatch(QuizzesActions.setSelectedQuiz({}));
    dispatch(UtilsActions.setSpinnerVisible(true));
    await dispatch(QuestionsActions.fetchQuestions());
    await dispatch(PlatformUserActions.fetchAllUsers());
    try {
      await dispatch(TagsActions.fetchTags());
    } catch (error) {
      // ignore
    }
    dispatch(UtilsActions.setSpinnerVisible(false));
    dispatch(initialize('QuizForm', { hideScore: false }));
    dispatch(ModalsActions.showModal('NEW_QUIZ_FORM_MODAL', {
      modalType: 'OPERATIONAL_VIEW',
      modalProps: {
        content: (
          <QuizForm
            onSubmit={(quizData) => this.onCreateNewQuiz(quizData)}
          />
        ),
      },
    }));
  }

  onDeleteQuizHandler() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('DELETE_QUIZ_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        title: translations.t('forms.warning'),
        bodyText: translations.t('quizzes.confirmDeleteQuiz'),
        onConfirm: () => this.deleteQuiz(),
        confirmText: translations.t('forms.delete'),
      },
    }));
  }

  // eslint-disable-next-line react/sort-comp
  async deleteQuiz() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.deleteQuiz());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('DELETE_QUIZ_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.deleteQuizSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('DELETE_QUIZ_MODAL'));
      dispatch(ModalsActions.hideModal('EDIT_QUIZ_MODAL'));
    } catch (error) {
      dispatch(ModalsActions.hideModal('DELETE_QUIZ_MODAL'));
      dispatch(ModalsActions.showModal('DELETE_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.terminateQuizError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onCreateNewQuiz(quizData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      const newQuiz = await dispatch(QuizzesActions.createQuiz(quizData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('CREATE_QUIZ_SUCCESS_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('quizzes.createQuizSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('NEW_QUIZ_FORM_MODAL'));
      dispatch(QuizzesActions.fetchQuizzDetails(newQuiz));
    } catch (error) {
      dispatch(ModalsActions.showModal('CREATE_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.createQuizError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  onQuizClickedHandler(quiz) {
    const { user: { data: user } } = this.props;
    if (user.role.name === USER_ROLES.USER) {
      this.onShowQuizSubmission(quiz);
    } else if (quiz.quizStatusType === QUIZ_STATUS_TYPES.DRAFT) {
      this.onEditQuiz(quiz);
    } else {
      this.onViewQuizDetails(quiz);
    }
  }

  async onSearchQuiz(filterValue) {
    const { dispatch } = this.props;
    try {
      this.setState({ isLoading: true });
      dispatch(QuizzesActions.resetQuizFilters());
      if (filterValue) {
        if (filterValue.name) {
          dispatch(QuizzesActions.setQuizzesFilter('name', filterValue.name));
        }
        if (filterValue.quizStatusType && filterValue.quizStatusType.value !== 'ALL') {
          dispatch(QuizzesActions.setQuizzesFilter('quizStatusType', filterValue.quizStatusType.value));
        }
      }
      dispatch(QuizzesActions.resetQuizzesData());
      await dispatch(QuizzesActions.fetchQuizzes());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onResetQuizFilters() {
    const { dispatch } = this.props;
    try {
      this.setState({ isLoading: true });
      dispatch(QuizzesActions.resetQuizFilters());
      await this.onFetchQuizzes();
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onFetchQuizzes(page) {
    const { dispatch, user: { data: { role } } } = this.props;
    if (role.name === USER_ROLES.USER) {
      await dispatch(QuizzesActions.fetchQuizzesForUsers(page));
    } else {
      await dispatch(QuizzesActions.fetchQuizzesAndSubmissions(page));
    }
  }

  async onEditQuiz(quiz) {
    const {
      dispatch,
    } = this.props;
    try {
      dispatch(QuizzesActions.setSelectedQuiz(quiz));
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.fetchBaseUsers());
      await dispatch(TagsActions.fetchTags());
      await dispatch(QuestionsActions.fetchQuestions());
      const {
        tags: { data: tagsData },
        users: { baseUsers: { content: usersData } },
      } = this.props;
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(initialize('QuizForm', {
        ...quiz,
        participants: _.map(quiz.participantsIds, (id) => {
          const user = _.find(usersData, { id });
          return {
            value: id,
            label: `${user && user.name ? user.name : ''} ${user && user.surname ? user.surname : ''}`,
          };
        }),
        tags: _.map(quiz.userTagOutDTOS, (tag) => {
          const tagData = _.find(tagsData, { id: tag.id });
          return {
            value: tagData.id,
            label: tagData.name,
          };
        }),
        questions: quiz.questionsIds,
      }));
      dispatch(ModalsActions.showModal('EDIT_QUIZ_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: (
            <QuizForm
              edit
              onSubmit={(quizData) => this.onUpdateQuiz(quizData)}
              onDeleteQuizHandler={() => this.onDeleteQuizHandler()}
            />
          ),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onViewQuizDetails(quiz) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      dispatch(QuizzesActions.setSelectedQuiz(quiz));
      dispatch(PlatformUserActions.fetchUsersTags());
      await dispatch(PlatformUserActions.fetchAllUsers());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('VIEW_QUIZ_DETAILS_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: (<QuizDetails sendReminder={(quizReminderId, userId) => this.onSendUserReminder(quizReminderId, userId)} />),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  onShowQuizSubmission(quiz) {
    const { dispatch } = this.props;
    dispatch(QuizzesActions.setSelectedQuiz(quiz));
    dispatch(ModalsActions.showModal('VIEW_QUIZ_SUBMISSION_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        content: (<QuizSubmission onFinishQuiz={() => this.onFinishQuiz(quiz)} />),
        hideCancel: true,
        fullScreen: true,
      },
    }));
  }

  async onUpdateQuiz(quizData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      const quizUpdated = await dispatch(QuizzesActions.updateQuiz(quizData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('EDIT_QUIZ_SUCCESS_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('quizzes.editQuizSuccess'),
        },
      }));
      dispatch(ModalsActions.hideModal('EDIT_QUIZ_MODAL'));
      dispatch(QuizzesActions.fetchQuizzDetails(quizUpdated));
    } catch (error) {
      dispatch(ModalsActions.showModal('EDIT_QUIZ_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.editQuizError'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  onFinishQuiz = async (quiz) => {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(SubmissionsActions.fetchSubmissionsByUser());
      await dispatch(SubmissionsActions.finishSubmission());
      dispatch(ModalsActions.showModal('ANSWER_SUBMIT_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('submissions.submissionSubmitSuccess'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
      const quizDetailed = await dispatch(QuizzesActions.fetchQuizById(quiz));
      this.onShowQuizSubmission(quizDetailed);
      this.onFetchQuizzes();
    } catch (error) {
      let errorMessage = translations.t('submissions.submissionSubmitError');
      if (error === 2004) {
        errorMessage = translations.t('submissions.submissionsRefusedNoCredits');
      }
      dispatch(ModalsActions.showModal('ANSWER_SUBMIT_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: errorMessage,
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  showUserAnswers = (quiz) => {
    const {
      dispatch,
      submissions: { data: { user: quizSubmissionsData } },
      user: { data: user },
    } = this.props;

    const submission = _.find(quizSubmissionsData, { ownerId: user.id });

    dispatch(QuizzesActions.setSelectedQuiz(quiz));
    dispatch(ModalsActions.showModal('VIEW_QUIZ_STATS_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        content: (<UserAnswers submission={submission} />),
        hideCancel: true,
        fullWidth: true,
      },
    }));
  }

  showStats = async (quiz) => {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      dispatch(QuizzesActions.setSelectedQuiz(quiz));
      await dispatch(PlatformUserActions.fetchAllUsers());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('VIEW_QUIZ_STATS_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          content: (<QuizAnalytics quiz={quiz} sendReminder={(quizReminderId, userId) => this.onSendUserReminder(quizReminderId, userId)} />),
          hideCancel: true,
          fullScreen: true,
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onSendUserReminder(quizId, userId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisible(true));
    try {
      await QuizzesActions.sendDeadlineNotification(quizId, userId);
      dispatch(ModalsActions.showModal('REMINDER_SEND_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: translations.t('quizzes.sendReminderSuccess'),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.showModal('REMINDER_SEND_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: translations.t('quizzes.sendReminderError'),
        },
      }));
    }
  }

  isQuizSubmitted = (quizId) => {
    const { submissions: { data: { user: userSubmissionsData } } } = this.props;
    const submission = _.find(userSubmissionsData, { quizId });
    return submission && (submission.submissionStatusType === SUBMISSION_STATUS_TYPES.SUBMITTED);
  }

  render() {
    const { isLoading } = this.state;
    const {
      classes,
      quizzes: { data: { content: quizzesData, pagination } },
      user: { data: user },
    } = this.props;

    const options = user.role.name === USER_ROLES.USER
      ? null
      : _.map(QUIZ_STATUS_TYPES, (type) => ({
        value: type,
        label: translations.t(`quizzes.types.${type}`),
      }));

    const groups = _(quizzesData)
      .sortBy('endDate')
      .groupBy('quizStatusType')
      .value();

    const sortedQuizzesData = _.flatten([
      groups[QUIZ_STATUS_TYPES.DRAFT] || [],
      groups[QUIZ_STATUS_TYPES.RUNNING] || [],
      groups[QUIZ_STATUS_TYPES.PAUSED] || [],
      groups[QUIZ_STATUS_TYPES.READY] || [],
      groups[QUIZ_STATUS_TYPES.DONE] || [],
    ]);

    const canCreateQuiz = AbilityProvider
      .getDomainAbilityHelper()
      .hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.QUIZ);
    const isMobile = isMobileBrowser();
    return (
      <div ref={(c) => { this.containerDiv = c; }}>
        <MuiThemeProvider theme={theme}>
          <div className={classes.container}>
            <h1 className={classes.title}>{translations.t('header.quiz')}</h1>
            <ListComponent
              pagination={pagination}
              containerstyle={{ width: isMobile ? 'unset' : '90%' }}
              newElementText={translations.t('quizzes.newQuiz')}
              searchFieldLabel={translations.t('questions.findInTitle')}
              onLoadMore={(page) => this.onAppendQuizzes(page)}
              onCreateNew={canCreateQuiz ? () => this.onNewQuizClicked() : null}
              onSearch={canCreateQuiz ? (values) => this.onSearchQuiz(values) : null}
              onResetFilters={canCreateQuiz ? () => this.onResetQuizFilters() : null}
              options={options}
            >
              <Paper className={classes.listContainer} square style={{ overflowY: 'scroll' }}>
                {!isLoading && sortedQuizzesData && _.map(sortedQuizzesData, (quiz) => (
                  <QuizRow
                    key={quiz.id}
                    quiz={quiz}
                    userId={user.id}
                    isBaseUser={user.role.name === USER_ROLES.USER}
                    isQuizSubmitted={this.isQuizSubmitted(quiz.id)}
                    answers={quiz.answers}
                    outerContainerstyle={{ marginTop: 15 }}
                    onClick={() => this.onQuizClickedHandler(quiz)}
                    onFinishQuiz={() => this.onFinishQuiz(quiz)}
                    onShowStats={() => this.showStats(quiz)}
                    onShowUserAnswers={() => this.showUserAnswers(quiz)}
                  />
                ))}
                {(!quizzesData || _.isEmpty(quizzesData)) && !isLoading && (
                  <div className={classes.noQuizContainer}>
                    <AssignmentIcon className={classes.noQuizIcon} />
                    <h4 className={classes.noQuizText}>{translations.t('quizzes.noQuizzesFound')}</h4>
                  </div>
                )}
                {isLoading && (
                  <Spinner
                    title={translations.t('general.loading')}
                    hideLogo
                    spinnerStyle={{ color: '#5AC0B1', marginTop: 10 }}
                    titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
                  />
                )}
              </Paper>
            </ListComponent>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  quizzes: state.quizzes,
  submissions: state.submissions,
  questions: state.questions,
  users: state.platformUsers,
  user: state.user,
  tags: state.tags,
});

export default connect(mapStateToProps)(withStyles(styles)(QuizzesView));
