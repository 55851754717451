import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

export const fetchQuizzes = async (params) => {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/quizzes`, paramsToSend);
};

export const fetchAllQuizzes = async (params) => {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/quizzes/all`, paramsToSend);
};

export const fetchCreatedQuizzes = async (params) => {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/quizzes/withFilter`, paramsToSend);
};

export const createQuiz = async (quizInDTO) => (
  edulaiInstance.post(`/api/${API_VERSION}/quizzes`, quizInDTO)
);

export const submitQuiz = async (quizId) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/submit`)
);

export const toggleQuizStatus = async (quizId, status) => {
  const quizInDTO = { quizStatusType: status };
  return edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/toggleStatus`, quizInDTO);
};

export const changeQuizStartDateTime = async (quizId, quizUpdateStartDateInDTO) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/updateStartDate`, quizUpdateStartDateInDTO)
);

export const forceStartQuiz = async (quizId) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/forceStart`)
);

export const addParticipants = async (quizId, quizUpdateParticipantsInDTO) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/updateParticipants`, quizUpdateParticipantsInDTO)
);

export const updateHideScore = async (quizId, quizUpdatHideScoreInDTO) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/updateHideScore`, quizUpdatHideScoreInDTO)
);

export const terminateQuiz = async (quizId) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/terminate`)
);

export const updateQuiz = async (quizId, quizUpdateInDTO) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}`, quizUpdateInDTO)
);

export const reactivateQuiz = async (quizId) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/reactivate`)
);

export const extendExpiration = async (quizId, quizUpdateExpirationInDTO) => (
  edulaiInstance.put(`/api/${API_VERSION}/quizzes/${quizId}/extendExpiration`, quizUpdateExpirationInDTO)
);

export const deleteQuiz = async (quizId) => (
  edulaiInstance.delete(`/api/${API_VERSION}/quizzes/${quizId}`)
);

export const getQuiz = async (quizId) => (
  edulaiInstance.get(`/api/${API_VERSION}/quizzes/${quizId}`)
);

export const sendDeadlineNotification = async (quizId, userId) => {
  return edulaiInstance.get(`/api/${API_VERSION}/quizzes/${quizId}/forceDeadlineNotification/${userId}`);
};

export const sendDownloadReportRequest = async (quizId) => {
  return edulaiInstance.get(`/api/${API_VERSION}/quizzes/${quizId}/results`);
};