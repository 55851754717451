import React from 'react';
import * as UserActions from '../../redux/actions/user.actions';
import fetchConfig from '../../hoc/fetchConfig';
import NoServiceView from '../NoServiceView/NoServiceView';

async function fetchConfigFunction() {
  await UserActions.setInstanceConfigurations();
}

class ConfigComponent extends React.Component {
  render() {
    return (this.props.children);
  }
}

export default fetchConfig(fetchConfigFunction, NoServiceView)(ConfigComponent);
