import { withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import EnabledIcon from '@material-ui/icons/CheckCircle';
import BanIcon from '@material-ui/icons/NotInterested';
import DisabledIcon from '@material-ui/icons/HighlightOff';
import React from 'react';
import MDButton from '../MDButton/MDButton';
import translations from '../../translations/i18next';
import { RED } from '../../styles/styleConsts';

const styles = theme => ({
  formContainer: {
    margin: 20,
    marginTop: 20,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  headerTitle: {
    marginLeft: 20,
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  enabledText: {
    color: '#66c0b0',
    margin: 0,
  },
  disabledText: {
    color: 'red',
    margin: 0,
  },
  statusContainer: {
    marginLeft: 10,
    marginBottom: 20,
  },
  statusTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white',
  },
});

const UserStatusView = ({ isEnabled, isInactive, title, onDisableUser, onEnableUser, classes }) => (
  <div className={classes.statusContainer}>
    <Divider />
    <h2 className={classes.title}>{title || translations.t('forms.userStatus')}</h2>
    {isInactive ? (
      <h3 className={classes.subtitle}>{translations.t('forms.inactiveUserExplanation')}</h3>
    ) : (
      <h3 className={classes.subtitle}>{!isEnabled ? translations.t('forms.enabledUserExplanation') : translations.t('forms.disableUserExplanation')}</h3>
    )}
    <div style={{ display: isInactive? 'block' : 'flex', justifyContent: 'space-between', marginBottom: 10, marginTop: 20 }}>
      <Chip
        className={classes.statusTag}
        color={isEnabled ? 'primary' : 'secondary'}
        label={isInactive? translations.t('users.inactive') : isEnabled ? translations.t('users.enabled') : translations.t('users.disabled')}
        icon={isEnabled ? <EnabledIcon /> : <DisabledIcon />}
      />
      {!isEnabled && !isInactive && onEnableUser && (
        <MDButton
          title={translations.t('forms.enableUser')}
          backgroundColor="#5AC0B1"
          containerstyle={{ marginTop: 0 }}
          onClick={() => onEnableUser()}
        />
      )}
      {isEnabled && onDisableUser && (
        <MDButton
          title={translations.t('users.disableUser')}
          backgroundColor={RED}
          containerstyle={{ marginTop: 0 }}
          icon={<BanIcon style={{ color: 'white', marginLeft: 10, marginRight: 10 }} />}
          onClick={() => onDisableUser()}
        />
      )}
    </div>
    <Divider />
  </div>
);

export default withStyles(styles)((UserStatusView));
