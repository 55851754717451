import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit, change } from 'redux-form';
import debounce from 'debounce-promise';
import * as PlatformUserActions from '../../../redux/actions/platformUsers.actions';
import { EDULAI_BLUE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import MDButton from '../../MDButton/MDButton';
import SelectableField from '../FormsComponents/SelectableInput/SelectableField';
import { USER_ROLES } from '../../../config/configurations';

const validate = (values) => {
  const errors = {};
  if (!values.participants) {
    errors.participants = translations.t('forms.required');
  }
  if (values.participants && _.isEmpty(values.participants)) {
    errors.participants = translations.t('forms.min1hr');
  }
  return errors;
};

const styles = () => ({
  formContainer: {
    margin: 15,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  headerTitle: {
    marginLeft: 20,
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  enabledText: {
    color: '#66c0b0',
    margin: 0,
  },
  disabledText: {
    color: 'red',
    margin: 0,
  },
  statusContainer: {
    marginLeft: 10,
    marginBottom: 20,
  },
  statusTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

class AddParticipantsForm extends Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(PlatformUserActions.fetchBaseUsers());

    this.debouncedSearch = debounce(async (name) => {
      const usersData = await dispatch(PlatformUserActions.fetchBaseUsers(0, 100, (name || '').trim()));
      const newOptions = _.map(usersData, (user) => ({
        value: user.id,
        label: `${user.name} ${user.surname}`,
      }));
      return newOptions;
    }, 500);
  }

  async onFilterUserTags(name) {
    const { users: { tags: { content: userTags } } } = this.props;
    const filteredOptions = _.filter(userTags, (tag) => tag.name.toLowerCase().includes(name.toLowerCase()));
    const newOptions = _.map(filteredOptions, (tag) => ({
      value: tag.id, // workaround for creatable component
      label: tag.name,
    }));
    return newOptions;
  }

  async onUserTagChange(tags) {
    const { dispatch, form } = this.props;
    const selectedTags = (form.values && form.values.tags) || [];
    const selectedUsers = (form.values && form.values.participants) || [];
    const newTags = tags || [];
    const tag = newTags.length > selectedTags.length
      ? _.difference(newTags, selectedTags)
      : _.difference(selectedTags, newTags);
    if (!tag.length) {
      return;
    }
    const users = await dispatch(PlatformUserActions.fetchAllUsersByTag(tag[0].value));
    const options = _(users)
      .filter({ enabled: true, role: { name: USER_ROLES.USER } })
      .map((user) => ({
        value: user.id,
        label: `${user.name} ${user.surname}`,
      }))
      .value();
    const newParticipants = newTags.length > selectedTags.length
      ? _.union([...options, ...selectedUsers])
      : _.remove(selectedUsers, (user) => _.find(options, (option) => option.value !== user.value));

    dispatch(change('AddParticipantsForm', 'participants', newParticipants));
  }

  render() {
    const {
      participantsIds,
      dispatch,
      classes,
      users: { baseUsers: { content: usersData }, tags: { content: userTags } },
    } = this.props;

    const nonParticipants = _.filter(usersData, (user) => !participantsIds.includes(user.id));
    const options = _.map(nonParticipants, (user) => ({
      value: user.id,
      label: `${user.name} ${user.surname}`,
    }));

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.formContainer}>
          <Field
            name="tags"
            title={translations.t('forms.userTag')}
            placeholder={translations.t('forms.userTagPlaceholder')}
            component={SelectableField}
            onSelect={(value) => this.onUserTagChange(value)}
            multi
            titleContainerStyle={{ marginBottom: 10 }}
            containerstyle={{ marginTop: 20 }}
            onLoadOptions={(name) => this.onFilterUserTags(name)}
            defaultOptions={_.map(userTags, (tag) => ({
              value: tag.id, // workaround for creatable component
              label: tag.name,
            }))}
          />
          <Field
            name="participants"
            containerStyle={{ marginBottom: 20, marginTop: 20 }}
            component={SelectableField}
            placeholder={translations.t('quizzes.newParticipants')}
            title={translations.t('quizzes.participants')}
            multi
            mandatory
            onLoadOptions={this.debouncedSearch}
            defaultOptions={options}
          />
          <MDButton
            title={translations.t('forms.save')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{ marginBottom: 20 }}
            onClick={() => dispatch(submit('AddParticipantsForm'))}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.platformUsers,
  form: state.form.AddParticipantsForm,
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'AddParticipantsForm',
    validate,
    destroyOnUnmount: false,
  }),
  withStyles(styles),
])(AddParticipantsForm);
