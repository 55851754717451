import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import StatisticsIcon from '@material-ui/icons/Timeline';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { connect } from 'react-redux';

import {
  PERMISSION_ENTITIES,
  PERMISSIONS,
  QUIZ_STATUS_TYPES,
  SUBMISSION_STATUS_TYPES,
} from '../../../config/configurations';
import { EDULAI_BLUE, EDULAI_PURPLE, QUIZ_STATUS_COLORS } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import AbilityProvider from '../../../utils/PermissionsUtils/AbilityProvider';
import MDButton from '../../MDButton/MDButton';
import Permission from '../../Permission/Permission';
import { isMobileBrowser } from '../../../utils/utilsFunctions';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`,
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  typography: {
    useNextVariants: true,
  },
  titleContainer: {
    alignItems: 'center',
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 25,
    color: '#3f3f3f',
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5,
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#979da1',
    textAlign: 'left',
    fontSize: 12,
  },
  text: {
    fontWeight: 'normal',
    margin: 0,
    marginTop: 10,
  },
  status: {
    display: 'flex',
    margin: 'auto',
  },
  statusText: {
    textAlign: 'center',
    color: 'white',
    margin: 'auto',
    fontWeight: 800,
    fontSize: 18,
  },
});

const get12Or24HrsFormat = () => {
  const LTFormat = moment.localeData().longDateFormat('LT');
  if (LTFormat.split(' ').length > 1 && LTFormat.split(' ').pop() === 'A') {
    return 'MMMM D YYYY h:mm a';
  }
  return 'MMMM D YYYY H:mm';
};

const QuizRow = (props) => {
  const {
    quiz,
    onClick,
    classes,
    containerstyle,
    answers,
    isBaseUser,
    onFinishQuiz,
    onShowStats,
    userId,
  } = props;

  // Submissions
  const completedSubmissionsOverall = _.filter(
    quiz.submissions,
    (submission) => submission.submissionStatusType === SUBMISSION_STATUS_TYPES.SUBMITTED,
  );

  const pendingSubmissions = _.filter(
    quiz.submissions,
    (submission) => (
      submission.ownerId === userId
      && submission.submissionStatusType === SUBMISSION_STATUS_TYPES.DRAFT
    ),
  );


  // Partecipants
  const participantsCount = quiz && quiz.participantsIds ? _.size(quiz.participantsIds) : null;

  const completedCount = completedSubmissionsOverall ? _.size(completedSubmissionsOverall) : null;
  const givenAnswers = answers ? _.size(answers) : null;
  const dateTimeFormat = get12Or24HrsFormat();
  const completionAnswerPercentage = givenAnswers && quiz && quiz.questionsIds
    ? Math.floor((givenAnswers / _.size(quiz.questionsIds)) * 100)
    : 0;
  const completionSubmissionsPercentage = completedCount && participantsCount
    ? Math.floor((completedCount / participantsCount) * 100)
    : 0;
  const percentageToShow = isBaseUser
    ? completionAnswerPercentage
    : completionSubmissionsPercentage;
  const isPendingLastSubmissions = pendingSubmissions
    && !_.isEmpty(pendingSubmissions)
    && completionAnswerPercentage === 100
    && quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING;

  const isQuizRunningAndOver = quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING
    && _.isEmpty(pendingSubmissions)
    && completionAnswerPercentage === 100;

  const quizStatusToShow = isBaseUser && isQuizRunningAndOver
    ? QUIZ_STATUS_TYPES.DONE
    : quiz.quizStatusType;


  const isQuizStarted = quiz.quizStatusType !== QUIZ_STATUS_TYPES.DRAFT
    && quiz.quizStatusType !== QUIZ_STATUS_TYPES.READY;

  return (
    <MuiThemeProvider theme={theme}>
      <Card
        style={{ ...containerstyle }}
        className={classes.outerContainer}
      >
        <CardActionArea className={classes.eventContainer} onClick={() => onClick()}>
          <div style={{ width: '10%' }}>
            <CircularProgressbar value={percentageToShow} text={`${percentageToShow}%`} />
          </div>
          <div
            style={{
              position: 'relative',
              marginLeft: 20,
              width: '100%',
              display: 'flex',
            }}
          >
            <div style={{ width: isMobileBrowser() ? '60%' : '70%' }}>
              <h2 className={classes.title}>
                {quiz.name}
              </h2>
              <h4 className={classes.text}>
                {`${moment(quiz.startDate).format(dateTimeFormat)} - ${moment(quiz.endDate).format(dateTimeFormat)}`}
              </h4>
              <Permission
                do={[PERMISSIONS.UPDATE]}
                on={PERMISSION_ENTITIES.QUIZ}
                abilityHelper={AbilityProvider.getDomainAbilityHelper()}
              >
                <div style={{ display: 'flex  ' }}>
                  <div style={{ width: '50%' }}>
                    <h4 className={classes.text}>{`${translations.t('quizzes.participants')}: ${participantsCount}`}</h4>
                  </div>
                  <div style={{ width: '50%' }}>
                    <h4 className={classes.text}>{`${translations.t('quizzes.completed')}: ${completedCount}`}</h4>
                  </div>
                </div>
              </Permission>
              {givenAnswers && isBaseUser ? (
                <div style={{ display: 'flex  ' }}>
                  <div style={{ width: '50%' }}>
                    <h4 className={classes.text}>{`${translations.t('quizzes.givenAnswers')}: ${givenAnswers}`}</h4>
                  </div>
                  {quiz && quiz.questionsIds && (
                    <div style={{ width: '50%' }}>
                      <h4 className={classes.text}>{`${translations.t('quizzes.totalQuestions')}: ${_.size(quiz.questionsIds)}`}</h4>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div style={{ position: 'absolute', right: 0 }}>
              <div className={classes.status}>
                <Tooltip arrow title={translations.t(`quizzes.typesDescriptions.${quizStatusToShow}`)} placement="bottom">
                  <Chip
                    className={classes.photoTypeTag}
                    style={{
                      backgroundColor: QUIZ_STATUS_COLORS[quizStatusToShow],
                      color: 'white',
                      borderRadius: 5,
                    }}
                    label={translations.t(`quizzes.types.${quizStatusToShow}`)}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </CardActionArea>
        {isQuizStarted && (
          <Permission
            do={[PERMISSIONS.UPDATE]}
            on={PERMISSION_ENTITIES.QUIZ}
            abilityHelper={AbilityProvider.getDomainAbilityHelper()}
          >
            <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
              <MDButton
                icon={<StatisticsIcon style={{ marginRight: 5 }} />}
                title={translations.t('quizzes.quizStatistics')}
                backgroundColor={EDULAI_BLUE}
                containerstyle={{ marginTop: 0, marginRight: 15, marginBottom: 15 }}
                onClick={() => onShowStats()}
              />
            </div>
          </Permission>
        )}
        {isPendingLastSubmissions && isBaseUser && (
          <MDButton
            title={translations.t('submissions.concludeQuiz')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{ margin: 10, marginLeft: 40, marginRight: 40 }}
            onClick={() => onFinishQuiz()}
          />
        )}
      </Card>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withStyles(styles)(QuizRow));
