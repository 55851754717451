import { withStyles, Paper } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';

import { EDULAI_BLUE, RED } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import MDButton from '../../MDButton/MDButton';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }
  return errors;
};

const styles = (theme) => ({
  formContainer: {
    margin: 20,
    marginTop: 20,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  headerTitle: {
    marginLeft: 20,
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  enabledText: {
    color: '#66c0b0',
    margin: 0,
  },
  disabledText: {
    color: 'red',
    margin: 0,
  },
  statusContainer: {
    marginLeft: 10,
    marginBottom: 20,
  },
  statusTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

const UserTagForm = ({ tag, dispatch, onDeleteTag }) => {
  const isEdulaiTag = tag && tag.id && tag.id === 1;
  return (
    <MuiThemeProvider theme={theme}>
      {isEdulaiTag && <h4 style={{ marginTop: 0 }}>{translations.t('forms.edulaiTagNotModify')}</h4>}
      <Field
        name="name"
        component={MDTextInputField}
        containerstyle={{ marginBottom: 20, marginTop: 10 }}
        variant="outlined"
        disabled={isEdulaiTag}
        label={translations.t('forms.tagName')}
        required
      />
      {tag && tag.users && !_.isEmpty(tag.users) && (
        <div>
          <h4>{translations.t('users.tagUsers')}</h4>
          {_.map(tag.users, (user) => (
            <Paper style={{ padding: 10, margin: 10 }}>
              <h4 style={{ margin: 0 }}>{`${user.name} ${user.surname}`}</h4>
              <h4 style={{ margin: 0, fontWeight: 100 }}>{user.email}</h4>
            </Paper>
          ))}
        </div>
      )}
      {!isEdulaiTag && (
        <MDButton
          backgroundColor={EDULAI_BLUE}
          title={translations.t('forms.save')}
          onClick={() => dispatch(submit('UserTagForm'))}
        />
      )}
      {tag && !isEdulaiTag && (
        <MDButton
          title={translations.t('forms.delete')}
          backgroundColor={RED}
          onClick={() => onDeleteTag(tag)}
        />
      )}
    </MuiThemeProvider>
  );
}


export default _.flow([
  connect(),
  reduxForm({
    form: 'UserTagForm',
    validate,
    destroyOnUnmount: true,
  }),
  withStyles(styles),
])(UserTagForm);
